.td__container {
  position: relative;
  z-index: 12;
}

.toolsDropdown-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  top: 100%;
  width: 260px;
  height: 208px;
  z-index: 5;
  left: -50px;
}

.toolsDropdown-container.es {
  left: -24px;
  width: 290px;
}

.toolsDropdown-container.account {
  top: 24px;
}

.toolsDropdown {
  padding: 12px 17px;
  position: relative;
  top: 40px;
  display: flex;
  flex-direction: column;
  width: 356px;
  background: #ffffff;
}

.td__container .toolsDropdown > a {
  display: block;
  text-align: left;
  padding: 12px 10px !important;
  border-bottom: 1px solid #dedede;
  font-size: 15px;
  line-height: 18px;
  margin: 0 0 0 0 !important;
  cursor: pointer;
  font-weight: 400;
  position: relative;
}

.td__container .toolsDropdown > button {
  display: block;
  text-align: left;
  padding: 12px 10px !important;
  border-bottom: 1px solid #dedede;
  font-size: 15px;
  line-height: 18px;
  margin: 0 0 0 0 !important;
  cursor: pointer;
  font-weight: 400;
  position: relative;
}

.td__container .toolsDropdown > a.activeArea {
  font-weight: 600;
}

.toolsDropdown > a:last-child {
  border-bottom: none;
}

.td__container .toolsDropdown > a.disabled {
  color: #868686;
  cursor: not-allowed;
  pointer-events: none;
}
