/* header colors based on section */

/* ACCOUNT SECTION */
header.section__account {
  background-color: #f4efe9;
}

div.section__account {
  background-color: #e8dbcf;
}

.section__account .iconLinks li {
  color: #e37b53;
}

header.section__account .iconLinks li .userInitials,
header.section__account .iconLinks li .userInitials span {
  border-color: #e37b53;
  color: #e37b53;
}

.iconLinks li .userInitials a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section__account .userIconCircle path,
.section__account .userIconCircle circle,
.section__account .btn.btn-link path:nth-child(2),
.section__account .comboLogoContainer .ilgaDatabase path {
  fill: #e37b53;
}

.section__account .had__container .headerAreaDropdown a:hover,
.section__account .had__container .headerAreaDropdown div:hover,
.section__account .had__container .headerSubAreaDropdown a:hover,
.section__account .textLinks li > a:hover {
  color: #e37b53;
}
.section__account .had__container .headerAreaDropdown > *:hover svg {
  fill: #e37b53;
}
.section__account .headerAreaDropdown,
.section__account .headerSubAreaDropdown {
  background-color: #f4efe9;
}

/* END OF ACCOUNT SECTION */

/* UPDATES SECTION */
header.section__updates {
  background-color: #f5f5ed;
}

div.section__updates,
div.section__updates .table__section {
  background-color: #edead6;
}

.section__updates .iconLinks li {
  color: #d5c61d;
}

.section__updates .iconLinks li .userInitials a {
  color: #d5c61d;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section__updates .iconLinks li .userInitials {
  border-color: #d5c61d;
  color: #d5c61d;
}

.section__updates .userIconCircle path,
.section__updates .userIconCircle circle,
.section__updates .btn.btn-link path:nth-child(2),
.section__updates .comboLogoContainer .ilgaDatabase path {
  fill: #d5c61d;
}

.section__updates table.mainTable th,
.section__updates button.deleteCol,
.section__updates .ach__fullScreenButtonContainer .fullscreenButton {
  background-color: #e5d9a9 !important;
}

.section__updates table.mainTable td {
  background-color: #faf9ed;
}

.section__updates .table__section .areaContent__subTitle {
  margin-bottom: 0;
}

.section__updates .areaContent__subTitle {
  color: #707070;
  font-size: 28px;
  font-weight: 700;
}

/* END OF UPDATES SECTION */

/* DEADLINES SECTION */
header.section__deadlines {
  background-color: #f4efef;
}

div.section__deadlines,
div.section__deadlines .table__section {
  background-color: #e2d7d5;
}

.section__deadlines .iconLinks li {
  color: #e3797a;
}

.section__deadlines .iconLinks li .userInitials a {
  color: #e3797a;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section__deadlines .iconLinks li .userInitials {
  border-color: #e3797a;
  color: #e3797a;
}

.section__deadlines .userIconCircle path,
.section__deadlines .userIconCircle circle,
.section__deadlines .btn.btn-link path:nth-child(2),
.section__deadlines .comboLogoContainer .ilgaDatabase path {
  fill: #e3797a;
}

.section__deadlines table.mainTable th,
.section__deadlines button.deleteCol,
.section__deadlines .ach__fullScreenButtonContainer .fullscreenButton {
  background-color: #e5b4b4 !important;
}

.section__deadlines table.mainTable td {
  background-color: #f4efef;
  border: 1px solid #e2d7d5;
}

.section__page .areaContent__header {
  margin: 26px 0 20px;
}

.section__page .table__section .areaContent__subTitle {
  margin-bottom: 0;
}

.section__page .areaContent__subTitle {
  color: #707070;
  font-size: 28px;
  font-weight: 700;
}

/* UPCOMING DEADLINES SECTION */

/* Credits SECTION */
header.section__credits {
  background-color: #f2f2f2;
}

div.section__credits {
  background-color: #d8d8d8;
}

.section__credits .iconLinks li {
  color: #707070;
}

.section__credits .iconLinks li .userInitials a {
  color: #707070;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section__credits .iconLinks li .userInitials {
  border-color: #707070;
  color: #707070;
}

.section__credits .userIconCircle path,
.section__credits .userIconCircle circle,
.section__credits .btn.btn-link path:nth-child(2),
.section__credits .comboLogoContainer .ilgaDatabase path {
  fill: #707070;
}

/* END OF Credits SECTION */

/* TOOLS SECTION */
header.section__tools {
  background-color: #eeecf5;
}

div.section__tools {
  background-color: #d8d5e3;
}

.areaHome__page.section__tools .content__container .areaLinkBox:hover {
  background-color: #afa6c9;
  color: #ffffff;
}

.areaHome__page.section__tools .content__container .areaLinkBox:hover p,
.areaHome__page.section__tools .content__container .areaLinkBox:hover h6 {
  color: #ffffff;
}

.section__tools .areaLinkBox svg path,
.section__tools .areaLinkBox svg circle,
.section__tools .areaLinkBox svg rect,
.hpcb__toolsContainer svg path,
.hpcb__toolsContainer svg circle,
.hpcb__toolsContainer svg rect {
  fill: #d8d5e3;
}

.areaHome__page.section__tools .content__container .areaLinkBox:hover svg path,
.areaHome__page.section__tools .content__container .areaLinkBox:hover svg circle,
.areaHome__page.section__tools .content__container .areaLinkBox:hover svg rect {
  fill: #a397c4 !important;
}

.section__tools .iconLinks li {
  color: #8a79ba;
}

.section__tools .iconLinks li .userInitials a {
  color: #8a79ba;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section__tools .iconLinks li .userInitials {
  border-color: #8a79ba;
  color: #8a79ba;
}

.section__tools .userIconCircle path,
.section__tools .userIconCircle circle,
.section__tools .btn.btn-link path:nth-child(2),
.section__tools .comboLogoContainer .ilgaDatabase path {
  fill: #8a79ba;
}

.section__tools .areaHome__page .content__container .areaLinkBox {
  color: #8a79ba;
  background-color: #f9f7ff;
}

.section__tools .areaLink__header h6 {
  color: #8a79ba;
  text-align: left;
}

.section__tools.areaHome__page .content__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.section__tools.areaHome__page .content__container .areaLinkBox {
  width: calc(33% - 20px);
}

/* END OF TOOLS SECTION */

/* HEADER DROPDOWN SPECIFIC */

.section__areas {
  background-color: #d8e3e5;
}

header.section__areas{
  background-color: #E9EFF0;
  
}

/* background colors */
.section__areas.section__homePage .toolsDropdown,
.section__areas.section__homePage .headerAreaDropdown,
.section__areas.section__homePage .headerSubAreaDropdown {
  background-color: #F0F0F0;
}
.section__areas.section__homePage .had__container .headerAreaDropdown + .headerSubAreaDropdown > a,
.section__areas.section__homePage .had__container .headerAreaDropdown > * {
  border-bottom-color: #dedede;
}
.section__areas.section__homePage .had__container .headerAreaDropdown + .headerSubAreaDropdown > a:hover,
.section__areas.section__homePage .had__container .headerAreaDropdown > a:hover,
.section__areas.section__homePage .had__container .headerAreaDropdown > div:hover {
  color: #868686;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  border-bottom-color: #a4a4a4;
}

.section__areas .toolsDropdown,
.section__areas .headerAreaDropdown,
.section__areas .headerSubAreaDropdown {
  background-color: #f4f8f8;
}

.section__jurs .toolsDropdown,
.section__jurs .headerAreaDropdown,
.section__jurs .headerSubAreaDropdown {
  background: #ecefec;
}

.section__updates .toolsDropdown,
.section__updates .headerAreaDropdown,
.section__updates .headerSubAreaDropdown {
  background-color: #f5f5ed;
}

.section__deadlines .toolsDropdown,
.section__deadlines .headerAreaDropdown,
.section__deadlines .headerSubAreaDropdown {
  background-color: #f4efef;
}

.section__credits .toolsDropdown,
.section__credits .headerAreaDropdown,
.section__credits .headerSubAreaDropdown {
  background-color: #f2f2f2;
}

.section__tools .toolsDropdown,
.section__tools .headerAreaDropdown,
.section__tools .headerSubAreaDropdown {
  background-color: #eeecf5;
}
/* hover colors */
.section__areas .td__container .toolsDropdown a:hover,
.section__areas .had__container .headerAreaDropdown > a:hover,
.section__areas .had__container .headerAreaDropdown > div:hover,
.section__areas .had__container .headerSubAreaDropdown > a:hover,
.section__areas .had__container .headerSubAreaDropdown > div:hover,
.section__areas .textLinks li > a:hover {
  color: #5c8f95;
}
.section__areas .had__container .headerAreaDropdown > div:hover svg {
  fill: #5c8f95;
}

.section__homePage .textLinks li > a:hover {
  color: #868686;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.section__jurs .td__container .toolsDropdown a:hover,
.section__jurs .had__container .headerAreaDropdown > a:hover,
.section__jurs .had__container .headerAreaDropdown > div:hover,
.section__jurs .had__container .headerSubAreaDropdown > a:hover,
.section__jurs .had__container .headerSubAreaDropdown > div:hover,
.section__jurs .textLinks li > a:hover {
  color: #649364;
}
.section__jurs .had__container .headerAreaDropdown > div:hover svg {
  fill: #649364;
}

.section__tools .td__container .toolsDropdown a:hover,
.section__tools .had__container .headerAreaDropdown a:hover,
.section__tools .had__container .headerAreaDropdown div:hover,
.section__tools .had__container .headerSubAreaDropdown a:hover,
.section__tools .textLinks li > a:hover {
  color: #8a79ba;
}
.section__tools .had__container .headerAreaDropdown > *:hover svg {
  fill: #8a79ba;
}

.section__updates .td__container .toolsDropdown a:hover,
.section__updates .had__container .headerAreaDropdown a:hover,
.section__updates .had__container .headerAreaDropdown div:hover,
.section__updates .had__container .headerSubAreaDropdown a:hover,
.section__updates .textLinks li > a:hover {
  color: #d5c61d;
}
.section__updates .had__container .headerAreaDropdown > *:hover svg {
  fill: #d5c61d;
}

.section__deadlines .td__container .toolsDropdown a:hover,
.section__deadlines .had__container .headerAreaDropdown a:hover,
.section__deadlines .had__container .headerAreaDropdown div:hover,
.section__deadlines .had__container .headerSubAreaDropdown a:hover,
.section__deadlines .textLinks li > a:hover {
  color: #e3797a;
}
.section__deadlines .had__container .headerAreaDropdown > *:hover svg {
  fill: #e3797a;
}

.section__credits .td__container .toolsDropdown a:hover,
.section__credits .had__container .headerAreaDropdown a:hover,
.section__credits .had__container .headerAreaDropdown div:hover,
.section__credits .had__container .headerSubAreaDropdown a:hover,
.section__credits .textLinks li > a:hover {
  color: #707070;
}
.section__credits .had__container .headerAreaDropdown > *:hover svg {
  fill: #707070;
}

/* END OF HEADER DROPDOWN SPECIFIC*/

/* END OF header colors based on section */
