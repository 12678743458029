.chartContainer {
  position: relative;
}

.chartContainer .loadingBar {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.pieOptions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.protectionButtons {
}
.protectionButton {
  margin-right: 12px;
  padding: 5px;
  color: rgb(240, 240, 240);
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 12%;
  min-width: 42px;
  min-height: 32px;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}

.protectionButton.active {
  border: 1px solid #747474;
  
  text-underline-offset: 3px;
}

.protectionButton:hover {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
}

.protectionButton.so {
  background-color: #ab97ce;
}
.protectionButton.gi {
  background-color: #96c9db;
}
.protectionButton.ge {
  background-color: #e08aa2;
}
.protectionButton.sc {
  background-color: #f0c85c;
}

.protectionButton.yes {
  border-radius: 2%;
  background-color: #ab97ce;
}
.protectionButton.indirect {
  border-radius: 5%;
  background-color: #96c9db;
}

.protectionButton.marriage {
  border-radius: 2%;
  background-color: #ab97ce;
}
.protectionButton.civil {
  border-radius: 5%;
  background-color: #96c9db;
}
.protectionButton.joint {
  border-radius: 2%;
  background-color: #ab97ce;
}
.protectionButton.second {
  border-radius: 5%;
  background-color: #96c9db;
}

.pieOptions label {
  align-self: center;
  float: right;
}
