.tallies__section {
  margin: var(--verticalMargin);
  background: #f4f8f8;
  padding: 32px 38px;
}
.tally__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.tallyTopic {
  width: 292px;
  border-right: 3px solid #d3dfe1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
}
.tallyTitle {
  font-size: 20px;
  line-height: 22px;
  color: #000;
  font-weight: 600;
}
.tallySubtitle {
  font-size: 16px;
}
.tallyData__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.tallyData__container .td__box {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-right: 3px solid #d3dfe1;
  padding: 0px 24px 0;
}

.td__box-118 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  align-items: center;
  border-right: 3px solid #d3dfe1;
  padding: 24px 24px 0;
  justify-content: flex-start;
}

.tallyData__container .td__box:last-child {
  border-right: 0;
  border-right: initial;
}
.tallyData__container .td__box-118:last-child {
  border-right: 0;
  border-right: initial;
}

.td__box-118_2,
.td__box-118_3 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  align-items: center;
  border-right: 3px solid #d3dfe1;
  padding: 0px 24px 0;
  justify-content: flex-start;
}

.tallyData__container .td__box-114 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  align-items: center;
  border-right: 3px solid #d3dfe1;
  padding: 25px 24px 0;
  justify-content: flex-start;
}
.tallyData__container .td__box-114:last-child {
  border-right: 0;
  border-right: initial;
}

.tallyData__container .td__box-13 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  align-items: center;
  border-right: 3px solid #d3dfe1;
  padding: 10px 24px 0;
  justify-content: flex-start;
}
.tallyData__container .td__box-13:last-child {
  border-right: 0;
  border-right: initial;
}

.tallyData__container .td__box-14 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  align-items: center;
  border-right: 3px solid #d3dfe1;
  padding: 10px 24px 0;
  justify-content: flex-start;
}

.tallyData__container .td__box-14:last-child {
  border-right: 0;
  border-right: initial;
}

.tallyData__container .td__number {
  text-align: center;
  font-size: 55px;
  margin-bottom: 10px;
  font-weight: 900;
}
.tallyData__container .td__text {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-transform: lowercase;
}
.tallyData__container .td__text::first-letter {
}
.tallyData__container .td__text.tally__4 {
  font-size: 25px;
  line-height: 30px;
  font-weight: 900;
  text-transform: uppercase;
}

.td__text.noLower {
  text-transform: inherit;
}
.tallyData__container .td__atLeast {
  font-size: 16px;
  line-height: 24px;
  color: #575757;
}
@media (max-width: 767px) {
  .tallyTitle {
    margin: 0px 12px 8px;
  }
  .tallyTitle br {
    display: none;
  }
  .tallyTopic {
    width: 100%;
    border-right: 0;
    border-right: initial;
    text-align: center;
    padding: 0;
    padding: initial;
  }
  .tallyData__container .td__box {
    width: 100% !important;
    border: medium none currentColor;
    border: initial;
    margin: 10px 0 20px;
  }
  .tallyData__container .td__box-13,
  .tallyData__container .td__box-14,
  .tallyData__container .td__box-114,
  .tallyData__container .td__box-118 {
    width: 100% !important;
    border: medium none currentColor;
    border: initial;
    margin: 10px 0 20px;
  }
  .tallyData__container .td__box:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 767px) {
  .tally__container {
    flex-direction: row;
  }
  .tallyData__container {
    flex-direction: row;
  }
  .tally__container {
    height: 145px;
  }
  .homepage__Jurisdictions {
    margin-top: 50px;
  }
}


.lgrExplanTally{
  font-size: 12px !important;
  line-height: 16px !important;
}