:root {
  --lightBluebg: #ecefec;
}

header.jur__page {
  background: #ecefec;
}

header.jur__page .textLinks a.active {
  border-bottom: 2px solid #ffffff;
}

header.jur__page .iconLinks li span,
header.jur__page .iconLinks li .btn-link,
header.jur__page .iconLinks li a {
  color: #649364;
}

header.jur__page .iconLinks li:nth-last-child(2) path,
header.jur__page .iconLinks li:nth-last-child(2) circle {
  fill: #649364;
}

header.jur__page .iconLinks li:nth-last-child(1) path:last-child {
  fill: #649364;
}

header.jur__page .iconLinks li span:hover,
header.jur__page .iconLinks li .btn-link:hover,
header.jur__page .textLinks li a:hover {
  color: #707070;
}

.jurHero__section {
  background: #dde0d4;
  min-height: 810px;
  padding-top: 140px;
}

.jurPage__searchBar {
  position: relative;
  width: 484px;
  height: 44px;
  margin-bottom: 18px;
  font-size: 16px;
  line-height: 18px;
}

.jurPage__searchInput {
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
  width: 100%;
  height: 100%;
  padding: 12px 16px;
}

.jurPage__searchInput::placeholder,
.jurPage__searchInput:-ms-input-placeholder,
.jurPage__searchInput::-ms-input-placeholder {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
}

.jurPage__searchBar svg {
  position: absolute;
  right: 12px;
  top: 9px;
}

.jurPage__searchBar svg path {
  fill: #b6c1b5;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.jurPage__searchInput {
  background: #f2f6f2;
  box-shadow: 0 1px 5px #00000029;
  outline-color: #d8e3e5;
}

.jh__countryFlag {
  width: 33px;
  height: 24px;
  margin-right: 10px;
}

.jh__countryInfo-container, .jh__countryInfo h1 {
  font-size: 33px;
  line-height: 45px;
  font-weight: 900;
  color: #000;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;
  margin: initial
}



.jh__flagContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.jh__countryStatus {
  position: absolute;
  left: 0;
  bottom: -10px;
  font-size: 13px;
  line-height: 15px;
  font-weight: 600;
  color: #707070;
  margin-left: 42px;
}

.jh__searchSubnational {
  margin-left: 40px;
  font-size: 18px;
  position: relative;
  width: 265px;
  height: 26px;
  z-index: 10;
}

.jh__searchSubnational input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: medium none currentColor;
  border: initial;
  border-bottom: 1px solid gray;
  padding-bottom: 8px;
  outline-color: #d8e3e5;
}
.jh__searchSubnational input:focus {
  outline: none;
  box-shadow: none;
  box-shadow: initial;
}

.jh__searchSubnational {
  font-size: 12px;
  line-height: 14px;
  color: #868686;
}

.jh__searchSubnational input::placeholder,
.jh__searchSubnational input:-ms-input-placeholder,
.jh__searchSubnational input::-ms-input-placeholder {
  font-size: 12px;
  line-height: 14px;
  color: #868686;
}

.jh__searchSubnational svg {
  position: absolute;
  right: 0;
  bottom: 8px;
}
.jh__countryData {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.jh__countryData img {
  align-self: center;
  margin-right: auto;
}

.jh__countryData .jh__countryNewsContainer {
  width: 368px;
  background-color: #f7f6f2;
  margin-right: 26px;
  box-shadow: 0 1px 3px #00000029;
  margin-bottom: 18px;
  height: 492px;
}

.jh__tab {
  padding: 10px 29px 10px 29px;
  border: medium none currentColor;
  border: initial;
}
.jh__tab-itemList {
  max-height: 390px;
  height: 390px;
  overflow: auto;
  padding: 0 10px;
  margin-bottom: 0;
}
/* width */
.jh__tab-itemList::-webkit-scrollbar,
.jh__timelineList::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
.jh__tab-itemList::-webkit-scrollbar-thumb,
.jh__timelineList::-webkit-scrollbar-thumb {
  height: 5px;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  min-height: 75px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05),
    inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}
.jh__tab-itemList::-webkit-scrollbar-button,
.jh__timelineList::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.jh__tab-itemList::-webkit-scrollbar-corner,
.jh__timelineList::-webkit-scrollbar-corner {
  background-color: transparent;
}

.jh__tab-itemList li {
  border-bottom: 2px dotted #bdc9bc;
  padding: 10px 0;
}

.jh__tab-itemList li:last-child {
  border-bottom: 0;
  border-bottom: initial;
}

.jhil__header {
  font-size: 11px;
  line-height: 14px;
  color: #000;
}

.jhil__timeRemaining {
  font-size: 12px;
  color: #6b986b;
  font-weight: 400;
}

.jhil__body-title {
  font-size: 13px;
  line-height: 14px;
  font-weight: 600;
  color: #000;
  margin-top: 4px;
  margin-bottom: 4px;
}

.jhil__body-content {
  color: #707070;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 3px;
}
.jh__tab .jhil__body-content {
  color: #000;
}

.jhil__body:last-child p {
  margin-bottom: 0;
}

.jhil__footer p {
  color: #707070;
  margin-top: 4px;
  margin-bottom: 0;
}
.jhil__footer a {
  text-decoration: none;
  text-decoration: initial;
  color: #6b986b;
}

.jhil__footer a:hover {
  text-decoration: underline;
}

.jh__countryNewsContainer .nav-tabs {
  border: medium none currentColor;
  border: initial;
}
.jh__countryNewsContainer .tab-content {
  height: calc(100% - 78px);
}

.jh__countryNewsContainer .jh__tab.active {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.jh__countryNewsContainer .btn.btn-default {
  display: block;
  width: 100%;
  position: relative;
  top: 20px;
  background: #ffffff;
  color: #649364;
  font-weight: 700;
  font-size: 12px;
}
.jh__countryNewsContainer .nav-item {
  width: 50%;
}

.jh__countryNewsContainerNonUn .nav-item {
  width: 100%;
}

.jh__countryNewsContainer .nav-item button {
  width: 100%;
  font-size: 13px;
  line-height: 30px;
  border: medium none currentColor;
  border: initial;
  background-color: #bdc9bc;
  color: #f7f5e4;
  height: 100%;
  border-radius: 0;
}
.jh__countryNewsContainer .nav-link.active {
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
  font-weight: 700;
  background-color: #f7f6f2;
}
.jh__countryNewsContainer .nav-link:hover {
  isolation: initial;
}
.jh__countryNewsContainer .text-green {
  color: #649364;
}

.jh__countryNewsContainer h5.title {
  font-size: 13px;
  line-height: 30px;
  margin-top: 8px;
  text-align: center;
  font-weight: 600;
  color: #000;
}
.jh__tab .jh__signUpCall {
  padding: 20px;
  font-size: 14px;
  line-height: 17px;
  color: #000;
}
.jh__tab .jh__signUpCall a {
  color: #649364;
}

.jh__timelineContainer {
  width: 270px;
  background-color: #f7f6f2;
  margin-bottom: 18px;
  box-shadow: 0 1px 3px #00000029;
}

.jh__timelineContainer h5 {
  font-size: 13px;
  line-height: 30px;
  margin-top: 8px;
  text-align: center;
  font-weight: 600;
  color: #000;
}

.jh__timelineList {
  padding: 8px 0px;
  max-height: 424px;
  overflow: auto;
  margin: 0 16px;
}

.jh__timelineList li {
  display: flex;
  margin: 12px;
  border-bottom: 2px dotted #bdc9bc;
  padding-bottom: 8px;
}

.jh__timelineList li:last-child {
  border-bottom: 0;
  border-bottom: initial;
}

.jh__timelineList .jht__yearDone {
  color: #649364;
  font-size: 12px;
  line-height: 18px;
  min-width: 25px;
}

.jh__timelineList .jht__thingDone {
  display: flex;
  flex-direction: column;
  margin-left: 11px;
}

.jht__thingDone span {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 3px;
}

.jht__thingDone span:not(:first-child) {
  margin-top: 5px;
}

.jur-home__page .mapLegend {
  justify-content: center;
}

.jur-home__page .mapLegend__item .count {
  font-weight: 600;
}

.samePageLinks__section {
  margin: var(--verticalMargins);
}

.spl__container {
  display: flex;
  gap: 118px;
}

.spl__container .spl__link {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px 16px;
  height: 150px;
  width: 176px;
  font-size: 19px;
  line-height: 19px;
  cursor: pointer;
  color: #868686;
  font-weight: 700;
  transition: color 225ms, background-color 225ms;
}

.spl__container .spl__link span {
  font-size: 13px;
  color: #70a576;
  font-weight: 700;
}

.spl__container .spl__link:hover,
.spl__container .spl__link.active {
  background-color: #ecefec;
  color: #000;
  font-weight: 900;
}

/* JurProfileSummary__section */
.JurProfileSummary__section {
  background-color: #ecefec;
  padding: 32px 48px 40px 48px;
  margin-bottom: 28px;
}

.jps__header,
.jps__button {
  display: flex;
  justify-content: space-between;
}

.jps__title {
  font-size: 22px;
  line-height: 37px;
  color: #a0aa9f;
  margin-bottom: 0;
}

.jps__button {
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: 900;
  color: #000;
  border-radius: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 11px 19px;
  transition: background-color 225ms, border-color 225ms, color 225ms;
}

.jps__button:hover,
.jps__button:focus {
  color: #000;
  background: rgba(255, 255, 255, 0.375) !important;
  border-color: #4e4e4e !important;
}
.jpjs__innerArea .jps__button:hover,
.jpjs__innerArea .jps__button:focus {
  border-color: #dce2dd !important;
  color: #8b8b8b;
}

.jpsb__countryFlag {
  width: 21px;
  height: 14px;
  margin-right: 8px;
}

.jps__summaryBoxes {
  display: flex;
  justify-content: space-between;
}

.jps__body {
  margin: 30px 0 0;
}

.jps__sb {
  max-width: 292px;
  padding: 26px 26px 16px 16px;
  height: 152px;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.jps__sb p {
  color: inherit;
}
.jps__sb p.text-green {
  color: #70a576;
}
.jps__sb p span {
  margin-right: 4px;
  font-size: 16px;
  line-height: 18px;
}

.jps__header {
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  align-items: center;
}
p.jps__header {
  align-items: baseline;
}
.jps__status {
  font-size: 13px;
  line-height: 14px;
  font-weight: 900;
}

.yellow-text {
  color: #9d2d0e !important;
}

.green-text {
  color: #347a3c !important;
}

.sb-dark {
  background-color: #e2c8b4;
  color: #707070;
}

.sb-gray {
  background-color: #e3e3e3;
  color: #a5a4a4;
}

.sb-white {
  background-color: #d6e0d0;
  color: #707070;
}

.sb-nodata {
  background-color: #e3e3e3;
  color: #766d6d !important;
}

.sb-nodata .yellow-text{
  color: #766d6d !important;
}



.jps__status,
.jps__statusSet {
  width: 100%;

  padding: 0 19px 0 18px;
}

.jps__status2digit,
.jps__statusSet2digit {
  padding: 0 19px 0 29px;
}

.jps__status.illegal {
  font-size: 13px;
  line-height: 14px;
}

.jps__discriminationSection {
  margin-top: 42px;
}

.jpsd__title {
  font-size: 16px;
  color: #707070;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.jpsd__title span {
  flex-grow: 1;
  height: 2px;
  background-color: #dfdfdf;
  margin-left: 12px;
  margin-top: 3px;
}

.jpsd__boxContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.jpsd__box {
  padding: 24px;
  width: 33%;
  max-width: 400px;
  background-color: #f7f7f7;
  margin-bottom: 10px;
  cursor: pointer;
}

.jpsd__box .jpsd__boxTitle {
  font-size: 16px;
  line-height: 18px;
  color: #707070;
  margin-bottom: 18px;
  font-weight: 900;
}

.jpsd__boxBody {
  display: flex;
  justify-content: space-between;
}

.jpsd__stripeColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.jpsdsc__name {
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: 900;
  display: block;
  margin-bottom: 7px;
}

.jpsdsc__status {
  color: #707070;
  font-size: 13px;
  line-height: 14px;
  max-width: 40px;
  padding: 0;
  display: block;
  text-align: center;
}
.jpsdsc__status_bold {
  font-weight: bold;
}
.jpsd__stripeColumn .jpsdsc__bar {
  width: 100%;
  height: 6px;
  margin-bottom: 4px;
}

.jpsdsc__bar.green {
  background-color: #347a3c;
}

.jpsdsc__bar.yellow {
  background-color: #c50e0e8c;
}

.jpsdsc__bar.gray {
  background-color: #d4b4164d;
}

.jpsd__boxContainer .jps__summaryBoxes {
  width: 100%;
  margin-top: 32px;
}

.jps__summaryRow {
  width: 100%;
  padding: 32px 0px 0px 0px;
}

.jps__summaryRow-heading {
  font-size: 18px;
  color: #707070;
  font-weight: 900;
}
.jps__summaryRow-subHeading {
  color: #000;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  margin-top: 37px;
  margin-bottom: 14px;
}
.jps__summaryRow .jps__summaryBoxes {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.jpssr__sb {
  max-width: 246px;
  max-height: 152px;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 4px;
}

.jpssr__sb:last-child {
  margin-right: 0;
}

.jpssr__header {
  font-size: 16px;
  line-height: 18px;
  color: inherit;
  margin-bottom: 21px;
  font-weight: 900;
}

.jpssr__status {
  font-size: 13px;
  line-height: 14px;
  font-weight: 900;
}

.pull-up {
  position: relative;
  top: -18px;
}

/* END OF JurProfileSummary__section */

.section1-jur1 {
  background: gray;
  padding-top: 18px;
}

.section1-jur1 .jpjs__area {
  font-size: 14px;
  line-height: 24px;
  color: #70a576;
}

.jpjs__titleWithBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.jpjs__title {
  font-size: 20px;
  line-height: 20px;
  font-weight: 900;
  color: #000;
}

h2.jpjs__title,
h4.a3ts__tableTitle,
h4.tb__barChart-title {
  color: #575757;
}

.jpjs__innerArea-header {
  padding-top: 12px;
}

.jpjs__innerArea-header .content__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.jpia__title {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  max-width: 1000px;
  margin-top: 26px;
  margin-bottom: 14px;
  margin-left: 2px;
  text-indent: -26px;
  padding-left: 26px;
}

.jpjs__innerArea .jps__button {
  min-width: 140px;
  color: #707070;
  font-size: 13px;
  line-height: 14px;
  font-weight: 700;
  padding: 8px 20px;
}

.jpjs__innerArea-body {
  padding-top: 0px;
  margin-top: 5px;
}

.jpjs__summaryBox {
  padding: 24px;
  background-color: #ffffff;
  color: #707070;
  width: 100%;
  max-width: 615px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.jpjs__summaryBoxSsu {
  padding: 24px;
  background-color: #ffffff;
  color: #707070;
  width: 100%;
  max-width: 615px;
  display: flex;
  flex-direction: row;
}

.jpjs__summaryBoxMain {
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  color: #707070;
  width: 100%;
  max-width: 815px;
  display: flex;
}

.sbox__left,
.sbox__right {
  padding: 24px;
  width: 50%;
}

.sbox__left p {
  font-size: 13px;
  line-height: 14px;
  margin-bottom: 8px;
  color: #707070;
}

.sbox__left p:nth-child(3) {
  margin-top: 20px;
}

.sbox__right {
  padding: 24px 40px 24px 24px;
  margin: auto;
  font-size: 13px;
}

.sbox__right p {
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
  color: #707070;
  margin-bottom: 0;
  padding-top: 20px;
}

.sbox__right p:first-child {
  margin-top: 0;
  padding-top: 0;
}

.jpjs__progressBar {
  display: flex;
  height: 24px;
}

.jpjspb__left,
.jpjspb__right {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.jpjspb__left {
  background-color: #e3b7a6;
  color: #ffffff;
  min-width: 10%;
}

.jpjspb__left.red {
  background-color: #e3b7a6;
}

.jpjspb__left.blue {
  background-color: #6ea2b5;
}

.jpjspb__left.green {
  background-color: #b5d6c1;
}

.jpjspb__right {
  background-color: #e3e3e3;
  color: #707070;
  min-width: 10%;
}

.jpjs__countryBox {
  margin: 20px 0 0;
}
.jpjs__countryBox .accordion-header .accordion-button:after,
.subJur__box .accordion-header .accordion-button:after {
  position: relative;
  top: -2px;
  right: -16px;
}
.jpjs__innerArea {
  background-color: #ecefec;
  padding-bottom: 25px;
}
.section1-jur1 .jpjs__innerArea {
  padding-bottom: 0;
}
.section2-jur1 .jpjs__innerArea {
}
.section2-jur1 .jpia__title {
}

.section2-jur1 .jpjs__innerArea-body p {
  color: #d8d8d8;
  font-size: 19px;
  line-height: 22px;
  margin-bottom: 0;
}
/* JUR PROFILE AREA 1 */
.section1-jur1 {
  background-color: #ecefec;
  background-color: var(--lightBluebg);
  margin-top: 0;
  padding: 46px 0 0;
}

.jpjs__countryBox .accordion-item {
  border-radius: 0;
  border: medium none currentColor;
  border: initial;
}

.jpjs__countryBox .accordion-header button,
.jpjs__countryBox .accordion-button:not(.collapsed),
.jpjs__countryBox .jur-title {
  background-color: #ffffff !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  outline: none !important;
  font-size: 18px;
  font-weight: 900;
  padding: 20px 30px;
  color: #000;
}

.jpjs__countryBox-actions {
  display: flex;
  justify-content: flex-end;
  padding: 18px 0;
}

.jpjs__countryBox-actions .greenBtn {
  margin-left: 10px;
  background-color: #d3e0d6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.29);
  padding: 11px 18px;
  font-size: 13px;
  line-height: 18px;
  border: medium none currentColor;
  border: initial;
  color: #818881;
  transition: color 325ms, box-shadow 325ms;
}

.jpjs__countryBox-actions .greenBtn:hover,
.jpjs__countryBox-actions .greenBtn:focus,
.jpjs__countryBox-actions .greenBtn:active {
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.29);
  background-color: #daf5de;
}

.subJur__box:not(:last-child) {
  margin: 6px 0;
}

.jpjs__innerArea-body .accordion-footer {
  padding-top: 1px;
  background: #ecefec;
}

.accordion-collapse + .accordion-footer {
  height: 0;
  transition: height 225ms;
  overflow: hidden;
}

.accordion-collapse.show + .accordion-footer {
  height: 72px;
  overflow: visible;
}

.subJur__box .accordion-item {
  border: medium none currentColor;
  border: initial;
  border-radius: 0 0 0 0;
}

.subJur__box .accordion-button {
  background-color: #ffffff;
  border-radius: 0 0 0 0;
  box-shadow: none;
  box-shadow: initial;
  color: #000;
  padding: 13px 26px;
}
.subJur__box .accordion-button.collapsed {
  border: 2px solid #d1dbd0;
  background-color: #ecefec;
  border-radius: 0 0 0 0;
}
.subJur__box .accordion-body,
.jpjs__innerArea .accordion-body {
  border-top: 0;
  border-top: initial;
}
.jpjs__innerArea .accordion-body {
  padding: 5px 0px 20px 20px;
  background-color: #ecefec;
}

.jpjs__sogiesc-columns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.jpjss__column {
  background: #fff;
  flex-grow: 1;
  margin-right: 13px;
  padding: 29px;
  max-width: 24%;
  max-width: 24%;
}
.jpjss__column:last-child {
  margin-right: 0;
}
.jpjss__column .jpjss__columnHeader {
  text-align: center;
}
.jpjss__columnHeader-title {
  font-size: 18px;
  color: #000;
  font-weight: 900;
  margin-bottom: 3px;
}
.jpjss__columnHeader-subTitle {
  font-size: 14px;
  color: #000;
  font-weight: 700;
  margin-bottom: 40px;
}
.jpjss__columnProgress p {
  color: #707070;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 6px;
}
.jpjss__cpBar {
  width: 100%;
  height: 19px;
  background: #e3e3e3;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin: 8px 0 16px;
}
.jpjss__cpBar > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-gray {
  background-color: #e3e3e3;
  color: #707070;
  min-width: 8%;
}
.bg-blue {
  background-color: #6ea2b5;
  color: #ffffff;
  min-width: 8%;
  font-weight: 700;
}
.bg-mint {
  background-color: #b5d6c1;
  color: #ffffff;
  min-width: 8%;
  font-weight: 700;
}
.jpjss__columnContent p {
  margin-top: 24px;
  color: #707070;
  font-size: 13px;
  line-height: 18px;
}
.jur__page table th {
  background-color: #dce2dd;
}
.jur__page table th,
.jur__page table td {
  vertical-align: middle;
  border-color: #ecefec;
  height: 40px;
  max-height: 40px;
}
.jur__page table td {
  background-color: #fff;
}

.jur__page .header-0 th.undefined {
  background-color: #dce2dd !important;
}

.jur__page .jurTableContainer {
  margin-top: 10px;
  min-height: 0;
  min-height: initial;
}

.empty-header {
  background-color: #fff;
}

/* JUR PROFILE AREA 1 */
.toTop__btn {
  position: fixed;
  bottom: 24px;
  left: 24px;
  transition: bottom 325ms;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: medium none currentColor;
  border: initial;
  color: #a0aa9f;
  background: #ffffff;
  box-shadow: 0 1px 9px #00000029;
  z-index: 2;
}
/* To Top Btn style */
.toTop__btn.hidden {
  bottom: -80px;
}

.jhil__sourceLabel {
  font-weight: 600;
}

.jhil__sourceName {
  margin-right: 10px;
}
.section1-jur1 .table__section > .content__container {
  padding: 0 0 28px 0;
}

.upcomingFontRed {
  color: #eb836b;
}

.upcomingFontGreen {
  color: #70a576;
}

.upcomingFontBlue {
  color: #528598;
}

.noUpcuming {
  max-height: 390px;
  height: 390px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.noUpcuming p {
  margin: auto;
}

.jurCiteBox {
  display: flex;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.jurCiteBox span {
  margin: auto;
  -webkit-filter: brightness(1.5);
          filter: brightness(1.5);
  cursor: pointer;
}

.jurCiteBox span:hover {
  margin: auto;
  -webkit-filter: brightness(1);
          filter: brightness(1);
}

.noData {
  display: flex;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.noDataText {
  margin-top: auto;
  margin-bottom: auto;
  max-width: 760px;
}

.subJurNoData {
  margin-top: 10px;
}

.subJurNoData a {
  color: #528598;
}

.subJurNoData a:hover {
  text-decoration: underline;
}

.jur__page table .tr0 th {
  padding: 2px;
  height: 16px;
  max-height: 16px;
}

h2.subJurSsuTitle {
  position: relative;
  top: 20px;
  left: 48px;
  font-size: 14px;
  font-weight: 700;
}

p.cycleDocsParagraph {
  font-size: 14px;
  line-height: 16px;
}
.cycleDocsParagraph ol {
  margin-top: 10px;
}

.cycleDocsParagraph ol {
  counter-reset: list;
}

.cycleDocsParagraph ol > li {
  list-style: none;
}

.cycleDocsParagraph ol > li:before {
  content: '(' counter(list) ') ';
  counter-increment: list;
}

.jur-profile__page .dd-wrapper,
.filter__modal.jur-profile .dd-wrapper {
  background: #ecefec;
}

.jur-profile__page .dd-wrapper .dd-list-item.WhatItem button,
.filter__modal.jur-profile .dd-wrapper .dd-list-item.WhatItem button {
  background: #ecefec !important;
}

.jur-profile__page .check-container,
.filter__modal.jur-profile .check-container {
  background-color: #ecefec;
}

.jur-profile__page .check-container label,
.filter__modal.jur-profile .check-container label {
  background-color: #ecefec;
}

.jur-profile__page .check-container label:before,
.filter__modal.jur-profile .check-container label:before {
  background-color: #dce2dd;
  border-color: #aaadaa;
}

.jur-profile__page .check-container label:after,
.filter__modal.jur-profile .check-container label:after {
  background-color: #ecefec;
  border-color: #aaadaa;
}

.jur-profile__page .check-container input:checked ~ label:after,
.filter__modal.jur-profile .check-container input:checked ~ label:after {
  background-color: #dce2dd;
  border-color: #aaadaa;
  background-position: -1px -1px;
}

.jur-profile__page .check-container label:after,
.filter__modal.jur-profile .check-container label:after {
  border-color: #aaadaa;
  color: #aaadaa;
}

.jur-profile__page .activeFilter,
.filter__modal.jur-profile .activeFilter {
  background-color: #ecefec;
}

.jur-profile__page .dd-filter input,
.filter__modal.jur-profile .dd-filter input {
  border-color: #aaadaa;
  background-color: #dce2dd;
  color: rgb(65, 65, 65);
}

.jur-profile__page .dd-filter input::-webkit-input-placeholder, .filter__modal.jur-profile .dd-filter input::-webkit-input-placeholder {
  color: rgb(65, 65, 65);
}

.jur-profile__page .dd-filter input::placeholder,
.filter__modal.jur-profile .dd-filter input::placeholder {
  color: rgb(65, 65, 65);
}

.jur-profile__page .sourceTooltip {
  color: #5d9b73;
}

.jur-profile__page .sourceTooltipBody a.pdfLink {
  color: #5d9b73;
  text-decoration: none;
  text-decoration: initial;
  cursor: pointer;
  margin-bottom: auto;
  margin-top: auto;
}
.jur-profile__page .sourceTooltipBody a.pdfLink:hover {
  text-decoration: underline;
}

.jur-profile__page .sourceTooltipBody a.pdfLinkImage {
  color: #5d9b73;
}

.jur-profile__page .tableSources div a {
  color: #5d9b73;
}

.jur-profile__page .tableAreaPx {
  max-height: 700px;
  min-height: 700px;
}

.filter__modal.jur-profile .filterModal__footer .btn.btn-primary {
  background-color: #dce2dd !important;
  border-color: #ecefec !important;
  color: #4e4e4e;
}

.filter__modal.jur-profile .modal-body,
.filter__modal.jur-profile .modal-footer,
.filter__modal.jur-profile .modal-header {
  background-color: #ecefec !important;
  border-color: #ecefec !important;
  color: #4e4e4e;
}

.filter__modal.jur-profile .topicFilterModal__body .check-container label {
  background-color: #ecefec !important;
}

.filter__modal.jur-profile .buttonCountryNames {
  border: 2px solid #aaadaa;
}

.filter__modal.jur-profile .dd-filter input {
  background-color: #aaadaa;
}

.filter__modal.jur-profile .buttonCountryNames .knobs:before {
  background-color: #aaadaa;
}

.filter__modal.jur-profile .filterModal__clearFilter:hover,
.filter__modal.jur-profile .filterModal__clearFilter:focus {
  color: #aaadaa;
}