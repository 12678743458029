.section__page.advancedSearch__page {
  padding-top: 90px;
}
.advancedSearch__page .ach__iconContainer svg {
  -webkit-transform: scale(1.35) translate(0, 0);
          transform: scale(1.35) translate(0, 0);
}

.advancedSearch__page .tableAreaPx {
  min-height: 0;
  min-height: initial;
}
.advancedSearch__page .areaContent__header {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.advancedSearch__page .ach__iconContainer {
  background-color: #afa6c9;
  border: medium none currentColor;
  border: initial;
  width: 38px;
  height: 38px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e1dfe8;
  position: relative;
  top: 6px;
}
.advancedSearch__page .acp__title {
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 22px;
  font-weight: 900;
}
.advancedSearch__page .ach__titlesContainer p {
  max-width: 625px;
  font-size: 16px;
  line-height: 22px;
  color: #000;
}
.advancedSearch__page .acp__formContainer {
  background-color: #eeecf5 !important;
  padding: 50px 50px 34px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.advancedSearch__page .acp__formContainer .acp__formControl {
  width: calc(50% - 12px);
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}
.advancedSearch__page .acp__formContainer .acp__formControl label {
  min-width: 120px;
  width: 120px;
  text-align: right;
  font-size: 15px;
  line-height: 18px;
  color: #000;
  margin-right: 10px;
}
.advancedSearch__page .acp__formControl .reactSelect {
  flex-grow: 1;
}
.advancedSearch__page .acp__actionBar {
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
}

.advancedSearch__page .acp__actionBar .btn {
  margin-left: 16px;
  border-radius: 0;
  background-color: #8a79ba;
  border-color: #8a79ba;
  min-width: 156px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}
.advancedSearch__page .acp__actionBar .btn:hover,
.advancedSearch__page .acp__actionBar .btn:focus,
.advancedSearch__page .acp__actionBar .btn:active {
  background-color: #afa6c9;
  border-color: #afa6c9;
}
.advancedSearch__page .acpfc__doubleHeight {
  flex-grow: 1;
  background: white;
  padding: 22px 30px;
  min-height: 74px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 8px;
}

.advancedSearch__page .acpfc__doubleHeight:first-of-type {
  margin-left: 0px;
}

.advancedSearch__page .acpfc__doubleHeight .purpleDropdown__trigger {
  background-color: #eeecf5;
  font-size: 12px;
  line-height: 12px;
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
  min-width: 120px;
  padding: 6px;
  height: 28px;
}

.advancedSearch__page .acpfc__doubleHeight .purpleDropdown__container {
  margin-left: 20px;
}

.advancedSearch__page .acpfc__doubleHeight input.termBooleanInput {
  width: 100%;
  border-top: 0;
  border-top: initial;
  border-left: 0;
  border-left: initial;
  border-right: 0;
  border-right: initial;
  padding-bottom: 2px;
  border-bottom: 1px solid #e3e0e0;
  margin-right: 10px;
}
.advancedSearch__page .acpfc__doubleHeight.hasTermBooleanInput {
  margin-left: 0 !important;
  padding-left: 18px;
}
.advancedSearch__page .acpfc__doubleHeight input:focus,
.advancedSearch__page .acpfc__doubleHeight input:active,
.advancedSearch__page .acpfc__doubleHeight input:hover {
  border-top: 0;
  border-top: initial;
  border-left: 0;
  border-left: initial;
  border-right: 0;
  border-right: initial;
  outline: none;
  box-shadow: none;
}
.advancedSearch__page .acpfc__doubleHeight svg {
  position: absolute;
  right: 10px;
  color: #c4bbdd;
}
.advancedSearch__page .reactSelect > div,
.advancedSearch__page .acpfc__doubleHeight input.termBooleanInput,
.advancedSearch__page .acp__formContainer .advancedSearch__areasBtn {
  font-size: 14px;
  line-height: 18px;
  color: #707070;
  box-shadow: none;
  box-shadow: initial;
}
.advancedSearch__page .reactSelect svg {
  color: #868686;
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
}

.advancedSearch__page .acpfc__startDate,
.advancedSearch__page .acpfc__endDate {
  height: 38px;
  background-color: #eeecf5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  flex-grow: 1;
}

.advancedSearch__page .acpfc__startDate input,
.advancedSearch__page .acpfc__endDate input {
  background: transparent;
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
  text-align: center;
  flex-grow: 1;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
}

.react-datepicker__input-container input {
  cursor: pointer !important;
}

/* checklist stuff */
.advancedSearch__page .acp__formControl .check-container label {
  background-color: #eeecf5;
  width: 100%;
  text-align: left;
}

.asai__modal .acp__formControl .check-container label {
  background-color: #ffffff;
}
.advancedSearch__page .acp__formControl .check-container label:hover,
.advancedSearch__page .acp__formControl .check-container label:hover:before {
  background-color: #f9f7ff;
}
.advancedSearch__page .acp__formControl .check-container label:before {
  background-color: #ffffff;
}
.advancedSearch__page .check-container label:after {
  content: '';
  border-color: #8a79ba !important;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%238A79BA' fill-rule='nonzero'/%3E%3C/svg%3E") !important;
}

.advancedSearch__page
  .acp__formControl
  .check-container
  input:checked
  ~ label:after {
  background-color: #ffffff;
  border: 2px solid #8a79ba !important;
}
.dropdown-check-list {
  display: inline-block;
  position: relative;
  z-index: 22;
  flex-grow: 1;
  background: white;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: block;
  padding: 10px 50px 5px 10px;
  border: none;
  min-height: 38px;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
}

.dropdown-check-list .anchor.noCuTypes {
  background-color: #eaeaea;
}

.dropdown-check-list .anchor.yesCuTypes {
  background-color: #fff;
}

.advancedSearch__page .dropdown-check-list ul.items {
  background-color: #eeecf5;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  overflow: hidden;
}

.dropdown-check-list .anchor:after,
.advancedSearch__page .acp__formContainer .advancedSearch__areasBtn:after {
  position: absolute;
  content: '';
  border-left: 3px solid #707070;
  border-top: 3px solid #707070;
  padding: 5px;
  right: 13px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg) scale(0.475);
}
.advancedSearch__page .acp__formContainer .advancedSearch__areasBtn {
  position: relative;
  flex-grow: 1;
  border: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 38px;
  align-items: center;
  padding-left: 12px;
  padding-right: 28px;
}
.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  display: none;
  margin: 6px 0 0 0;
  border: medium none currentColor;
  border: initial;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  padding: 20px 25px;
  max-height: 400px;
  overflow-y: scroll;
}

.dropdown-check-list ul.items li {
  list-style: none;
}
.dropdown-check-list.visible .items {
  display: block;
}

/* Datepicker Inner styles */
.advancedSearch__page .react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 12px;
}
.advancedSearch__page
  .react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  border-bottom-color: #eeecf5;
}
.advancedSearch__page .react-datepicker__header {
  background-color: #eeecf5;
}
.advancedSearch__page .react-datepicker {
  font-family: 'Lato', sans-serif;
  border-color: transparent;
  border-radius: 0;
}
.advancedSearch__page .react-datepicker__day--selected,
.advancedSearch__page .react-datepicker__day--in-selecting-range,
.advancedSearch__page .react-datepicker__day--in-range,
.advancedSearch__page .react-datepicker__month-text--selected,
.advancedSearch__page .react-datepicker__month-text--in-selecting-range,
.advancedSearch__page .react-datepicker__month-text--in-range,
.advancedSearch__page .react-datepicker__quarter-text--selected,
.advancedSearch__page .react-datepicker__quarter-text--in-selecting-range,
.advancedSearch__page .react-datepicker__quarter-text--in-range,
.advancedSearch__page .react-datepicker__year-text--selected,
.advancedSearch__page .react-datepicker__year-text--in-selecting-range,
.advancedSearch__page .react-datepicker__year-text--in-range {
  background-color: #8a79ba;
  color: #fff;
}

/* colored labels thing */
.acpfc__footer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-top: 2px solid #d8d5e3;
  padding-top: 26px;
  margin-top: 20px;
  width: 100%;
}

.acpfc__footer .acpfc__selectedLabel {
  padding: 6px 34px 6px 12px;
  background: gray;
  margin-right: 15px;
  margin-bottom: 12px;
  position: relative;
  font-size: 14px;
  line-height: 18px;
}

.acpfc__footer .acpfc__selectedLabel svg {
  position: absolute;
  right: 6px;
  top: 11px;
  color: #868686;
  cursor: pointer;
}

.acpfc__footer .acpfc__selectedLabel.acpsl__blue {
  background-color: #a8d2dd;
}
.acpfc__footer .acpfc__selectedLabel.acpsl__yellow {
  background-color: #eada9f;
}
.acpfc__footer .acpfc__selectedLabel.acpsl__green {
  background-color: #bee0c1;
}
.acpfc__footer .acpfc__selectedLabel.acpsl__brown {
  background-color: #efd6bc;
}
.acpfc__footer .acpfc__selectedLabel.acpsl__purple {
  background-color: #d8d5e3;
}
.acpfc__footer .acpfc__selectedLabel.acpsl__orange {
  background-color: #f0d1d1;
}

/* advanced search - 1 screen */
.acp__content {
  background-color: #eeecf5;
  padding: 28px 0 28px;
  min-height: 60vh;
}

.acp__content .content__container {
  position: static;
  position: initial;
}

.acp__content-header {
  display: flex;
  justify-content: stretch;
  height: 56px;
}
.acp__content-header .acpch__queryResults-container {
  background-color: #eeecf5;
  padding: 8px 42px;
  flex-grow: 1;
  align-items: center;
}
.acp__content-header .acpch__queryResults-container p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 20px;
}

.acp__filter-container {
  width: 240px;
  height: 96px;
}
.acpfc__trigger {
  background-color: #afa6c9;
  height: 56px;
  display: flex;
  padding-top: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
}
.acp__filter-container:nth-child(2) {
  margin: 0 10px;
}

.acpfc__trigger span:first-child {
  font-size: 14px;
  color: #fff;
}
.acpfc__trigger span:nth-child(2) {
  font-size: 16px;
  line-height: 25px;
  margin-top: 5px;
  color: #fff;
  font-weight: 700;
}
.acpfc__trigger span:nth-child(2) svg {
  position: relative;
  top: -2px;
}
.acpfc__trigger span:nth-child(2) path {
  fill: #ffffff;
}

.acpfc__dropdown {
  height: 0;
  overflow: hidden;
  z-index: 12;
  position: relative;
}
.acpfc__dropdown.open {
  height: 162px;
  padding: 10px;
  z-index: 2000;
  background-color: #fff;
}
.acp__filter-container:nth-child(3) .acpfc__dropdown.open {
  height: 140px;
}
.acpfc__dropdown li {
  color: #707070;
  font-size: 14px;
  line-height: 14px;
  padding: 3px 0;
  text-align: center;
  height: 22px;
  cursor: pointer;
  transition: background-color 324ms;
  margin: 6px 0;
}
.acpfc__dropdown li:hover {
  background-color: #f9f7ff;
}
.acp__formControl:nth-child(3) {
  position: relative;
  z-index: 23;
}
.acp__content-body .accordion-body {
  padding: 20px 0 40px;
}

.acp__content-body .accordion-collapse {
  background-color: #eeecf5;
}

table.mainTable.acp__table th {
  height: 40px;
  background: #d8d5e3;
  text-align: center;
  border: 2px solid #eeecf5;
  font-size: 14px;
  line-height: 20px;
  font-weight: 900;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
}

table.mainTable.acp__table th .titleCellHeader {
  font-size: 13px;
  line-height: 20px;
}

table.mainTable.acp__table td {
  padding: 20px 10px;
  vertical-align: middle;
  text-align: start;
  background-color: #ffffff;
  border: 1px solid #eeecf5;
  font-size: 13px;
  line-height: 16px;
}

table.mainTable.acp__table tr:hover td {
  background-color: #e5edf336;
}

table.mainTable.acp__table td.col70 {
  max-width: 70px;
  min-width: 70px;
}

table.mainTable.acp__table td.col98 {
  max-width: 98px;
  min-width: 98px;
  text-align: center;
}

table.mainTable.acp__table td.col100 {
  max-width: 100px;
  min-width: 100px;
}
table.mainTable.acp__table td.col128 {
  max-width: 128px;
  min-width: 128px;
}

table.mainTable.acp__table td.jurTd {
  padding: 6px 6px;

  vertical-align: middle;
}

table.mainTable.acp__table td.col170 {
  max-width: 170px;
  min-width: 170px;
}
table.mainTable.acp__table td.col178 {
  max-width: 178px;
  min-width: 178px;
}

table.mainTable.acp__table td.textCenter {
  text-align: center;
}

.acp__td-rowContainer {
  display: flex;
  justify-content: flex-start;
}
.acp__td-rowContainer img {
  margin-right: 8px;
}

.acp__td-colContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.acp__td-colContainer .acptd__badge {
  background-color: #eeecf5;
  padding: 3px 6px;
  font-weight: 600;
  color: #5f5f5f;
  font-size: 11px;
  display: inline-block;
}
.acp__td-colContainer .btn__iconTransparent:first-child {
  margin-bottom: 32px;
  position: relative;
  top: 12px;
}
.btn__iconTransparent {
  width: 30px;
  height: 30px;
  padding: 0 2px;
  border: medium none currentColor;
  border: initial;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.noteIcon {
  -webkit-transform: rotate(180deg) scaleX(-1);
          transform: rotate(180deg) scaleX(-1);
}
.infoIcon g {
  -webkit-transform: translate(0px, 0px) scale(0.165);
          transform: translate(0px, 0px) scale(0.165);
}
.btn__iconTransparent path {
  transition: fill 225ms;
}
.btn__iconTransparent:hover path {
  fill: #d8d5e3 !important;
}

@media (max-width: 768px) {
  .advancedSearch__page.section__page .acp__formContainer {
    flex-direction: column;
  }
  .advancedSearch__page.section__page .acp__formContainer .acp__formControl {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .advancedSearch__page.section__page
    .acp__formContainer
    .acp__formControl
    label {
    text-align: left;
    margin-bottom: 12px;
    width: 100%;
  }
  .advancedSearch__page.section__page
    .acp__formContainer
    .acp__formControl
    .advancedSearch__areasBtn,
  .advancedSearch__page.section__page
    .acp__formContainer
    .acp__formControl
    .selectRegion__select {
    width: 100%;
  }
  .advancedSearch__page.section__page
    .acp__formContainer
    .acp__formControl
    label[for='selectJurisdiction'] {
    margin-bottom: 0px;
  }
  .advancedSearch__page.section__page .areaContent__header {
    margin-top: 0;
    padding-top: 32px;
  }
  .advancedSearch__page .dropdown-check-list {
    width: 100%;
  }
  .advancedSearch__page .acpfc__doubleHeight {
    margin-left: 0;
    padding: 6px 30px;
    min-height: 52px;
    width: 100%;
  }
  .advancedSearch__page .acpcb__noContent {
    padding: 23px 28px;
  }
  .advancedSearch__page .acp__content {
    padding: 28px 28px 28px 28px;
  }
  .advancedSearch__page .acp__actionBar {
    justify-content: space-between;
  }
  .advancedSearch__page .acp__actionBar .btn:first-child {
    margin-left: 0;
  }
  .advancedSearch__page .asai__modal .modal-body {
    flex-direction: column;
  }
  .advancedSearch__page .acp__title {
    padding-left: 60px;
  }

  .advancedSearch__page .ach__iconContainer {
    position: absolute;
    top: 46px;
  }

  .advancedSearch__page .acp__content-header {
    height: auto;
    height: initial;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .advancedSearch__page .acp__filter-container {
    width: 49%;
    margin: 0;
    margin-top: 12px;
  }
}

.advancedSearch__page .tablePagination button:first-child,
.advancedSearch__page .tablePagination button:last-child {
  background: #eeecf5;
}

.acp__tableContainer .sourceTooltip,
.acp__tableContainer a,
.acp__tableContainer a.pdfLink,
.acp__tableContainer .sourceTooltipBody a.pdfLinkImage {
  color: #7a4595e8;
}

.advancedSearch__page .dd-wrapper.dd-wrapperjur {
  background: #eeecf5;
}

.advancedSearch__page .dd-wrapper .accordion-button {
  background: #eeecf5;
}

.advancedSearch__page .dd-wrapper .accordion-button h5 {
  color: #575757;
}

.advancedSearch__page .dd-wrapper.dd-wrapperjur {
  background: #eeecf5;
}

.advancedSearch__page .acp__formControl .dd-list-item .check-container label {
  background: #eeecf5;
}

.advancedSearch__page .dd-wrapper .accordion-body {
  max-height: 370px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: #eeecf5;
  padding-left: 12px;
}
.advancedSearch__page .check-container {
  background-color: #eeecf5;
}


.acp__formContainer .purpleDropdown__items.open::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.acp__formContainer .purpleDropdown__items.open::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  min-height: 75px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.acp__formContainer .purpleDropdown__items.open::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.acp__formContainer .purpleDropdown__items.open::-webkit-scrollbar-corner {
  background-color: transparent;
}

.purpleDropdown__item:hover,
.acpfc__dropdown li:hover {
  background-color: #d4d0e3;
}

.acp__a3a4a5warning{
  font-style: italic;
  margin-top:8px;
  width: 100%;
}