.area__page .selectRegion__section > .content__container {
  padding-top: 100px;
  background: #d8e3e5;
  margin-top: 66px;
}

.area__page.area1__page .selectRegion__section > .content__container,
.area__page.area2__page .selectRegion__section > .content__container,
.area__page.area3__page .selectRegion__section > .content__container,
.area__page.area5__page .selectRegion__section > .content__container,
.area__page.area6__page .selectRegion__section > .content__container {
  padding-top: 106px;
  padding-bottom: 22px;
  margin-top: 56px;
}
.area__page.area6__page .selectRegion__section > .content__container {
  padding-top: 64px;
}
.content__container.mapContent__container > div:first-child {
  position: relative;
  top: 20px;
}
.area6__page .content__container.mapContent__container > div:first-child {
  top: 0;
}
.area__page.area4__page .selectRegion__section > .content__container {
  margin-top: 56px;
}
.area__page .selectRegion__section > .content__container {
  padding-top: 104px;
  margin-top: 52px;
}

.area__page .selectRegion__section,
.area3__page .selectRegion__section,
.area4__page .selectRegion__section {
  min-height: 874px;
}

.areaContent__subTitle .text-blue {
  color: #6ea2b5;
}
.area3__page #sunburstChartDiv g[aria-labelledby='id-227-title'] {
  -webkit-transform: translate(10px, 379px);
          transform: translate(10px, 379px);
}
.area3__page .table__section .areaContent__subTitle.secondSubtitle {
  margin-top: 100px;
}

/* custom scrollbar */
.area3__page .tb__barChart-area3::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border: 1px solid black;
}
.area3__page .tb__barChart-area3::-webkit-scrollbar-track {
  background: rgb(229, 229, 229);
  opacity: 0.75;
}
.area3__page .tb__barChart-area3::-webkit-scrollbar-thumb {
  background: #b0cbce;
  border-radius: 5px;
}
.area3__page .tb__barChart-area3::-webkit-scrollbar-thumb:hover {
  background: #609696;
  border-radius: 5px;
}

.area__page.area6__page .selectRegion__section {
  min-height: 255px;
}

.area__page .selectRegion__section img {
  height: auto;
  width: 100%;
  margin: 0 auto;
  display: block;
}

.area__page .table__section > .content__container {
  margin-bottom: 46px;
}
.area__page .area2Table__section.table__section > .content__container {
  margin-bottom: 0;
  padding-bottom: 28px;
}

.area__page .selectRegion__inputs-container {
  top: 42px;
  align-items: center;
}

.mainGraphic__section img {
  width: 100%;
  height: auto;
}

.mapLegend {
  display: flex;
  width: 100%;
}
.map__alternate .mapLegend {
  position: relative;
  top: 0px;
}
.area5__page.map__alternate .MapChartSettings__container,
.area5__page.map__alternate .RegionMapSelect__container,
.area5__page.map__alternate .MapInfoBtn__container {
  bottom: 4px;
}

.cursorQuestion {
  cursor: help;
}

.mapLegend__item {
  width: 140px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  position: relative;
  top: 4px;
  -webkit-user-select: none;
          user-select: none;
}

.mapLegend__item .label {
  width: 130px;
  margin: auto;
  margin-top: 0px;
}

.mapLegend__item.legend150 {
  width: 150px;
}

.mapLegend__item.legend150 .label {
  width: 140px;
  margin: auto;
  margin-top: 0px;
}

.mapLegend__item.legend175 {
  width: 175px;
}

.mapLegend__item.legend175 .label {
  width: 165px;
}

.mapLegend__item .stripe {
  height: 5px;
  width: 100%;
  margin-bottom: 12px;
}

.mapLegend__item_10 {
  width: 90px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.mapLegend__item_10 .stripe {
  height: 5px;
  width: 100%;
  margin-bottom: 12px;
}

.mapLegend__gradient_container {
  width: 60%;

  justify-content: space-between;
}

.mapLegend__gradient {
  height: 5px;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  flex-direction: row;
  margin-bottom: 12px;
}

.mapLegend__gradient_label_container {
  width: 100%;
  display: flex;

  justify-content: space-between;
}

.mapLegend__gradient_label {
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.mapLegendStepGradient {
  display: flex;
  max-width: 450px;
  flex-direction: column;
}
.mapLegendStepGradient__title {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}
.mapLegendStepGradient__title_left {
  display: flex;
  justify-content: left;
  margin-bottom: 8px;
}
.mapLegendStepGradient__steps {
  display: flex;
  flex-wrap: wrap;
}

.areaContent__title {
  font-size: 28px;
  line-height: 22px;
  text-align: center;
  margin: 30px 0 8px 0;
  font-weight: 600;
  color: #6ea2b5;
  text-transform: uppercase;
}

.areaContent__subTitle {
  text-align: center;
  font-size: 28px;
  line-height: 28px;
  margin: 10px 0 0px 0;
  font-weight: 400;
}

.areaContent__subTitle2 {
  text-align: center;
  font-size: 28px;
  line-height: 28px;
  margin: 10px 0 40px 0;
  font-weight: 600;
}

.content__container .areaContent__section .content__container {
  display: flex;
  justify-content: space-between;
}

.areaContent__left,
.areaContent__right {
  background-color: #f4f8f8;
  padding: 38px 38px 18px 38px;
}

.areaContent__left {
  width: calc(60% - 14px);
  display: flex;
  flex-direction: column;
}

.areaContent__left p {
  color: black;
}

.areaContent__left a {
  color: #5c8f95;
}
.areaContent__left a:hover {
  color: #324e52;
  text-decoration: underline;
}

.areaContent__section .areaContent__left li {
  font-size: 14px;
}
.areaContent__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0 20px;
  position: relative;
}

.areaContent__header .ach__titlesContainer {
  padding-left: 12px;
}

.area1__page .areaContent__header {
  margin-bottom: 26px;
}

.area2__page .table__section .areaContent__header {
  margin: 0 0 80px;
}

.area4__page .table__section .areaContent__header {
  margin: 0 0 0 0;
}

.area2__page .info__section {
  padding: 40px 0;
}

.ach__iconContainer {
  padding: 15px 13px 13px;
  border: 6px solid #e7edef;
  margin-right: 11px;
}

.ach__iconContainer {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: medium none currentColor;
  border: initial;
  background: #b0cbce;
}
.areaContent__header {
  margin-bottom: 0;
  flex-grow: 1;
}
.areaContent__header .areaContent__title {
  font-size: 14px;
  line-height: 16px;
  color: #000;
  margin-bottom: 0;
}
.areaContent__header .areaContent__subTitle {
  font-size: 20px;
  line-height: 20px;
  color: #4e4e4e;
  font-weight: 900;
}

.area6__page .ach__iconContainer svg {
  -webkit-transform: scale(1.6) translate3d(0, 0, 0);
          transform: scale(1.6) translate3d(0, 0, 0);
}
.area6__page .ach__iconContainer svg path:nth-child(1) {
  -webkit-transform: translate3d(5px, 10px, 0);
          transform: translate3d(5px, 10px, 0);
}
.area6__page .ach__iconContainer svg path:nth-child(2) {
  -webkit-transform: translate3d(90px, 30px, 0) rotate(180deg);
          transform: translate3d(90px, 30px, 0) rotate(180deg);
}
.area6__page.area__page .selectRegion__inputs-container {
  top: 58px;
}
.ach__iconContainer svg {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.area4__page .ach__iconContainer svg {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
.ach__titlesContainer .areaContent__title,
.ach__titlesContainer .areaContent__subTitle {
  text-align: left;
}

.ach__titlesContainer .areaContent__title {
  font-weight: 400;
  margin-top: 0px;
}

.ach__titlesContainer .areaContent__subTitle {
  margin: 0 0 0 0;
}

.table__section .ach__titlesContainer {
  padding: 20px 12px;
}

.table__section .ach__titlesContainer .areaContent__subTitle {
  margin-bottom: 0;
}

.table__section .ach__iconContainer {
  border-color: #b0cbce;
}

.ach__fullScreenButtonContainer {
  position: relative;
  height: 40px;
}

.ach__fullScreenButtonContainer .fullscreenButton {
  position: absolute;
  right: 15px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  padding: 8px 10px 5px 8px;
  background-color: #b0cbce;
  justify-content: space-between;
  width: 120px;
}

.ach__fullScreenButtonContainer .fullscreenButton.es {
  width: 170px;
}

/* React select like design */
.selectRegion__inputs-container
  .selectTreaty__select
  .css-g1d714-ValueContainer,
.selectRegion__inputs-container .selectTreaty__select .css-1hwfws3,
.selectRegion__inputs-container
  .selectTreaty__select
  .css-1hb7zxy-IndicatorsContainer,
.selectRegion__inputs-container
  .selectTreaty__select
  .css-1gtu0rj-indicatorContainer,
.selectRegion__inputs-container
  .selectTreaty__select
  .css-tlfecz-indicatorContainer {
  background: #d8e3e5;
  height: 44px;
  color: #000;
}
.selectRegion__inputs-container
  .selectTreaty__select
  .css-1gtu0rj-indicatorContainer
  svg,
.selectRegion__inputs-container
  .selectTreaty__select
  .css-tlfecz-indicatorContainer
  svg {
  position: relative;
  top: 4px;
}
.selectRegion__inputs-container .selectTreaty__select .css-1wa3eu0-placeholder,
.selectRegion__inputs-container .selectTreaty__select svg {
  color: #000;
}
.selectRegion__inputs-container .selectTreaty__select .css-11unzgr > div,
.selectRegion__inputs-container
  .selectTreaty__select
  .css-4ljt47-MenuList
  > div {
  font-size: 14px;
  line-height: 28px;
  border-top: 0;
  border-top: initial;
  padding: 2px 0 2px 16px;
  max-width: 300px;
}
.css-1n7v3ny-option {
  /* FOCUSED STATE OF INPUT */
  background-color: #f4f4f4 !important;
}
.areaContent__right {
  width: calc(40% - 14px);
}

.areaContent__section {
  margin: var(--verticalMargin);
}

.areaContent__section li {
  font-size: 16px;
  line-height: 24px;
}

.areaContent__section li:not(:last-child) {
  margin-bottom: 16px;
}

.areaContent__section .areaContent__right h5,
.areaContent__section .areaContent__left h5,
.area6__contentSection h5 {
  margin: 0px 0px 20px 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.areaContent__section .areaContent__right li {
  margin-bottom: 12px;
}

.areaContent__section .update__item {
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.areaContent__section .update__item .itemTitle {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-bottom: 4px;
}

.areaContent__section .update__item .itemContent {
  font-size: 14px;
  color: #626363;
}



.areaContent__section .update__item .itemDate {
  font-size: 14px;
  font-weight: 400;
  color: #528598;
}

.area__page .mainGraphic__section {
  margin: var(--verticalMargin);
  padding: var(--boxPaddings);
  background: #f4f8f8;
  position: relative;
  min-height: 512px;
}
.area__page .mainGraphic__section.mgs__paddingFix {
  padding-bottom: 55px;
}
.area__page .mainGraphic__section.mgs__pieChart-container {
  padding: 38px 38px 38px 38px;
}
.area__page .mainGraphic__section.mgs__pieChart-container h5,
.area__page .mainGraphic__section.mgs__pieChart-container h4 {
  margin-left: 0;
}

.area__page .mainCTA__2charts {
  position: relative;
}
.area__page .mainCTA__2charts .mainGraphic__section {
  margin: 0 0 0 0;
}
.area__page .mainGraphic__section h5 {
  margin: 0px 0px 10px 15px;
  font-size: 18px;
  font-weight: 600;
}

.area__page .mainGraphic__section h4 {
  margin: 0px 0px 10px 15px;
  font-size: 15px;
  color: #b0cbce;
  font-weight: 400;
}

.area__page .recs__left h5 {
  margin: 0px 0px 10px 0px;
  font-size: 18px;
  font-weight: 600;
}

.area__page .recs__left h4 {
  margin: 0px 0px 10px 0px;
  font-size: 15px;
  color: #b0cbce;
  font-weight: 400;
}

.table__section {
  margin: var(--verticalMargin);
  padding: 55px 0;
  background-color: #d8e3e5;
}

.table__section h2,
.table__section h4 {
  text-align: left;
  margin-top: 0;
}

.table__section .areaContent__subTitle {
  margin-bottom: 66px;
}
.table__section h2.areaContent__subTitle {
  margin-bottom: 0px;
}

.area__page + footer {
  margin-top: 0;
}

.largeBlueRectangle {
  min-height: 140px;
  background: #f4f8f8;
}

.quickSearch__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 20px;
}

.quickSearch__container h5 {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

.quickSearch__container h6 {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  margin-top: -6px;
}

.individualComm__title {
  margin-top: 30px;
}

.quickSearch__left {
  width: 65%;
}

.quickSearch__right {
  width: 33%;
}

.quickSearch__full {
  width: 100%;
}

.quickSearch__full .searchItems2__container {
  display: flex;
  flex-direction: row;
}

.searchItems__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.searchItems__container .searchItem__box {
  width: 32%;
  background: #f4f8f8;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 28px 28px 40px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: color 225ms, background-color 225ms;
}

.searchItems__container .searchItem__box:nth-child(7),
.searchItems__container .searchItem__box:nth-child(8),
.searchItems__container .searchItem__box:nth-child(9) {
  margin-bottom: 0;
}

.searchItems__container .searchItem__box:hover > * {
  color: #fff;
}

.searchItem__box .searchItem__body {
  font-size: 16px;
  line-height: 18px;
  color: #528598;
}

.searchItem__box .searchItem__name {
  color: #000;
  font-size: 20px;
  line-height: 22px;
  margin-top: 8px;
  font-weight: 900;
}

.searchItem__box .searchItem__total {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 18px;
  line-height: 26px;
  color: #575757;
}

.searchItems2__container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 45px);
  justify-content: space-between;
  color: #fff;
}

.searchItem2__box {
  height: 120px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 900;
  background: #e9eff0;
  color: #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  flex-grow: 1;
  margin: 0 4px;
  cursor: pointer;
  transition: color 225ms, background-color 225ms;
}

.searchItems__container .searchItem__box:hover,
.searchItems2__container .searchItem2__box:hover,
.quickSearch__full .searchItem2__box:hover,
.quickSearch__full .searchItem2__box.active {
  color: #fff !important;
  background: #69909f;
}

.table__quickLinks {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
}

.area5__page .mainMap__quickLinks-container {
  justify-self: flex-end;
  display: flex;
  justify-content: stretch;
  margin-left: auto;
}

.area5__page .mainMap__quickLinks-container button {
  border-radius: 0;
  padding: 5px 24px;
  border: 2px solid white;
}

.area5__page .mainMap__quickLinks-container button:hover,
.area5__page .mainMap__quickLinks-container button:focus,
.area5__page .mainMap__quickLinks-container button.active {
  background-color: #f4f4f4;
  color: #88a8b4;
}

.btn-outline-light {
  color: #88a8b4;
  border-color: #ffffff;
}

.area5__page .mainMap__quickLinks-container button:nth-child(2) {
  border-right: none;
  border-left: none;
}

.area5__page .table__section .areaContent__header,
.area6__page .table__section .areaContent__header {
  margin: 0 0 46px;
}

.table__quickLinks .tql__item {
  width: 32%;
  background-color: #e9eff0;
  display: flex;
  align-items: center;
  padding: 56px 20px;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  font-weight: 600;
  transition: color 225ms, background-color 225ms;
}

.table__quickLinks .tql__item span {
  margin: 0 auto;
}

.table__quickLinks .tql__item:hover {
  background-color: #69909f;
  color: #ffffff;
  cursor: pointer;
}

.table__search-container {
  width: 100%;
  max-width: 566px;
  text-align: left;
  position: relative;
}

.table__search-input {
  width: 100%;
  max-width: 566px;
  height: 36px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.18);
  outline: none;
  border: none;
  font-size: 15px;
  padding: 16px 20px;
  color: #000;
}

.table__search-container svg {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #d8e3e5;
}

.content__right ul {
  list-style: none;
  padding: 0 40px 40px 40px;
}

.content__right li {
  margin-bottom: 8px;
}

.update__item div {
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.update__item .top__row {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  align-items: center;
  margin-bottom: 3px;
}

.update__item .top__row .country,
.update__item .top__row .linkHeadline {
  font-size: 17px;
  font-weight: 600;
  margin: 0 5px;
}

.update__item .top__row .linkHeadline {
  color: #528598;
}

.update__item .top__row a {
  text-decoration: none;
}

.update__item .itemTitle {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.update__item {
  border-bottom: 1px dashed #cbcbcb;
  padding-bottom: 16px;
  padding-top: 12px;
}

.update__item:last-child {
  border-bottom: none;
}

.update__item .itemContent {
  font-size: 14px;
  line-height: 22px;
  color: #000;
  margin-bottom: 4px;
  display: block;
}

.update__item .itemDate {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #528598;
}

.update__item .itemDate:first-letter {
  text-transform: capitalize;
}

.areaContent__section p,
.content__section p {
  font-size: 14px;
  line-height: 22px;
}

/* area 2 TABLE CSS */
.area2Table__section.table__section {
  margin-top: 0;
  padding: 12px 0 0;
}

.a2ts__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}

.a2ts__header .a2ts__title {
  color: #000;
  font-size: 20px;
  line-height: 22px;
  font-weight: 900;
  margin-bottom: 0;
}

.a2ts__header .btn.btn-link {
  font-size: 16px;
  line-height: 16px;
  color: #000;
  text-decoration: none;
  padding: 0 0 0 0;
}

.a2ts__header svg {
  margin-left: 12px;
}

.table-responsive {
  padding-bottom: 5px;
}

.a2ts__body table {
  border-color: transparent;
  width: 100%;
}

.a2ts__body table th,
.a2ts__body table td {
  border: 3px solid #d8e3e5;
}

.a2ts__body table th {
  height: 96px;
  text-align: center;
  background-color: #b0cbce;
  font-size: 16px;
  line-height: 16px;
  font-weight: 900;
  padding: 0 40px;
}

.a2ts__body table td {
  height: 60px;
  background: #e9eff0;
}

.a2ts__body .protectionCategoryId {
  font-weight: bold;
}

/* end of area2 TABLE CSS*/

/* Area 4 recommendations section */
.recommendations__section {
  margin: var(--verticalMargin);
  display: flex;
  justify-content: space-between;
}

.recommendations__section .recs__left,
.recommendations__section .recs__right {
  padding: 36px 46px;
  background-color: #f4f8f8;
  width: 100%;
  margin-top: 0px;
}

.recommendations__section .recs__right img {
  width: 100%;
}

.recommendations__section .recs__left {
  width: calc(40% - 14px);
}

.recommendations__section .recs__right {
  width: calc(60% - 14px);
}

.recommendations__section .recs__title {
  font-size: 25px;
  line-height: 30px;
  font-weight: 900;
  color: #000;
  margin-bottom: 24px;
}

.recommendations__section .recs__actions {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin-top: 18px;
  margin-bottom: 22px;
}

.recs__tabBtn {
  flex-grow: 1;
  background-color: transparent;
  border: 2px solid #b0cbce;
  padding: 15px;
  color: #000;
  font-size: 14px;
  line-height: 16px;
}

.recs__tabBtn:nth-child(1) {
  border-right: 0;
  border-right: initial;
}

.recs__tabBtn.active {
  background: #b0cbce;
}
.recommendations__list {
  max-height: 422px;
  overflow: auto;
}
.recommendations__listItem {
  display: flex;
  min-height: 40px;
}

.recommendations__listItem span {
  background: #d8e3e5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  color: #707070;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.recommendations__listItem span:nth-child(1) {
  background: #b0cbce;
  color: #fff;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
}

.recommendations__listItem span:nth-child(2) {
  flex-grow: 1;
  margin: 0 6px 6px;
  justify-content: flex-start;
  padding: 12px 20px;
}

.recommendations__listItem span:nth-child(3) {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}

.recommendations__list::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.recommendations__list::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  min-height: 75px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.recommendations__list::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.recommendations__list::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* END OF Area 4 recommendations section */
.area__page .mapLoader__container > #loading {
  display: flex;
  align-self: center !important;
  justify-content: center !important;
  align-items: center !important;
}
@media (max-width: 768px) {
  .area3__page .quickSearch__container h6 {
    margin-bottom: 0;
  }
  .mapContent__container .areaContent__header {
    padding-top: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-self: flex-start;
  }
  .mapContent__container .ach__iconContainer svg {
    -webkit-transform: none !important;
            transform: none !important;
    -webkit-transform: initial !important;
            transform: initial !important;
  }
  .mapContent__container .ach__titlesContainer {
    margin-left: 12px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mapContent__container .ach__titlesContainer .areaContent__title,
  .mapContent__container .ach__titlesContainer .areaContent__subTitle {
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
  }
  .mapContent__container div.ach__iconContainer {
    width: 60px;
    height: 60px;
  }
  .mapContent__container .selectCategory__container {
    margin: 4px 0;
  }
  .areaContent__header {
    margin: 0 0 26px;
    padding-top: 28px;
    flex-direction: column;
  }
  .table__section:nth-child(2) .areaContent__header {
    margin-top: 0;
    padding-top: 0;
  }

  div.ach__iconContainer {
    width: 118px;
    height: 118px;
    margin-bottom: 0;
  }

  .ach__iconContainer svg {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
  }
  .area1__page .ach__iconContainer svg {
    -webkit-transform: scale(1.6) translate(1px, 2px);
            transform: scale(1.6) translate(1px, 2px);
  }

  .area3__page .ach__iconContainer svg {
    -webkit-transform: scale(1.8) translate(1px, 0);
            transform: scale(1.8) translate(1px, 0);
  }

  .area4__page .ach__iconContainer svg {
    -webkit-transform: scale(1.1) translateY(5px);
            transform: scale(1.1) translateY(5px);
  }

  .area5__page .ach__iconContainer svg {
    -webkit-transform: scale(1.7) translate(2px, 1px);
            transform: scale(1.7) translate(2px, 1px);
  }

  .area6__page .ach__iconContainer svg {
    -webkit-transform: scale(1.6) translate3d(5px, 3px, 1px);
            transform: scale(1.6) translate3d(5px, 3px, 1px);
    overflow: visible;
  }

  .ach__titlesContainer {
    margin-top: 20px;
  }

  div.css-13936z3-option {
    padding: 12px 24px;
  }

  .area2__page .areaContent__right .circle {
    margin: 0 auto;
  }

  .area2__page .areaContent__section .areaContent__right {
    padding: 0 0 0 0;
  }

  .mapLegend {
    flex-wrap: wrap;
    width: 100%;
  }

  .area1__page .mapLegend {
    top: -80px;
  }

  .mapLegend__item {
    width: 50%;
    margin-bottom: 12px;
  }

  .selectRegion__inputs-container {
    flex-direction: column;
  }

  .area__page .selectRegion__section > .content__container {
    margin-top: 40px;
    padding-top: 140px;
  }

  .content__container .areaContent__section .content__container {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }

  .areaContent__section .areaContent__left {
    margin-bottom: 20px;
    position: relative;
  }

  .areaContent__section .areaContent__left,
  .areaContent__section .areaContent__right {
    width: 100%;
  }

  .area__page .mainGraphic__section {
    padding: 20px;
    margin-top: 20px;
    position: relative;
  }

  .areaContent__title,
  .areaContent__subTitle {
    text-align: left;
  }

  .areaContent__subTitle {
    margin-top: 6px;
    line-height: 25px;
  }

  .table__section .areaContent__subTitle {
    margin-bottom: 0;
  }

  .area2__page .areaContent__section {
    margin-bottom: 0;
  }

  .selectRegion__inputs-container
    .selectTreaty__select
    .css-g1d714-ValueContainer,
  .selectRegion__inputs-container
    .selectTreaty__select
    .css-1hb7zxy-IndicatorsContainer {
    height: 37px;
  }
}
@media (min-width: 1020px) {
  .area__page .selectRegion__section img {
    height: 100%;
    width: auto;
  }
}

.area5TooltpipBody {
  font-weight: 400;
  max-height: 200px;
  padding-right: 5px;
  overflow: auto;
}

.area5TooltpipBody::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border: 1px solid black;
}
.area5TooltpipBody::-webkit-scrollbar-track {
  background: rgb(229, 229, 229);
  opacity: 0.75;
}
.area5TooltpipBody::-webkit-scrollbar-thumb {
  background: #b0cbce;
  border-radius: 5px;
}
.area5TooltpipBody::-webkit-scrollbar-thumb:hover {
  background: #609696;
  border-radius: 5px;
}

.latestUpdates {
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
}
.latestUpdates .list-unstyled {
  overflow: auto;
}

.latestUpdates h5 {
  position: relative;
}
.showingEdit + .areaContent__right .list-unstyled {
  max-height: none;
  max-height: initial;
}

.latestUpdates .list-unstyled::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.latestUpdates .list-unstyled::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  min-height: 75px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

@media (min-width: 1040px) {

  .mainGraphic__section.pieChart__height-fix #pieChartDiv {
    min-height: 465px !important;
  }
  .fixChartSymetry svg {
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: scale(1.025);
            transform: scale(1.025);
  }
  .fixChartSymetry-right svg {
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: scale(1.025) translate(-6px, 0);
            transform: scale(1.025) translate(-6px, 0);
  }
  .recommendations__section .recs__right svg {
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: scale(1.055) translate(-22px, 0);
            transform: scale(1.055) translate(-22px, 0);
  }
  .recommendations__section .recs__right h4,
  .recommendations__section .recs__right h5 {
    margin-left: 0;
  }
  .fixChartSymetry-right g[aria-labelledby="id-201-title"],
  .fixChartSymetry-right g[aria-labelledby="id-16632-title"],
  .fixChartSymetry-right g[aria-labelledby="id-26404-title"] {
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: translate(16px, 428px);
            transform: translate(16px, 428px);
  }
}
