.yearDropdown__container {
    position: relative;
}
.yearDropdown__container p {
    cursor: pointer;
    font-size: 13px;
    line-height: 24px;
    font-weight: 600;
    color: #707070;
    background: #ffffff;
    width: 80px;
    height: 23px;
    margin-bottom: 0;
    padding: 0 16px;
}
.yearDropdown__list.hidden  {
    height: 0;
    overflow: hidden;

}
.yearDropdown__list {
    height: auto;
    min-width: 110px;
    position: absolute;
    bottom: calc(100% + 32px);
    transition: height 325ms;
    background: #FFFFFF;
    padding: 15px 15px 0;
    left: -18px;
    box-shadow: 0 3px 6px #00000029;
    text-align: center;
    z-index: 1;
}
.yearDropdown__list p {
    border-bottom: 2px solid #CAD1C9;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #707070;
}
.yearDropdown__list p.langSelected {
    order: -1;
    color: #BFBFBF;
}
.yearDropdown__list p:first-child {
   padding-top: 0;
}
.yearDropdown__list p:last-child {
    border-bottom: 0;
    border-bottom: initial;
}
.overflow-hidden {
    overflow: hidden;
}