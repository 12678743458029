.MapChartSettings__container {
  position: absolute;
  right: 0;
  bottom: 20px;
}

.area2__page .MapChartSettings__container {
  bottom: 4px;
}

.area3__page .MapChartSettings__container,
.area5__page .MapChartSettings__container,
.area1__page.has__category-selector  .MapChartSettings__container {
  bottom: 24px;
}

.area4__page .MapChartSettings__container {
  bottom: 0px;
}

.MapChartSettings__container .SourceText__container,
.MapChartSettings__container {
  width: 52px;
  height: 48px;
}

.mapChartSettings__trigger {
  position: relative;
  cursor: pointer;
  width: 52px;
  height: 48px;
  background-color: #E9EFF0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 225ms;
}
.mapChartSettings__trigger:hover {
  background-color: #FFF;
}
.mapChartSettings__trigger path:nth-child(2) {
  fill: #A4CAD1;
  transition: fill 225ms;
}
.mapChartSettings__trigger:hover path:nth-child(2) {
  fill: #6EA2B5;
}
.MapChartSettings__menu {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
}

.showMapSettings.MapChartSettings__menu {
  display: flex;
  flex-direction: column;
  width: 360px !important;
  height: 500px !important;
  position: absolute;
  top: -520px;
  background: #fff;
  right: 0;
  box-shadow: 0 3px 6px #00000029;
  padding: 26px;
  overflow: visible;
  z-index: 100;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #fff;
  position: absolute;
  bottom: 16px;
  right: 18px;
}

.showMapSettings.MapChartSettings__menu .arrow-down {
  bottom: -13px;
}

.mcs__header {
  display: flex;
  justify-content: space-between;
}

.mcs__header > div {
  background: #f4f8f8;
  padding: 18px;
}

.mcs__header > div p {
  color: #575757;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
}

.mcs__header-left {
  width: 54%;
}

.mcs__header-right {
  width: 43%;
}

.mcs__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.mcs__item {
  margin: 6px 0;
  height: 70px;
  background: #f4f8f8;
  display: flex;
  justify-content: space-between;
  color: #575757;
  font-size: 14px;
  line-height: 22px;
}

.mcs__item:last-child {
  flex-grow: 2;
  height: auto;
  height: initial;
}

.mcs__itemTitle {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 22px 0 22px 22px;
}

.mcs__imageLinks-container {
  display: flex;
  flex-direction: column;
}

/* toggle */
.mcs__toggle {
  position: relative;
  padding: 22px 22px 0 0;
}

.mcs__links {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 32px;
}

.mcs__links span {
  margin-left: 12px;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
  color: #528598;
  cursor: pointer;
}

.mcs__links a:hover {
  text-decoration: underline;
}

.mcs-link {
  font-size: 14px;
  line-height: 16px;
}

.mcs__links-header,
.mcs__links-body {
  display: flex;
  align-items: center;
  padding: 18px;
}

.mcs__links-body {
  padding-top: 4px;
}

.mcs__imageLinks-container .mcs__link {
  font-size: 11px;
  line-height: 16px;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.buttonMicrostates,
.buttonCountryNames,
.buttonQuality,
.buttonCenterMap,
.buttonChartToggle {
  position: relative;
  top: -9px;
  width: 160px;
  height: 40px;
  margin: 0 auto 0 auto;
  overflow: hidden;
  border: 2px solid #96c1d1;
}

.buttonMicrostates.disabled,
.buttonCountryNames.disabled,
.buttonQuality.disabled,
.buttonCenterMap.disabled,
.buttonChartToggle.disabled {

  border: 2px solid #ccc;
}

.buttonCountryNames {
  width: 130px;
}

.buttonQuality {
  width: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #f4f8f8;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 10 */
.buttonMicrostates .knobs:before,
.buttonMicrostates .knobs:after,
.buttonMicrostates .knobs span,
.buttonCountryNames .knobs:before,
.buttonCountryNames .knobs:after,
.buttonCountryNames .knobs span,
.buttonQuality .knobs:before,
.buttonQuality .knobs:after,
.buttonQuality .knobs span,
.buttonCenterMap .knobs:before,
.buttonCenterMap .knobs:after,
.buttonCenterMap .knobs span,
.buttonChartToggle .knobs:before,
.buttonChartToggle .knobs:after,
.buttonChartToggle .knobs span {
  position: absolute;
  top: 4px;
  width: 72px;
  height: 28px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 12px;
  padding: 9px 4px;
  border-radius: 2px;
  transition: 0.3s ease all;
}

.buttonCountryNames .knobs:before,
.buttonCountryNames .knobs:after,
.buttonCountryNames .knobs span {
  width: 56px;
}

.buttonQuality .knobs:before,
.buttonQuality .knobs:after,
.buttonQuality .knobs span {
  width: 45px;
}

.buttonCenterMap .knobs:before,
.buttonCenterMap .knobs:after,
.buttonCenterMap .knobs span {
  width: 72px;
}

.buttonChartToggle .knobs:before,
.buttonChartToggle .knobs:after,
.buttonChartToggle .knobs span {
  width: 108px;
}

.buttonMicrostates .knobs:before,
.buttonCountryNames .knobs:before,
.buttonQuality .knobs:before,
.buttonCenterMap .knobs:before,
.buttonChartToggle .knobs:before {
  content: "";
  left: 4px;
  background-color: #96c1d1;
}
.buttonMicrostates .knobs.disabled:before,
.buttonCountryNames .knobs.disabled:before,
.buttonQuality .knobs.disabled:before,
.buttonCenterMap .knobs.disabled:before,
.buttonChartToggle .knobs.disabled:before {
  content: "";
  left: 4px;
  background-color: #ddd;
}

.buttonMicrostates .knobs:after,
.buttonCountryNames .knobs:after,
.buttonQuality .knobs:after,
.buttonCenterMap .knobs:after,
.buttonChartToggle .knobs:after {
  content: "Actual Size";
  right: 4px;
  color: #000;
}


.buttonMicrostates .knobs.disabled:after,
.buttonCountryNames .knobs.disabled:after,
.buttonQuality .knobs.disabled:after,
.buttonCenterMap .knobs.disabled:after,
.buttonChartToggle .knobs.disabled:after {
  content: "Actual Size";
  right: 4px;
  color: #ccc;
}

.buttonMicrostates .knobs-es:after{
  content: "Tamaño real";
}
.buttonCountryNames .knobs-en:after {
  content: "Display";
  right: 3px;
}

.buttonCountryNames .knobs-es:after {
  content: "Mostrar";
  right: 3px;
}

.buttonQuality .knobs:after {
  content: "HQ";
}

.buttonQuality .knobs-es:after {
  content: "Alta";
}

.buttonQuality .knobs.disabled:after {
  content: "HQ";
}

.buttonQuality .knobs-es.disabled:after {
  content: "Alta";
}

.buttonCenterMap .knobs:after {
  content: "Pacific";
}
.buttonCenterMap .knobs-es:after {
  content: "Pacifico";
}
.buttonChartToggle .knobs:after {
  content: "Date Range";
}
.buttonCenterMap .knobs.disabled:after {
  content: "Pacific";
}

.buttonCenterMap .knobs-es.disabled:after {
  content: "Pacifico";
}

.buttonMicrostates .knobs span,
.buttonCountryNames .knobs span,
.buttonQuality .knobs span,
.buttonCenterMap .knobs span,
.buttonChartToggle .knobs span {
  display: inline-block;
  left: 4px;
  color: #fff;
  z-index: 1;
}

.buttonMicrostates .checkbox:checked + .knobs span,
.buttonCountryNames .checkbox:checked + .knobs span,
.buttonQuality .checkbox:checked + .knobs span,
.buttonCenterMap .checkbox:checked + .knobs span,
.buttonChartToggle .checkbox:checked + .knobs span {
  color: #000;
}

.buttonMicrostates .checkbox:checked + .knobs:before,
.buttonCenterMap .checkbox:checked + .knobs:before {
  left: 80px;
}

.buttonCountryNames .checkbox:checked + .knobs:before {
  left: 66px;
}

.buttonQuality .checkbox:checked + .knobs:before {
  left: 46px;
  width: 45px;
}

.buttonMicrostates .checkbox:checked + .knobs:after,
.buttonCountryNames .checkbox:checked + .knobs:after,
.buttonQuality .checkbox:checked + .knobs:after,
.buttonCenterMap .checkbox:checked + .knobs:after,
.buttonChartToggle .checkbox:checked + knobs:after {
  color: #fff;
}

.buttonMicrostates .checkbox:checked ~ .layer,
.buttonCountryNames .checkbox:checked ~ .layer,
.buttonQuality .checkbox:checked ~ .layer,
.buttonCenterMap .checkbox:checked ~ .layer {
  background-color: #f4f8f8;
}

.area__page .selectRegion__section .mcs__links-header img {
  width: 42px;
  height: 42px;
  margin: 0 0 0 0;
}

.area__page .selectRegion__section .mcs__links-body img {
  width: 38px;
  height: 38px;
  margin: 0 2px 0 2px;
}

@media (max-width: 768px) {
  .area4__page .MapChartSettings__container {
    bottom: -62px;
  }
}
