.UPRInfoBtn__container {
  display: inline-block;
}

.UPRInfoBtn__container.noRegions {
  right: 64px;
}

.area2__page .UPRInfoBtn__container {
  bottom: 4px;
}
.area3__page .UPRInfoBtn__container,
.area5__page .UPRInfoBtn__container,
.area1__page.has__category-selector .UPRInfoBtn__container {
  bottom: 24px;
}
.area4__page .UPRInfoBtn__container {
  bottom: 0px;
}

.UPRInfoBtn__trigger {
  cursor: pointer;
  width: 52px;
  height: 48px;
  background: #ecefec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1px;
  transition: background-color 225ms;
}
.UPRInfoBtn__trigger:hover {
  background-color: #fff;
}
.UPRInfoBtn__trigger g,
.UPRInfoActions__container g {
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0.16);
          transform: scale(0.16);
  fill: #bdc9bc;
  transition: fill 225ms;
}
.UPRInfoBtn__trigger:hover g,
.UPRInfoActions__container:hover g {
  fill: #98a197;
}

.UPRInfoModal .modal-content {
  border: medium none currentColor;
  border: initial;
  border-radius: 0;
}

.UPRInfoModal .btn-close {
  position: absolute;
  top: 28px;
  right: 28px;
}

.UPRInfoModal .modal-header {
  min-height: 120px;
  justify-content: flex-start;
  align-items: flex-end;
  background: #ecefec;
  width: 100%;
  padding: 20px 60px;
  border-bottom: 0;
  border-bottom: initial;
}

.UPRInfoModal .modal-header .comboLogoContainer {
  margin-left: 0px;
}

.UPRInfoModal .modal-body {
  padding: 40px 60px;
}

.UPRInfoActions__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #bedfdf;
  border-top: 1px solid #bedfdf;
  padding: 10px 0 10px 0;
}

.UPRInfoActions__container h4 {
  margin-bottom: 0;
  font-size: 22px;
  line-height: 28px;
  color: #6ea2b5 !important;
  font-weight: 400;
}

.UPRInfoActions__container .btn-link {
  color: #6ea2b5;
}

.UPRInfoActions__container svg {
  margin-right: 14px;
}

.UPRInfoActions__container .btn-link svg path {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}

.UPRInfoModal__content {
  padding: 30px 0 0;
}

.UPRInfoModal__content li::marker {
  font-size: 14px;
}

.UPRInfoModal__content h2 {
  font-size: 18px;
  padding-top: 15px;
  color: #528598;
  font-style: italic;
}
.UPRInfoModal__content h3 {
  font-size: 16px;
  padding-top: 15px;
  color: #528598;
  font-style: italic;
  margin-left: 20px;
}

.UPRInfoModal__content h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 12px;
  color: #528598;
}

.UPRInfoModal__content h5 {
  font: normal normal bold 25px/28px Lato;
  font-size: 18px;
  line-height: 20px;
  color: #528598;
  margin-bottom: 18px;
}

.UPRInfoModal__content p {
  font-size: 16px;
  line-height: 18px;
  color: #575757;
  margin-bottom: 18px;
}

.UPRInfoModal__content ul {
  list-style: circle outside;
  width: 100px;
}
.UPRInfoModal__content ol {
  padding-left: 10px;
}

.UPRInfoModal__content li {
  font-size: 16px;
  line-height: 18px;
  color: #575757;
  margin-bottom: 18px;
  margin-top: 10px;
}

.UPRInfoModal__content .subtitle {
  font-size: 18px;
  padding-top: 15px;
  color: #528598;
  font-style: italic;
}

.UPRInfoModal__content p.smallPrint {
  font-size: 11px;
  line-height: 12px;
}

.UPRInfoModal__content h6 {
  font-size: 11px;
  line-height: 12px;
}

@media (max-width: 768px) {
  .UPRInfoModal .modal-header .comboLogoContainer {
    -webkit-transform: scale(1);
            transform: scale(1);
    margin-left: 0;
    flex-direction: row;
    display: flex;
    align-items: baseline;
  }

  .UPRInfoModal .modal-body {
    padding: 40px 32px;
  }
  .UPRInfoActions__container h4 {
    padding: 10px;
  }
  .UPRInfoActions__container svg {
    width: 40px !important;
    height: 40px !important;
    margin: 0 0 0 0;
  }

  .UPRInfoModal__content {
    max-height: calc(100vh - 296px);
    overflow: auto;
  }
}

@media (min-width: 1240px) {
  .UPRInfoModal .modal-lg {
    max-width: 1040px;
  }
}
