body {
  background-color: #d8e3e5;
  font-family: "Lato";
}
.footer {
  position: relative;
  margin-top: -150px; /* negative value of footer height */
  height: 150px;
  clear: both;
  padding-top: 20px;
}
.n1cGraphicsGroup {
  display: flex;
  justify-content: space-between;
}

.n1cGraphicsChart50 {
  flex-basis: 48%;
}
