.mapYearFilterSwitch {
    position: absolute;
    right: 178px;
    bottom: 20px;
}
.mapYearFilterSwitch p.label {
    margin-top: 7px;
    margin-bottom: 0;
    width: 80px;
    height: 20px;
    text-align: center;
}
.yearDropdown__container {
    position: relative;
}

.yearDropdown__container svg {
    position: absolute;
    right: 0px;
}