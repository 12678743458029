div.virtualTableContainer {
  padding: 1rem;
}

div.virtualTableContainer .table {
  display: inline-block;
  border-spacing: 0;
  border: 1px solid black;
}
div.virtualTableContainer .table .th,
div.virtualTableContainer .table .td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
