header {
  display: flex;
  justify-content: space-between;
  background-color: #e9eff0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 112px;
  padding-top: 30px;
}

header .content__container {
  display: flex;
  justify-content: space-between;
}
header a.logoContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 22px;
}
header .anthemAwardsLogo {
  padding-top: 15px;
  padding-bottom: 15px;
  
}

header .anthemAwardsLogoInner {
  margin-left: 20px;
  margin-bottom: -10px;
  max-height: 90px;
}

header.section__homePage {
  background-color: #f0f0f0;
}
header.section__homePage .logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -26px;
  padding-bottom: 0;
  padding-bottom: initial;
}
header.section__homePage .menuLeft {
  align-items: flex-end;
  padding-bottom: 26px;
}
header.section__homePage .menuRight {
  align-self: flex-end;
  padding-bottom: 20px;
}
.section__areas.section__homePage .headerAreaDropdown,
.section__areas.section__homePage .toolsDropdown {
  top: 34px;
}

.section__areas.section__homePage .headerSubAreaDropdown {
  top: 29px;
}

.section__areas.section__homePage .headerSubAreaDropdown.area3SubDropdown {
  top: 122px;
}

.section__areas.section__homePage
  .had__container
  .headerSubAreaDropdown
  > a.disabled,
.section__homePage .textLinks .toolsDropdown > a.disabled {
  color: #cccccc;
}
header.section__homePage .iconLinks li.btn-link,
header.section__homePage .iconLinks li path:last-child {
  color: #868686;
  fill: #868686;
}
header.section__homePage .iconLinks .btn-link.userIconCircle circle,
header.section__homePage .iconLinks .btn-link.userIconCircle path {
  fill: #868686;
}
header.section__homePage a.logoContainer img {
  height: 142px;
  width: auto;
}
header .menuLogo {
  max-width: 260px;
}
header .iconLinks a {
  color: #5c8f95;
}
header .iconLinks li .darkSlash {
  color: #707070;
  margin: 0 4px;
}
.comboLogoContainer .ilgaDatabase {
  position: relative;
  top: -5px;
  left: 4px;
}
.comboLogoContainer .ilgaDatabase path {
  fill: #679398;
}
header.jur__page .comboLogoContainer .ilgaDatabase path {
  fill: #649364;
}
header .textLinks a {
  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 30px;
}
header .textLinks a.active {
  border-bottom: 2px solid #fff;
}
.menuContainer {
  display: flex;
  background: transparent;
  align-items: center;
  position: relative;
}
.menuHomepage__actions {
  position: absolute;
  top: 40px;
  right: 0;
}
.menuHomepage__actions button {
  width: 164px;
  background-color: #fff;
  height: 30px;
  font-size: 12px;
  color: #4e4e4e;
  border: 1px solid #b7b7b7;
  box-shadow: none;
  box-shadow: initial;
  margin-right: 6px;
  transition: background-color 225ms;
}
.menuHomepage__actions button:hover {
  background-color: #e7e7e7;
}
.menuHomepage__actions button:first-child {
  margin-right: 20px;
}
.menuLeft {
  height: 100%;
  align-items: center;
  display: flex;
}
.userInitials {
  border: 2px solid #79a3a8 !important;
  border-radius: 100% !important;
  padding: 5px;
  width: 32px;
  height: 32px;
  font-size: 16px;
  text-decoration: none;
  font-weight: 700;
}
.menuContainer ul {
  display: flex;
  list-style: none;
  margin-bottom: 0;
}

.menuContainer ul li {
  margin-left: 20px;
  font-weight: 600;
}
.menuContainer .menuLeft li:last-child {
  margin-right: 175px;
}
.menuContainer a {
  text-decoration: none;
}

.iconLinks li {
  color: #79a3a8;
}
.section__homePage .iconLinks li {
  color: #868686;
}
.iconLinks li .btn-link {
  color: #79a3a8;
  padding: 0;
}

.iconLinks li .btn-link:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

/* Menu Modal */
.menu__modal .btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 12px;
  color: #000;
}
.menu__modal .modal-content {
  background-color: #f2f2f2;
}

.menu__modal .modal-header {
  justify-content: flex-end;
  align-items: center;
  min-height: 120px;
  flex-direction: column;
  border-bottom: 0;
  border-bottom: initial;
}
.menu__modal .modal-header img {
  max-width: 316px;
  height: 58px;
  margin-top: 95px;
}

.menu__modal .modal-body {
  display: flex;
  padding: 80px 100px;
  max-height: 76vh;
  overflow-y: auto;
  justify-content: center;
}

.menu__modal .subNav__links {
  margin-right: 60px;
}

.menu__modal .subNav__links:last-child {
  margin-left: 40px;
  margin-right: 0;
}
.subNav__links:first-child ul li {
  margin: 21px 0;
}
.subNav__links:first-child .accordion-body .list-inside li {
  margin: 4px 0;
}
.subNav__links li.disabled,
.subNav__links li.disabled a {
  pointer-events: none;
  color: #868686;
}
.subNav__links:last-child ul li {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  text-transform: uppercase;
}
.subNav__links:last-child ul li:nth-child(n + 2) {
  padding: 22px 0 0;
}
.subNav__links .list-unstyled li a,
.menu__accordion .accordion-header {
  font-size: 16px;
  line-height: 24px;
}
.menu__accordion {
  width: 500px;
}
.menu__accordion .accordion-button {
  outline-color: #d8e3e5 !important;
  box-shadow: none;
  box-shadow: initial;
  color: #000;
  background-color: #f2f2f2 !important;
  padding: 3px 0;
}
.menu__accordion .accordion-button:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23868686'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  margin-left: 20px;
  top: 4px;
}

.menu__accordion .accordion-button:focus {
  border-color: #fff !important;
}
.menu__accordion .accordion-body {
  overflow: auto;
  background-color: #f2f2f2;
}
/* Custom Scrollbar */
/* width */
.subNav__links::-webkit-scrollbar,
.menu__accordion .accordion-body::-webkit-scrollbar,
.filterModal__body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border: 1px solid black;
}

/* Track */
.subNav__links::-webkit-scrollbar-track,
.menu__accordion .accordion-body::-webkit-scrollbar-track,
.filterModal__body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.subNav__links::-webkit-scrollbar-thumb,
.menu__accordion .accordion-body::-webkit-scrollbar-thumb,
.filterModal__body::-webkit-scrollbar-thumb {
  background: #b9c1c3;
  border-radius: 5px;
}

/* Handle on hover */
.subNav__links::-webkit-scrollbar-thumb:hover,
.menu__accordion .accordion-body::-webkit-scrollbar-thumb:hover,
.filterModal__body::-webkit-scrollbar-thumb:hover {
  background: #93999a;
  border-radius: 5px;
}
.menu__modal .mainLink {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid #e3e3e3;
  margin-bottom: 12px;
  display: block;
  padding: 0 0 12px;
  line-height: 22px;
  text-transform: uppercase;
}

.menu__modal .mainLink.tools {
  margin-top: 32px;
}

.menu__modal .soloLink {
  padding: 0 0 12px;
}

.menu__modal .list-unstyled ul {
  list-style: disc;
}

.menu__modal .list-unstyled a {
  text-decoration: none;
  margin-top: 4px;
  font-size: 14px;
}
.menu__modal .list-unstyled .list-inside {
  margin-bottom: 12px;
}
.menu__modal .list-unstyled .list-inside a {
  font-size: 13px;
  line-height: 20px;
}
.text-orange {
  color: #eb836b;
}
.text-blue {
  color: #6ea2b5;
}
@media (min-width: 1025px) and (-webkit-max-device-pixel-ratio: 1) {
  body.modal-open header {
    width: 100%;
    padding-right: 17px;
  }
}
@media (min-width: 1025px) {
  body.modal-open .selectRegion__section {
    width: calc(100% + 20px);
    padding-right: 20px;
  }

  .subNav__links_areas {
    max-height: 550px;
    overflow-y: auto;
    min-width: 500px;
  }
}
@media (max-width: 768px) {
  header .anthemAwardsLogo, .anthemAwardsLogoInner{
    display: none;
    visibility: hidden;
  }
  .menu__modal .modal-body {
    flex-direction: column;
    padding: 0 16px 20px;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: flex-start;
  }
  .menu__modal .mainLink {
    margin-top: 20px;
  }
  .menu__accordion .accordion-body {
    padding: 20px 0;
  }

  .menu__modal .subNav__links:last-child {
    margin: 20px 0;
  }

  .menu__modal .list-unstyled .list-inside {
    margin-bottom: 0;
  }
  .menu__modal .subNav__links {
    margin-right: 0;
  }
  .menu__modal .menu__accordion {
    width: 100%;
  }
}

@media (min-width: 768px) {
  header.section__homePage {
    padding: 0 0 0 0;
    height: 200px;
  }
}
