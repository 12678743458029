.mapContainer {
  width: 100%;
  padding: 10px 0px;
  min-height: 630px;
}

.mapContainer .loadingMap {
  position: absolute;
  bottom: 20px;
}

#chartdiv {
  width: 100%;
  height: 560px;
  min-height: 560px;
  margin-bottom: 54px;
}

.exportButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background-color: #e7edef;
  border-radius: 5px;
  padding: 5px 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
