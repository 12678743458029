
/* Added CSS on newest sessions */
.area6__contentSection {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}

.area6__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 28px auto;
}
.area6__content .areaContent__left,
.area6__content .areaContent__right,
.resolutions__content {
    background-color: #F4F8F8;
}
.area6__content p {
    font-size: 18px;
    line-height: 26px;
}
.methodBtn:hover .methodBtn__text,
.methodBtn:hover svg {
    color: #212529;
}

.resolutions__content {
    padding: 38px 38px 18px 38px;
    width: 100%;
    margin: var(--verticalMargin);
}
.resolutions__tabLinks-container {
    display: flex;
    justify-content: space-between;
}
.resolutions__tabLink {
    width: calc(33.33% - 6px);
    border: medium none currentColor;
    border: initial;
    background-color: #D8E3E5;
    padding: 62px 62px 72px;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    color: #707070;
    text-align: left;
    transition: background-color 225ms, color 225ms;
}
.resolutions__tabLink:hover {
    background-color: #e1ecee;
    color: #a2b8bd;
}
.resolutions__tabLink.active {
    background-color: #9BB6B9;
    color: #fff;
    transition: none 0s ease 0s;
    transition: initial;
}
.resolutions__tabLink .rtl__mainHeading {
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
}
.resolutions__content hr {
    margin-bottom: 8px;
    height: 2px;
    background-color: #cccccc;
}
.resolutions__tabIndicator {
    color: #575757;
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
}
.a6activeTab__area {
    margin: var(--verticalMargin);
}
.a6activeTab__area .accordion-item,
.a6activeTab__area .accordion-header button {
    border-radius: 0 0 0 0;
    border: none;
    box-shadow: none;
    box-shadow: initial;
}
.a6activeTab__area .accordion-button {
    border-radius: 0 0 0 0 !important;
    border: none !important;
    background-color: #9BB6B9;
    color: #fff;
    font-weight: 700;
}
.a6activeTab__area .accordion-item {
    margin-bottom: 10px;
}
.a6activeTab__area .accordion-button::after {
    border-left-color: #ffffff;
    border-top-color: #ffffff;
}
.a6activeTab__area .accordion-body {
    background-color: #F4F8F8;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 18px 0;
}
.a6activeTab__area .accordion-body .infoBox {
    width: calc(50% - 8px);
    background-color: #D8E3E5;
    padding: 38px 38px 38px 38px;
    margin: 8px 0;
    display: flex;
    flex-direction: column;
}
.area__page .infoBox.infoBox-white {
    background-color: #f4f8f8;
    padding-bottom: 60px;
}
.area__page .infoBox .infoBox__info-bar {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.infoBox__info-bar .ib__date,
.infoBox__info-bar .ib__tag {
    font-size: 14px;
}
.infoBox__info-bar .ib__tag {
    text-transform: uppercase;
    background-color: #528598;
    color: #fff;
    padding: 6px 10px;
    margin-right: 8px;
    font-weight: 900;
}
.infoBox__info-bar .ib__date {
    color: #4E4E4E;
}
.area__page .infoBox .infoBox__title {
    margin: 20px 0 14px 0;
    font-size: 22px;
    line-height: 26px;
    font-weight: 900;
    color: #4E4E4E;
}
.infoBox .ib__adopted-date {
    font-size: 14px;
    font-weight: 400;
    color: #528598;
}
.infoBox p {
    margin-top: 14px;
    margin-bottom: 0;
}
.infoBox__footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}
.infoBox__footer button {
    width: calc(50% - 6px);
    margin-top: 40px;
    height: 40px;
    border-color: transparent;
    color: #fff;
    background-color: #9BB6B9;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    transition: color 225ms, background-color 225ms;
}
.infoBox__footer button:hover {
    color: #e6f6f8;
    background-color: #a9c9cc;
}
.vr__barChart-container {
    background-color: #E9EFF0;
    padding: 20px 24px;
    margin: var(--verticalMargin);
}
.vr__barChart-container h6 {
    font-size: 14px;
    margin-bottom: 14px;
}
.vr__barChart-container .vrbc-bar {
    width: 100%;
    display: flex;
    justify-content: center;
}
.vr__barChart-container.no-bar h6 {
    margin-bottom: 0;
}
.vrbc-bar span {
    min-width: 40px;
    color: #fff;
    text-align: center;
    height: 24px;
    line-height: 24px;
    font-weight: 900;
}
.vrbc-bar span:first-child {
    background-color: #91B6C4;
}
.vrbc-bar span:nth-child(2) {
    background-color: #D8A585;
}
.vrbc-bar span:nth-child(3) {
    background-color: #EFD79C;
    color: #707070;
}
.vrbc-bar span:nth-child(4) {
    background-color: #CBCBCB;
    color: #707070;
}

.a6activeTab__area .a6activeTab__area-na {
    padding-top: 0;
}
.areaContent__left.infoBox {
    width: calc(63.333% - 14px);
}
.areaContent__sponsors-container {
    width: calc(36.666% - 14px);
    display: block;

}
.areaContent__sponsors {
    position: -webkit-sticky;
    position: sticky;
    padding: 46px 38px 30px 38px;
    background-color: #F4F8F8;
    top: 28px;
}
.areaContent__sponsors h5 {
    font-size: 20px;
    color: #575757;
    margin-bottom: 20px;
    font-weight: 700;
}
.areaContent__sponsors ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    gap: 10px;
}
.areaContent__sponsors li {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px !important;
}
.areaContent__sponsors li img {
    margin-right: 6px;
}

.areaContent__sponsors hr {
    margin-bottom: 24px;
    height: 2px;
    background-color: #CCCCCC;
}

.infoBox__action-bar {
    margin: var(--verticalMargin);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.infoBox__action-bar .infoBox__action-button,
.infoBox__chart-containerActions button {
    transition: background-color 225ms, color 225ms, border 225ms;
}

.infoBox__action-bar .infoBox__action-button:hover,
.infoBox__chart-containerActions button:hover  {
    background-color: #eef6f8;
    color: #333;
    border: 2px solid #cde8ec;
}
.infoBox__action-bar .infoBox__action-button,
.infoBox__chart-containerActions button {
    flex-grow: 1;
    margin-right: 10px;
    color: #575757;
    font-size:12px;
    line-height: 1;
    padding: 14px 14px;
    border: 2px solid #C2D5D8;
    background-color: transparent;
}
.infoBox__action-bar .infoBox__action-button:last-child {
    margin-right: 0;
}
.infoBox__vote-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    gap: 10px;
    padding: 24px;
    background-color: #E9EFF0;
    margin: var(--verticalMargin);
}
.infoBox__vote-list .ibvl__heading {
    padding: 0 0 4px 0;
    border-bottom: 4px solid #91B6C4;
    font-size: 14px;
}
.infoBox__vote-list ul {
    padding: 20px 0 0 0;
}
.infoBox__vote-list li {
    font-size: 10px;
    margin-bottom: 8px !important;
}
.infoBox__vote-list img {
    width: 18px;
    height: auto;
    margin-right: 6px;
}
.infoBox__vote-list .ibvl__heading.border-red {
    border-color: #D8A585;
}
.infoBox__vote-list .ibvl__heading.border-yellow {
    border-color: #EFD79C;
}
.infoBox__vote-list .ibvl__heading.border-gray {
    border-color: #CBCBCB;
}
.areaContent-transparent {
    background-color: transparent !important;
}
.areaContent-transparent.areaContent__left {
    padding: 0 0 0 0 !important;
    width: calc(63.333% - 14px);
}
.areaContent-transparent .infoBox {
    padding: 38px 38px 18px 38px;
}
.areaContent-transparent .infoBox:nth-child(n+2) {
    margin: var(--verticalMargin);
}

.infoBox__chart-container {
    display: grid;
    grid-template-columns: repeat(1, 2fr 1fr);
    grid-gap: 10px;
    gap: 10px;
    margin: var(--verticalMargin);
}
.infoBox__chart-container .vr__barChart-container {
    margin-top: 0;
}
.infoBox__chart-containerActions {
    background-color: transparent;
    display: flex;
    flex-direction: column;
}
.infoBox__chart-switchContainer {
    background-color: #E9EFF0;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.infoBox__chart-switchContainer > span {
    margin-right: 10px;
}
.infoBox__chart-switchContainer .dv-switch label {
    margin-bottom: 0;
}
.infoBox__chart-containerActions button {
    padding: 0 0 0 0;
    margin-right: 0;
    max-height: 40px;
}
.areaContent__left.areaContent-transparent,
.areaContent__sponsors {
    margin-bottom: 60px;
}
.ibab__ungrow {
    flex-grow: 0 !important;
}
/*table stuff */
.tableArea6-1 .titleCellHeader {
    font-size: 16px;
}
.tableArea6-1 .tableJurCell td {
    background-color: #E9EFF0;
}
.tableArea6-1 .tableJurCell strong {
    max-width: 80px;
    display: block;
    margin: 0 auto;
}
.tableArea6-1 .tableTopic {
    padding: 4px 8px;
    font-size: 14px;
    line-height: 16px;
    color: #868686;
    background-color: #ffffff;
}
