.row {
  --bs-gutter-x: 0.5rem;
  --bs-gutter-y: 0;
}
/*.fullSize .modal-dialog {*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    margin: 0 0 0 0;*/
/*    max-width: unset;*/
/*    max-height: unset;*/
/*    display: flex;*/
/*    border: unset;*/
/*    border-radius: unset;*/
/*}*/

.login__modal.menu__modal .modal-header img {
  max-width: 316px;
  margin-top: 95px;
  margin-bottom: 102px;
  height: 54px;
}

.fullSize.menu__modal.login__modal .modal-content {
  background: #f4efe9;
  max-width: 900px;
}

.loginForm__container {
  width: 100%;
  margin: 0 auto;
  max-width: 375px;
}
.fullSize.menu__modal.signup__modal .modal-body {
  display: flex;
  padding: 0 80px 0px;
  max-height: 64vh;
  overflow-y: auto;
  justify-content: center;
}
.fullSize.menu__modal.login__modal .modal-dialog {
  max-width: 900px;
}

.fullSize.menu__modal.signup__modal .loginForm__container {
  width: 100%;
  max-width: 700px;
}

.midSize.menu__modal.signup__modal .loginForm__container {
  width: 100%;
  max-width: 700px;
}

.loginForm__container .error {
  margin-top: 4px;
  color: red;
}

.lf__title {
  color: #000;
  font-size: 34px;
  line-height: 32px;
  font-weight: 900;
}

.lf__subTitle {
  font-size: 16px;
  line-height: 22px;
  color: #000;
}

.lf__header {
  padding-bottom: 18px;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 38px;
}

.lf__subTitle span {
  display: inline-block;
  padding: 0;
  padding: initial;
  height: auto;
  height: initial;
  margin-left: 6px;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  color: #e37b53;
  cursor: pointer;
}

.lfInput__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  position: relative;
}

.lfInput__container label {
  font-size: 16px;
  line-height: 18px;
  color: #000;
  margin-bottom: 6px;
}

.lfInput__container input {
  background: #ffffff !important;
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
  color: #000;
  font-size: 16px;
  line-height: 18px;
  height: 45px;
  padding: 14px 22px;
}

.lfInput__container .btn-link:hover {
  color: #e37b53;
}

.showPasswordBtn {
  position: absolute;
  right: 0;
  top: 30px;
  font-size: 14px;
  line-height: 18px;
  color: #e37b53;
  text-decoration: none;
}

.lf__actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.lf__actions .btn,
.lf__actions .btn-primary {
  width: 100%;
  text-align: center;
}

.lf__actions .btn-primary {
  background: #e37b53;
  color: #ffffff;
  height: 45px;
  margin-bottom: 24px;
  border: medium none currentColor;
  border: initial;
  border-radius: 0;
  margin-top: 8px;
  outline: none !important;
  box-shadow: none !important;
  font-weight: 700;
}

.lf__actions .btn-link {
  margin-top: 0;
  font-size: 16px;
  line-height: 22px;
  color: #e37b53;
  text-decoration: none;
  cursor: pointer;
}

.lf__actions .actionMsg {
  font-size: 16px;
  line-height: 22px;
  color: #000;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.loginForm__container .dd-wrapper {
  box-shadow: none;
  box-shadow: initial;
  -webkit-transform: none;
          transform: none;
  -webkit-transform: initial;
          transform: initial;
  padding: 0 0 0 0;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
}

.loginForm__container .dd-wrapper .check-container {
  background: #e9eff0;
}

.loginForm__container .dd-wrapper .check-container label {
  background: #f4efe9;
  font-size: 13px;
  line-height: 22px;
  color: #707070;
}

.loginForm__container .dd-wrapper .check-container label span {
  margin-left: 3px;
}
.loginForm__container input:focus-visible {
  outline: none !important;
  border: medium none currentColor !important;
  border: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
}
.login__modal
  .loginForm__container
  .dd-wrapper
  .check-container
  input:checked
  + label {
  background: #f4efe9;
}

.login__modal
  .loginForm__container
  .dd-wrapper
  .check-container
  input:checked
  + label:before {
  background: #f6debf;
}
.login__modal .check-container input:checked ~ label:after {
  background-color: #f6debf;
  border-color: #e37b53;
}
.login__modal .check-container label:after {
  content: '';
  border-color: #e37b53 !important;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23E37B53' fill-rule='nonzero'/%3E%3C/svg%3E") !important;
}

.loginForm__container .check-container label:before {
  background: #e9eff0;
}

.loginForm__container .col-md-6:nth-child(2n-1) {
  padding-right: 5px;
}

.loginForm__container .col-md-6:nth-child(2n) {
  padding-left: 5px;
}

.loginForm__container input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bfbfbf;
  opacity: 1; /* Firefox */
}

.loginForm__container input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bfbfbf;
  opacity: 1; /* Firefox */
}

.loginForm__container input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bfbfbf;
}

.loginForm__container input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bfbfbf;
}

.signup__modal .check-container label span {
  color: #e37b53;
}
.login__modal .comboLogoContainer .ilgaDatabase path {
  fill: #e37b53;
}
@media (max-width: 768px) {
  .fullSize.menu__modal.signup__modal .modal-dialog {
    height: 90vh;
  }
  .fullSize.menu__modal.signup__modal .modal-body {
    display: flex;
    padding: 20px 40px 32px;
    justify-content: flex-start;
  }
}
@media (min-width: 1140px) {
  .menu__modal .modal-header {
    padding-top: 25px;
  }
  .menu__modal .modal-body {
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: none;
    max-height: initial;
  }
  .fullSize.menu__modal.signup__modal .modal-body {
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: none;
    max-height: initial;
  }
}

@media (min-width: 540px) and (max-width: 1320px) {
  .fullSize.menu__modal.login__modal .modal-dialog {
    margin: 20px;
  }
}

.loginForm__container.success .lf__actions {
  margin-top: 50px;
}
.loginForm__container.success .lf__actions .btn-primary {
  margin-bottom: 0px;
}

