.openEnforcmentBtn {
  text-align: center;
  height: 40px;

  color: #6ea2b5;
  font-size: 16px;
  border-radius: 0;
  align-self: flex-end;
  justify-self: flex-end !important;
  margin-top: auto;
}

.enforcmentModal .modal-header {
  min-height: 80px;
  justify-content: flex-start;
  align-items: flex-end;
  background: #e9eff0;
  width: 100%;
  padding: 20px 68px;
  border-bottom: 0;
  border-bottom: initial;
}

.enforcmentModal .modal-header .btn-close {
  padding: 0.5rem 0.1rem;
  margin: 0.5rem 0.1rem 0.1rem auto;
}

.enforcementHeader span {
  font-size: 1.2rem;
  vertical-align: text-top;
  color: #6ea2b5;
}

.enforcmentModal__content {
  transition: background-color 0.5s ease;
  padding: 20px 10px;
  line-height: 20px;
  font-size: 14px;
}
.enforcmentModal__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9eff0;

  padding: 8px 10px;
}

.enforcmentModal .modal-body {
  padding: 40px 60px;
}
.enforcmentModal__container h4 {
  font-size: 1.2rem;
  font-weight: bold;
  position: relative;
  width: 100%;
}
.enforcmentModal__container h4 div {
  display: inline;
}

.enforcmentModal__container h4 span {
  float: right;
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-weight: normal;
  font-size: 13px;
}

.enforcmentModal__content h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #528598;
  font-style: italic;
}

.enforcmentModal__content h2:not(:first-of-type) {
  margin-top: 20px;
}
.enforcmentFlag {
  vertical-align: text-top;
  margin-right: 8px;
}

@media (min-width: 1240px) {
  .enforcmentModal .modal-lg {
    max-width: 1040px;
  }
}
