.section__tools .areaLink__description {
    text-align: left;
    margin-top: 33px;
}
.section__tools .iconContainer {
    margin-bottom: 40px;
    margin-top: auto;
}
.areaHome__page.section__tools .content__container .areaLinkBox {
    min-height: 450px;
    margin-right: 14px;
}
.areaHome__page.section__tools .content__container .areaLinkBox p,
.areaHome__page.section__tools .content__container .areaLinkBox h6 {
    transition: color 324ms;
}
.areaHome__page.section__tools .content__container .areaLinkBox svg path,
.areaHome__page.section__tools .content__container .areaLinkBox svg circle,
.areaHome__page.section__tools .content__container .areaLinkBox svg rect {
   transition: fill 324ms;
}

@media (max-width: 768px) {
    .section__tools.areaHome__page .content__container {
        flex-direction: column;
    }
    .areaHome__page.section__tools .content__container .areaLinkBox {
        width: 100%;
        min-height: 172px;
    }
    .areaHome__page.section__tools .areaLinkBox .iconContainer {
       margin-top: 40px;
    }
    .areaHome__page.section__tools .areaLinkBox:nth-child(n+3) .iconContainer {
        margin-top: 20px;
    }
}

@media (min-width: 568px) and (max-width: 1200px) {
    .section__tools.areaHome__page .content__container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }
    div.areaHome__page.section__tools .content__container .areaLinkBox {
        width: calc(50% - 10px);
    }
    div.areaHome__page.section__tools .content__container .areaLinkBox:nth-child(n+2) {
        margin-right: 0;
    }
}

.tools__page{
    min-height: 0;
    min-height: initial;
}