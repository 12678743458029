.acp__statsGeneratorHeader {
    display: flex;
    background: #EEECF5;
    padding: 20px 26px;
    height: 130px;
    margin: 12px 0;
}
.StatsGenerator .section__page {
    padding-bottom: 32px;
}
.acp__statsGeneratorHeader .acp__formControl {
    flex-grow: 1;
    margin-right: 10px;
}
.acp__statsGeneratorHeader .acp__formControl .reactSelect {
    height: 100%;
    position: relative;
}
.acp__statsGeneratorHeader .acp__button {
    width: 204px;
}
.acp__button {
    border: medium none currentColor;
    border: initial;
    box-shadow: none;
    box-shadow: initial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: #707070;
    padding: 24px 48px;
    background: #ffffff;
    align-self: flex-end;
    margin-left: auto;
    height: 100%;
}

.acp__statsGeneratorPicker {
    display: flex;
    flex-direction: column;
    background: #EEECF5;
    padding: 40px 60px;
}
.acp__sectionTitle {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: #000;
    margin: 0 0 0 0;
}
.acp__sectionSubtitle {
    font-size: 15px;
    line-height: 26px;
    color: #575757;
    margin: 0 0 32px;
}
.acpfc__chartBoxContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.acpfc__chartBoxContainer .acpfc__chartBox {
    background: #ffffff;
    padding: 20px 32px;
    width: calc(25% - 26px);
    margin-bottom: 34px;
    margin-right: 34px;
    height: 240px;
    cursor: pointer;
    transition: -webkit-filter 224ms;
    transition: filter 224ms;
    transition: filter 224ms, -webkit-filter 224ms;
}
.acpfc__chartBoxContainer .acpfc__chartBox.active {
    background: #D8D5E3;
}
.acpfc__chartBoxContainer .acpfc__chartBox:hover {
    -webkit-filter: grayscale(85%);
            filter: grayscale(85%);
}
.acpfc__chartBoxContainer .acpfc__chartBox:nth-child(4n) {
    margin-right: 0;
}
.acpfc__chartBoxContainer .acpfc__chartBox:nth-child(n+5) {
    margin-bottom: 10px;
}
.acpfc__chartBoxContainer .acpfc__chartBox.selected {
    background: #d8d5e3;
}
.acpfc__chartBoxContainer .acpfc__chartBox.selected:hover {
    -webkit-filter: initial;
            filter: initial;
}
.acpfc__chartName {
    font-size: 14px;
    line-height: 18px;
    font-weight: 900;
    margin-bottom: 4px;
    text-transform: uppercase;
}
.StatsGenerator p.acpfc__chartSubtitle {
    font-size: 13px;
    line-height: 16px;
    color: #6EA2B5;
    margin-bottom: 4px;
}
.acpfc__chartBoxContainer p {
    font-size: 12px;
    line-height: 13px;
    color: #575757;
    margin-bottom: 0;
}
.acpfc__chartBox img {
    width: 50%;
    height: auto;
    margin: 0 auto 20px;
    display: block;
}

.acpfc__chartBox img.wide {
    padding: 20px 10px;
    width: calc(100% - 20px);
    height: auto;
}
.acpfc__chartBox:nth-child(5) img.wide {
    width: calc(100% - 46px);
}
.acp__actionsContainer {
    display: flex;
    margin-right: 10px;
    align-items: center;
    padding: 0 32px;
    background: #ffffff;
    width: 568px;
}
.StatsGenerator .purpleDropdown__container {
    width: 220px;
    height: 30px;
    margin-left: 22px;
    flex-grow: 1;
}

.buttonChartToggle {
    position: relative;
    top: 0;
    width: 220px;
    height: 40px;
    margin: 0 22px 0 auto;
    overflow: hidden;
}
.StatsGenerator .buttonChartToggle .checkbox:checked + .knobs:before {
    left: 106px;
}
.buttonChartToggle {
    height: 34px;
    border-color: transparent;
    margin: 0 0 0 0;
}

.buttonChartToggle .checkbox:checked ~ .layer {
    background: #ffffff;
}
.StatsGenerator .buttonPurpleToggle .knobs {
    background: #ffffff;
}
.StatsGenerator .buttonPurpleToggle .knobs:before {
    background-color: #a8a0c1;
}
.StatsGenerator .buttonPurpleToggle .knobs:before,
.StatsGenerator .buttonPurpleToggle .knobs span {
    left: 0;
    top: 0;
    height: 30px;
    width: 108px;
    border: 2px solid #a8a0c1;
    border-radius: 0;
    padding: 0px 6px;
    line-height: 28px;
}
.StatsGenerator .buttonPurpleToggle .knobs:after {
    right: 0;
    top: 0;
    height: 30px;
    border: 2px solid #a8a0c1;
    border-radius: 0;
    width: 108px;
    padding: 0px 6px;
    line-height: 28px;
    background: transparent;
}
.StatsGenerator .buttonChartToggle .checkbox:checked + .knobs:after {
    background: #a8a0c1;
    color: #ffffff;
}
.StatsGenerator .purpleDropdown__trigger {
    background: #EEECF5;
    color: #868686;
    height: 30px;
    padding-right: 18px;
}
.StatsGenerator .purpleDropdown__items {
    background-color: #ffffff;
}
.purpleDropdown__trigger:after {
    top: 7px;
    border-left-color: #868686;
    border-top-color: #868686;
}
.acp__statsGenerator-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
}
.acp__purple-button {
    border: medium none currentColor;
    border: initial;
    box-shadow: none;
    box-shadow: initial;
    background-color: #8A79BA;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    transition: background-color 324ms;
    width: 160px;
    height: 50px;
    margin-right: 16px;
}
.acp__purple-button:last-child {
    margin-right: 0;
}
.acp__purple-button.disabled {
    pointer-events: none;
    cursor: not-allowed;
    background: #AFA6C9;
    color: #ededed;
}
.acp__purple-button:hover,
.acp__purple-button:focus,
.acp__purple-button:active {
    background-color: #8a79ba;
}
.StatsGenerator .areaHasParent span {
    display: block;
}
.StatsGenerator .areaHasParent span:first-child,
.StatsGenerator .areaIsParent {
    font-size: 16px;
    line-height: 16px;
    color: #707070;
    font-weight: 700;
}
.StatsGenerator .areaHasParent span:nth-child(2) {
    font-size: 14px;
    line-height: 16px;
    margin-top: 6px;
    font-weight: 400;
    color: #707070;
}
.StatsGenerator .advancedSearch__page .reactSelect svg {
    margin-right: 8px;
}
.rangeDropdowns__container {
    display: flex;
    align-items: center;
}
.rangeDropdowns__container label {
    margin-left: 16px;
}
.rangeDropdowns__container .purpleDropdown__container {
    width: 82px;
    margin-left: 10px;
}
.StatsGenerator .purpleDropdown__items {
    max-height: 200px;
    overflow: auto;
}
.StatsGenerator .purpleDropdown__item {
    padding: 0 0 0 0;
}
/* Stats Generator Home */
.StatsGeneratorHome .acp__button {
    background-color: #EEECF5;
    transition: background-color 225ms, color 225ms;
    text-align: center;
}
.StatsGeneratorHome .acp__button:hover {
    background-color: #AFA6C9;
    color: #ededed;
}

/* Stats Generator Selected */
.StatsGeneratorSelected .mgMap__container .comboLogoContainer {
    position: absolute;
    top: 10px;
    right: -46px;
    -webkit-transform: scale(.6);
            transform: scale(.6);
}
.StatsGeneratorSelected .mgMap__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.StatsGeneratorSelected .chartImage {
    width: 416px;
    height: 416px;
}
.StatsGeneratorSelected .mgmsf__exportBtn {
    height: 90px;
    width: 230px;
    flex-grow: initial;
    font-weight: 700;
}
.StatsGeneratorSelected .mgMapSection__container {
    width: calc(100% - 306px);
    max-height: 790px;
}
.StatsGeneratorSelected .mgMapSection__sidebar {
    max-width: 288px;
    flex-grow: 1;
    margin-left: 18px;
    padding: 40px 26px 26px 26px;
}
.StatsGeneratorSelected .mgMapSection__sidebar .reactSelect {
    height: 40px;
}
.StatsGenerator.StatsGeneratorSelected .advancedSearch__page .reactSelect svg {
    margin-right: 0;
}
.StatsGeneratorSelected .mgMapSection__sidebar .acp__whiteBox {
    padding: 15px 10px;
    margin: 20px 0 0;
}
.StatsGeneratorSelected .mgMapSection__sidebar .buttonChartToggle .knobs:after {
    content: "Population";
    color: #8A79BA;
}
.StatsGeneratorSelected .mgMapSection__sidebar .exportFormatPicker {
    width: 100%;
    max-width: none;
    max-width: initial;
}
.StatsGeneratorSelected .mgMapSection__sidebar .mgmsf__exportBtn {
    margin: 0 0 0 0;
    width: 100%;
    height: 70px;
}
.StatsGeneratorSelected .mgMapSection__sidebar .reactSelect {
    position: relative;
    z-index: 12;
}
.StatsGeneratorSelected .acp__statsGeneratorHeader {
    padding: 20px 20px;
}
.acp__wbContainer {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
}
.acp__whiteBox {
    display: flex;
    flex-direction: column;
    color: #A8A0C1;
    padding: 22px 24px;
    background: #ffffff;
    justify-content: center;
    margin-right: 12px;
    flex-grow: 1;

}
.acp__whiteBox p {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    color: #A8A0C1;
}
.acp__whiteBox .parentArea {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 4px;
    font-weight: 400;
}
.acp__whiteBox .childArea {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
}
.StatsGeneratorSelected .selectCategory__container {
    margin: 20px 0 20px 0 !important;
    width: 100%;
}
.StatsGeneratorSelected .selectCategory__container input + label {
    background: #eeecf5;
    font-size: 12px;
    line-height: 13px;
}
.StatsGeneratorSelected .selectCategory__container input:checked + label {
    background: #D8D5E3;
}
.StatsGeneratorSelected .customColumnHideSwitch {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0 20px 0;
}
.StatsGeneratorSelected .customColumnHideSwitch label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.StatsGeneratorSelected .customColumnHideSwitch label > div > div:first-child {
    border: 2px solid #A8A0C1;
}
.StatsGeneratorSelected .customColumnHideSwitch .react-switch-handle {
    background: #A8A0C1 !important;
}
.StatsGeneratorSelected .selectCategoryLong {
    flex-wrap: wrap;
    justify-content: flex-start;
}
.StatsGeneratorSelected .selectCategory {
    width: 59px;
    flex-grow: 0;
}
.StatsGeneratorSelected .selectCategory:nth-child(4n) {
    border-right: 2px solid #ffffff;
}
.StatsGeneratorSelected .selectCategory:nth-child(n+5) label {
    border-top: 0;
    border-top: initial;
}
.StatsGeneratorSelected .selectCategory:last-child label {
    width: 61px;
}

@media (max-width:1240px) {
    .acp__statsGeneratorHeader {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 200px;
        align-items: center;
    }
    .acp__statsGeneratorHeader .acp__formControl:first-child {
       order: 2;
        margin-top: 18px;
    }
    .acp__statsGeneratorHeader .acp__formControl .reactSelect {
       height: 62px;
        margin-top: 8px;
    }
    .acp__statsGeneratorHeader .acp__actionsContainer {
        width: 100%;
        padding: 20px 28px;
        margin-right: 0;
    }
    .acp__statsGeneratorHeader .acp__button {
        height: 62px;
        order: 2;
    }
     .acpfc__chartBoxContainer .acpfc__chartBox {
        width: calc(33% - 20px);
    }
     .acpfc__chartBoxContainer .acpfc__chartBox:nth-child(3n) {
        margin-right: 0;
    }
     .acpfc__chartBoxContainer .acpfc__chartBox:nth-child(4n) {
         margin-right: 34px;
    }
}

@media (max-width: 976px) {
    .acpfc__chartBoxContainer .acpfc__chartBox {
        width: calc(33% - 10px);
    }
    .acpfc__chartBoxContainer .acpfc__chartBox {
        margin-right: 14px;
    }
    .acpfc__chartBoxContainer .acpfc__chartBox:nth-child(4n) {
        margin-right: 14px;
    }
}