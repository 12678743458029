.topics__modal.asai__modal .modal-dialog {
  max-width: 1340px;
  top: -20px;
}

.topics__modal .acp__formControl .check-container label {
  color: #575757;
  font-size: 13px;
  line-height: 16px;
}
/* colors rework */
.advancedSearch__page .dd-filter input {
  background-color: #8a79ba;
  color: #ffffff !important;
}
.advancedSearch__page .dd-filter .filterModal__input::-webkit-input-placeholder {
  color: #ffffff !important;
}
.advancedSearch__page .dd-filter .filterModal__input::placeholder {
  color: #ffffff !important;
}

.advancedSearch__page .buttonAndOr {
  border-color: #8a79ba;
}
.advancedSearch__page .buttonAndOr .knobs:before {
  background-color: #8a79ba;
}
.advancedSearch__page.filter__modal .filterModal__header,
.advancedSearch__page.filter__modal .modal-body,
.advancedSearch__page.filter__modal .modal-footer {
  background-color: #eeecf5 !important;
}
.advancedSearch__page.filter__modal .filterModal__body .check-container label {
  background: #eeecf5;
}
.advancedSearch__page.filter__modal
  .filterModal__body
  .check-container
  label:before {
  background: #ac9cd9;
}
.advancedSearch__page.filter__modal
  .filterModal__body
  .check-container
  input:checked
  + label {
  color: #ffffff;
}
.advancedSearch__page.filter__modal
  .filterModal__body
  .check-container
  input:checked
  + label:after {
  background-color: #ffffff;
}
.filterModal__footer .btn.btn-primary {
  background-color: #8a79ba !important;
  border-color: #8a79ba !important;
}
.acpcb__noContent {
  padding: 46px 78px 36px;
  background-color: #eeecf5;
  font-size: 16px;
  line-height: 30px;
}
.acpcb__noContent p {
  margin-bottom: 0;
}
.advancedSearch__page .acpcb__noContent .btn {
  border-radius: 0;
  background-color: #8a79ba;
  border-color: #8a79ba;
  min-width: 156px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin-top: 30px;
  color: #ffffff;
}
.advancedSearch__page .acpcb__noContent .btn:hover {
  background-color: #afa6c9;
  border-color: #afa6c9;
}

/* Accordion results */
.advancedSearch__page .accordion {
  margin-top: 26px;
}
.advancedSearch__page .accordion-item {
  border-top: 0;
  border-top: initial;
  border-left: 0;
  border-left: initial;
  border-right: 0;
  border-right: initial;
}
.advancedSearch__page .accordDecoration {
  width: 26px;
  height: 26px;
  background-color: #d8d5e3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #707070;
  margin-right: 12px;
}
.advancedSearch__page .accordion-button {
  outline: none;
  border-color: transparent;
  box-shadow: none;
  box-shadow: initial;
  position: static;
  position: initial;
}
.advancedSearch__page .accordion-button:not(.collapsed) {
  color: #575757;
  background-color: #eeecf5;
  box-shadow: none;
  box-shadow: initial;
}
.advancedSearch__page .accordion-button:not(.collapsed)::after,
.advancedSearch__page .accordion-button::after {
  right: 22px;
  top: 16px;
}

.advancedSearch__page .accordion-button::after {
  position: static;
  position: initial;
}

.advancedSearch__page .topicFilterModal__body .check-container label {
  background-color: #eeecf5;
}

/* does this work on the other filter?*/
@media (max-width: 768px) {
  .filter__modal {
    padding-top: 0 !important;
  }
  .filterModal__body .check-container {
    width: 100%;
  }
  .filter__modal .filterModal__header {
    min-height: 0 !important;
    min-height: initial !important;
    padding-top: 40px;
  }
  .advancedSearch__page.filter__modal .filterModal__header,
  .advancedSearch__page.filter__modal .modal-body,
  .advancedSearch__page.filter__modal .modal-footer {
    flex-direction: column;
  }
  .filterModal__body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-height: 50vh;
    overflow-x: auto;
    overflow-y: auto;
  }
}
