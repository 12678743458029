.jur-home__page .jurHero__section {
  min-height: 800px;
  max-height: 800px;
  padding-top: 130px;
}
.jur-home__page .jurSearch__header {
  display: flex;
  justify-content: flex-start;
  background: #d1dbd0;
  padding: 18px 32px;
  margin-bottom: 26px;
}
.jur-home__page .selectJur__select {
  width: 484px;
  margin-right: 15px;
  height: 46px;
}
.jur-home__page .selectGroup__select {
  width: 484px;
}
.jurSearch__section {
  background-color: #ecefec;
  padding: 28px 0;
}
.jurSearch__countryList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.jurSearch__countryList button {
  padding: 15px 25px;
  background: #ffffff;
  width: calc(16.6666% - 8px);
  margin: 4px;
  border-radius: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #707070;
  min-height: 62px;
}
.jurSearch__countryList img {
  margin-right: 12px;
}
.jurSearch__countryList .btnText {
  display: block;
  flex-grow: 1;
  font-size: 15px;
  line-height: 15px;
  text-align: left;
}
.jur-home__page .reactSelect > div {
  border-color: #f7f6f2 !important;
  background-color: #f7f6f2 !important;
  color: #707070 !important;
}
.jur-home__page .reactSelect > div .css-4ljt47-MenuList {
  padding: 20px 8px;
  border-color: #ecefec !important;
  background-color: #ecefec !important;
}
.jur-home__page div[id^="react-select-3-option"] {
  padding: 8px 24px;
}
.jur-home__page .css-1n7v3ny-option {
  background-color: #dde0d4;
  color: #707070;
}
.jur-home__page div.css-9gakcf-option {
  background: #ecefec;
  color: #707070;
}
.jur-home__page div.css-9gakcf-option:hover,
.jur-home__page div.css-9gakcf-option:focus {
  background: #dde0d4 !important;
}
.jur-home__page .css-g1d714-ValueContainer {
  padding: 0 16px;
}
.jur-home__page .jurSearch__header svg.MagGlassIcon {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
