/* DROPDOWN ============================================ */
.dd-wrapper {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
}
.dd-wrapper .dd-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  padding: 10px 20px;
}
.dd-wrapper .dd-header__title--bold {
  font-weight: bold;
}
.dd-wrapper .dd-list {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  left: 0px;
  max-height: 400px;
  overflow-y: auto;
}
.dd-wrapper .dd-list li {
  list-style-type: none;
}
.dd-wrapper .dd-list li:first-of-type > button {
  border-top: 1px solid #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.dd-wrapper .dd-list li:last-of-type > button {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dd-wrapper .dd-list li button {
  display: flex;
  justify-content: space-between;
  background-color: #d8e3e5;
  font-size: small;
  padding: 0px 10px 0px 10px;
  border: 0;

  width: 100%;
  text-align: left;
}

.dd-wrapper .dd-list .dd-filter {
  display: flex;
  justify-content: space-between;
  background-color: white;
  font-size: small;
  padding: 5px 5px 5px 5px;
  border: 0;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.dd-wrapper .dd-list .dd-list-item input[type="checkbox"] {
  vertical-align: bottom;
}
.dd-wrapper .dd-list li button:hover {
  cursor: pointer;

  background-color: #cbe2e6;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dd-filter input {
  font-size: 12px;
  min-width: 240px;
  border: 0;
  width: 100%;
  height: 34px;
  background-color: #c0dde0;
  padding: 3px 10px;
  letter-spacing: 0.33px;
}
.dd-filter input:focus,
.dd-filter input:active {
  outline-color: #d8e3e5;
}
.dd-filter input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}
.dd-filter input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}

.dd-filter input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.dd-filter input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}
