/* FOOTER */
footer {
    background: #4e4e4e;
    color: #ffffff;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
footer a,
footer p {
    color: #ffffff;
    font-size: 12px;
    text-align: center;
}
footer .footer__links {
    padding: 0;
    display: flex;
    text-align: center;
    margin: 4px auto 60px;
    align-self: center;
    justify-content: space-around;
    text-decoration: none;
    list-style: none;
    font-size: 14px;
    line-height: 18px;
    max-width: 916px;
}
.footer__links a {
    margin: 0 12px;
    font-size: 14x;
    line-height: 20px;
    color: #ffffff;
}
footer .footer__siteMap {
    max-width: 916px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
footer .fc__moreLinks,
.fc__jurs, .fc__areas {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.fc__topLink {
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    font-weight: 700;
    margin: 22px 0;
    text-transform: uppercase;
}
.fc__lowerLinks a {
    font-size: 14px;
    line-height: 14px;
    color: #A8E8FF;
    text-decoration: none;
    margin-bottom: 18px;
    display: block;
    text-align: left;
}
.footer__copy {
    font-size: 12px;
    line-height: 22px;
    color: #D1D1D1;
    margin: 10px auto 50px;
}
.footer__copy span {
    margin-right: 24px;
}
.jur__page > *:nth-last-child(2) {
    padding-bottom: 0px;
}
@media (min-width: 768px) {
    .footer__siteMap {
        padding-top: 46px;
        padding-bottom: 40px;
    }
}
@media (max-width: 768px) {
    footer {
        width: 100%;
    }
    footer .footer__siteMap {
        flex-direction: column;
        max-width: 90%;
        margin: 0 auto;
        padding: 40px 10px 10px;
    }
    footer .fc__moreLinks {
        flex-direction: row;
        margin: 20px 0;
        justify-content: space-between;
    }
    footer .fc__moreLinks .fc__topLink {
        font-size: 12px;
        margin: 0 0 0 0;
    }
    .footer__content .footer__links {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    footer .footer__siteMap {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    footer .fc__areas, footer .fc__jurs {
       width: 50%;
    }
    footer .fc__moreLinks {
        width: 100%;
        justify-content: space-around;
    }
    footer .footer__links {
        flex-direction: row;
        text-decoration: underline;
        margin-bottom: 40px;
    }
}