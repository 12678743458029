.mgTreatyBodies__table-container {
    margin-left: 32px;
    overflow-y: auto;
    max-height: 600px;
}

.mgTreatyBodies__table tr {
    height: 20px;
}

.mgTreatyBodies__table tr.greyedOut td {
    color: #D1D1D1;
}

.mgTreatyBodies__table th,
.mgTreatyBodies__table td {
    color: #000;
    font-size: 9px;
    line-height: 22px;
    text-align: center;
    font-weight: 600;
    width: 44px;
    height: 20px !important;
    vertical-align: top;
    border-bottom: 1px solid #D1D1D1;
    border-collapse: collapse;
}

.mgTreatyBodies__table td:first-child {
    color: #575757;
}

.mgTreatyBodies__table th:nth-child(2n),
.mgTreatyBodies__table td:nth-child(2n)  {
    background: #F2F2F2;
}


.mgTreatyBodies__table-container::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border: 1px solid black;
}
.mgTreatyBodies__table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.mgTreatyBodies__table-container::-webkit-scrollbar-thumb {
    background: #b9c1c3;
    border-radius: 5px;
}
.mgTreatyBodies__table-container::-webkit-scrollbar-thumb:hover {
    background: #93999a;
    border-radius: 5px;
}