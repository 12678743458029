.RegionMapSelect__container {
  position: absolute;
  right: 64px;
  bottom: 20px;
}

.area2__page .RegionMapSelect__container {
  bottom: 4px;
}

.area3__page .RegionMapSelect__container,
.area5__page .RegionMapSelect__container,
.area1__page.has__category-selector  .RegionMapSelect__container  {
  bottom: 24px;
}

.area4__page .RegionMapSelect__container {
  bottom: 0px;
}

.SourceText__container {
  padding: 40px !important;
}

.RegionMapSelect__trigger {
  position: relative;
  cursor: pointer;
  width: 52px;
  height: 48px;
  background-color: #E9EFF0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 225ms;
}
.RegionMapSelect__trigger:hover {
  background-color: #FFF;
}
.RegionMapSelect__trigger path {
  fill: #A4CAD1;
  transition: fill 225ms;
}
.RegionMapSelect__trigger:hover path {
  fill: #6EA2B5;
}

.RegionMapSelect__menu {
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
}

.showRegionMapSelect.RegionMapSelect__menu {
  display: flex;
  flex-direction: column;
  width: 232px !important;
  height: 302px !important;
  position: absolute;
  top: -324px;
  background: #fff;
  right: 0;
  box-shadow: 0 3px 6px #00000029;
  padding: 34px 26px 26px;
  overflow: visible;
}

.showRegionMapSelect .btn-close {
  top: 4px;
  right: 4px;
  position: absolute;
  box-shadow: none;
  border: none;
  outline: none;
  color: #bfbfbf;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #fff;
  position: absolute;
  bottom: 16px;
  right: 18px;
}

.showRegionMapSelect.RegionMapSelect__menu .arrow-down {
  bottom: -13px;
}

.RegionMapSelect__container .btn.btn-link {
  position: absolute;
  top: 20px;
  right: 0;
  color: #bfbfbf;
  padding: 0 0 0 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RegionMapSelect__menu.showRegionMapSelect .btn.btn-link {
  top: 6px;
}

.rms__title {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}

.rms__selectItem {
  width: 100%;
  height: 24px;
  padding: 0 25px;
  font-size: 14px;
  line-height: 26px;
  cursor: pointer;
  transition: background-color 225ms;
}

.rms__selectItem:hover {
  background-color: #e9eff0;
}

.rms__selectItem_selected {
  background-color: #ffffff;
}

/* BOZIDAR TODO - find a better place for this */
.selectCategory__container {
  display: flex;
  justify-content: flex-end;
  width: 35%;
  align-items: center;
}
.selectCategory__container_fullwidth {
  flex-grow: 1;
}

.selectCategory__container label {
  padding: 14px 5px;
  border: 2px solid #fff;
  border-right: 0;
  border-right: initial;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 100%;
  background: #d8e3e5;
  cursor: pointer;
}

.area__page .selectCategory__container label {
  padding: 6px 6px 6px 6px;
  white-space:nowrap;
}

.selectCategory__container label:hover {
  background-color: #e7f2f4;
}

.selectCategory__container .selectCategory:last-child label {
  border-right: 2px solid #ffffff;
}

.selectCategory__container input {
  display: none;
}

.selectCategory__container .selectAllCategories {
  min-width: 58px;
}

.selectCategory__container .selectCategory {
  flex-grow: 1;
}

.area3__page .selectCategory__container .selectCategory {
  margin-top: 0;
  max-width: 70px;
  width: 70px;
}

.selectCategory__container input + label {
  position: relative;
  background: #d8e3e5;
}

.selectCategory__container input:checked + label {
  position: relative;
  background: #e9eff0;
}

.selectCategory label {
  color: #3F555D;
}

.RegionMapSelect__menu-container {
  padding: 10px 5px;
  background: #f4f8f8;
}

@media (max-width: 768px) {
  .area4__page .RegionMapSelect__container {
    bottom: -62px;
  }
}

/* BOZIDAR - THIS IS FOR selectCategory, needs to be moved */
@media (max-width: 1024px) {
  .selectCategory__container {
    margin: 24px 0;
  }

  .area3__page .selectCategory__container {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .area3__page .selectCategory__container .selectCategory {
    margin: 4px 4px 4px 4px;
  }

  .area3__page .selectCategory__container label {
    padding: 14px 5px;
    border: 2px solid #fff;
  }
}
