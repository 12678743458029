/* DROPDOWN ============================================ */
.dd-wrapper {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  background: #f4fdff;
  padding: 10px 0px;
  max-width: 100%;
}

.dd-wrapper.dd-wrapperjur {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  background: #f4fdff;
  padding: 10px 26px;
  max-width: 100%;
}

.dd-wrapper .dd-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  padding: 10px 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.dd-wrapper .dd-header__title--bold {
  font-weight: bold;
}
.dd-wrapper .dd-list {
  padding: 0;
  margin: 0;
  width: 100%;
  left: 0px;
  max-height: 400px;
  overflow: auto;
}
.dd-wrapper .dd-list li {
  list-style-type: none;
}
.dd-wrapper .dd-list li:first-of-type > button {
  border-top: 1px solid #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.dd-wrapper .dd-list li:last-of-type > button {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dd-wrapper .dd-list li button {
  display: flex;
  justify-content: space-between;
  background-color: #d8e3e5;
  font-size: small;
  padding: 0px 10px 0px 10px;
  border: 0;

  width: 100%;
  text-align: left;
}

.dd-wrapper .dd-list .dd-filter {
  display: flex;
  justify-content: space-between;
  background-color: white;
  font-size: small;
  padding: 5px 5px 5px 5px;
  border: 0;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.dd-wrapper .dd-list .dd-list-item input[type='checkbox'] {
  vertical-align: bottom;
}
.dd-wrapper .dd-list li button:hover {
  cursor: pointer;

  background-color: #cbe2e6;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dd-filter input {
  padding: 3px;
  width: 100%;
  height: 34px;
  background-color: #cbe2e6;
  border-top: 0 !important;
  border-top: initial !important;
}
.dd-clear-filter {
  border: 0px;
  padding-bottom: 8px;
  width: 100%;
  height: 34px;
  background-color: transparent;
  font-size: 12px;
  color: #676e6f;
}
.dd-wrapper .dd-list-item button {
  border: medium none currentColor;
  border: initial;
  background: #f4fdff;
  padding: 0;
  width: 100%;
}
.dd-wrapper .accordionsContainer {
  margin-top: 20px;
}
.dd-wrapper .accordion-body {
  max-height: 370px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: #f4fdff;
  padding-left: 12px;
}
.dd-wrapper .accordion-body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border: 1px solid black;
}
.dd-wrapper .accordion-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.dd-wrapper .accordion-body::-webkit-scrollbar-thumb {
  background: #b9c1c3;
  border-radius: 5px;
}
.dd-wrapper .accordion-body::-webkit-scrollbar-thumb:hover {
  background: #93999a;
  border-radius: 5px;
}

.dd-wrapper ul.dd-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border: 1px solid black;
}
.dd-wrapper ul.dd-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.dd-wrapper ul.dd-list::-webkit-scrollbar-thumb {
  background: #b9c1c3;
  border-radius: 5px;
}
.dd-wrapper ul.dd-list::-webkit-scrollbar-thumb:hover {
  background: #93999a;
  border-radius: 5px;
}

.dd-wrapper .accordion-item {
  border: none;
}
.dd-wrapper .dd-list-item input[type='checkbox'] {
  color: #dbedef;
  border-color: #dbedef;
  width: 14px;
  height: 14px;
  margin-right: 13px;
}
.dd-wrapper .dd-list-item button > span {
  display: flex;
  justify-content: flex-start;

  font-size: 15px;
  line-height: 22px;
  align-items: center;
}
.dd-wrapper .dd-list-item button img {
  margin-right: 5px;
}
.dd-wrapper .accordion-item:first-child {
  border-bottom: 0;
  border-bottom: initial;
}
.dd-wrapper .accordion-button {
  background-color: #f4fdff;
  box-shadow: none;
  border-bottom: 1px solid #dbedef !important;
}
.dd-wrapper .accordion-button h5 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: #528598;
  text-transform: uppercase;
  margin-bottom: 0;
}
.accordion-button::after {
  background-image: none !important;
  background-image: initial !important;
  position: absolute;
  content: '';
  border-left: 3px solid #707070;
  border-top: 3px solid #707070;
  padding: 5px;
  right: 13px;
  top: 14px;
  -webkit-transform: rotate(-225deg) scale(0.475);
          transform: rotate(-225deg) scale(0.475);
}
.tableArea1-1 .accordion-button::after {
  top: 14px !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: none !important;
  background-image: initial !important;
  position: absolute;
  content: '';
  border-left: 3px solid #707070;
  border-top: 3px solid #707070;
  padding: 5px;
  right: 13px;
  top: 14px;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: rotate(-135deg) scale(0.475);
          transform: rotate(-135deg) scale(0.475);
}

.dd-wrapper .dd-list-item.WhatItem button {
  background: #f4fdff !important;
  border-top: 0 !important;
  border-top: initial !important;
}

/* CUSTOM CHECKBOX STYLES */

.check-container {
  background-color: #fff;
  display: block;
  margin: 0;
  position: relative;
  width: 100%;
}
.check-container label {
  padding: 5px 12px 5px 28px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  position: relative;
  color: #000;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  background-color: #f4fdff;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.check-container label:before {
  width: 14px;
  height: 14px;
  border-radius: 0;
  content: '';
  background-color: #dff2f2;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
          transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.check-container label:after {
  width: 14px;
  height: 14px;
  content: '';
  border: 1px solid #98bbc7;
  background-color: #f4fdff;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%2398BBC7' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: -15px -15px;
  border-radius: 0;
  z-index: 2;
  position: absolute;
  left: 7px;
  top: 16px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
}

.filterModal__body .check-container label:after {
  top: 13px;
}
.check-container input:checked ~ label:before {
  -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
          transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.check-container input:checked ~ label:after {
  background-color: #dff2f2;
  border-color: #98bbc7;
  background-position: -2px -2px;
}
.check-container input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}

.no-check-container {
  background-color: #fff;
  display: block;
  margin: 0;
  position: relative;
  width: 100%;
}
.no-check-container label {
  padding: 5px 5px 5px 5px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  position: relative;
  color: #000;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  background-color: #fff;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.no-check-container label:hover {
  background-color: #f9f7ff;
}



.regionsAccordion .accordion-body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 36px 0 0;
  overflow-x: hidden;
}
.regionsAccordion .accordion-body > .dd-list-item {
  width: 44%;
  display: flex;
  flex-wrap: wrap;
}

.dropdown-menu {
  padding: 0;
  padding: initial;
  margin-top: 10px;
}

.check-container__disabled label {
  color: lightgray;
}
.check-container__disabled input[type='checkbox'] {
  color: gray;
}

@media (max-width: 768px) {
  .accordion-button:not(.collapsed)::after,
  .accordion-button::after {
    top: 0;
  }
}
