@media (max-width: 347px) {
  div.area__page.area3__page .selectRegion__section > .content__container {
    padding-top: 342px;
  }
  div.area__page.area3__page .selectRegion__section {
    min-height: 954px;
  }
  .tableFooter > div {
    display: flex;
    flex-direction: column;
  }
  .homepage__contentBox .hpcb__areasContainer {
    flex-direction: column !important;
  }
  .page__home .homepage__contentBox .hpcb__areasBox {
    width: 100% !important;
  }
}

@media (min-width: 347px) and (max-width: 440px) {
  div.area__page.area3__page .selectRegion__section > .content__container {
    padding-top: 286px;
  }
  div.area__page.area3__page .selectRegion__section {
    min-height: 904px;
  }
}

@media (max-width: 440px) {
  div.area3__page .MapChartSettings__container,
  div.area3__page .RegionMapSelect__container,
  div.area3__page .MapInfoBtn__container,
  div.area3__page .mapYearFilterSwitch {
    bottom: -36px;
  }
}

@media (max-width: 768px) {
  .menuHomepage__actions {
    bottom: -26px;
    top: auto;
    top: initial;
    display: flex;
  }
  .menuHomepage__actions button {
    max-width: 90px;
    font-size: 10px;
  }
  .menuHomepage__actions button:first-child {
    margin-right: 10px;
  }
  .section__homePage .content__section {
    flex-direction: column-reverse;
  }

  .section__homePage .content__section > div {
    width: 100%;
  }

  .section__homePage .homepage__sidebar {
    margin: var(--verticalMargin);
  }

  .section__homePage .homepage__deadlines,
  .section__homePage .homepage__latestUpdates,
  .section__homePage .homepage__jurisdictions,
  .section__homePage .homepage__contentBox {
    padding: 26px 20px;
  }

  .homepage__deadlines h4 {
    margin-bottom: 10px;
  }

  .section__homePage .hero__section .mapDiv {
    right: -48px !important;
    top: -118px !important;
  }

  header.section__homePage .logoContainer {
    max-width: 110px;
    position: relative;
    top: -62px;
    left: 16px;
  }
  .page__home .hero__section h1 {
    top: 40px;
    left: 28px;
    font-size: 32px;
    line-height: 38px;
  }
  div.page__home h4 {
    margin-bottom: 0;
  }
  div.hpcb__welcome p:nth-child(2) {
    margin-top: 16px;
  }
  div.hpcb__welcome p:last-child {
    margin-bottom: 0;
  }
  .page__home .hpcb__tools .hpcb__toolsContainer,
  .page__home .hpcb__areasContainer {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .page__home .homepage__contentBox .hpcb__areasBox {
    width: calc(50% - 10px);
    margin: 20px 0 0 0;
    justify-content: flex-start;
    padding: 32px 12px;
  }
  .homepage__contentBox p.hpcb__areaSubTitle {
    font-size: 12px;
    line-height: 14px;
  }
  .homepage__contentBox p.hpcb__areaTitle,
  .hpcb__tools p.hpcb__toolTitle {
    font-size: 14px;
    line-height: 18px;
  }
  .page__home .hpcb__toolsContainer .hpcb__toolBox svg,
  .page__home .homepage__contentBox .hpcb__areasBox svg {
    margin-top: auto;
  }
  .page__home .homepage__contentBox .hpcb__areasBox:nth-child(2) svg,
  .page__home .homepage__contentBox .hpcb__areasBox:nth-child(4) svg {
    position: relative;
    top: 10px;
  }
  div.homepage__contentBox .hpcb__areaBox-hiddenContent,
  div.homepage__contentBox .hpcb__toolBox-hiddenContent {
    padding: 24px 10px 24px 14px;
  }

  div.homepage__contentBox .hpcb__areaBox-hiddenContent p,
  div.homepage__contentBox .hpcb__toolBox-hiddenContent p {
    font-size: 12px;
    line-height: 14px;
  }
  div.homepage__contentBox .hpcb__areasBox:hover .hpcb__areaBox-hiddenContent p {
    max-height: 160px;
  }
  div.hpcb__tools .hpcb__toolBox {
    height: 170px;
  }
  div.homepage__contentBox .hpcb__toolBox:hover .hpcb__toolBox-hiddenContent p {
    max-height: 128px;
  }
  div.homepage__latestUpdates .itemContent {
    font-size: 14px;
    line-height: 18px;
  }
  div.homepage__deadlines .hdi__title,
  div.homepage__latestUpdates .hlu__topBar .country,
  div.homepage__latestUpdates .hlu__topBar {
    font-size: 15px;
    line-height: 17px;
  }
  div.homepage__latestUpdates .hlu__topBar img {
    position: relative;
    top: -2px;
  }

  div.homepage__deadlines .hdi__topBar {
    font-size: 13px;
    line-height: 16px;
  }
  div.homepage__deadlines .hdi__topBar span {
    font-size: 12px;
    line-height: 16px;
  }
  /* end of homepage css */

  .area__page.area1__page .selectRegion__section > .content__container {
    height: 682px;
  }
  .area__page.area1__page.area__A1-18 .selectRegion__section > .content__container {
    height: 824px;
  }
  .area1__page .MapChartSettings__container,
  .area1__page .RegionMapSelect__container,
  .area1__page .MapInfoBtn__container {
    bottom: -30px;
  }
  .area1__page.A1-1 .MapChartSettings__container,
  .area1__page.A1-1 .RegionMapSelect__container,
  .area1__page.A1-1 .MapInfoBtn__container {
    bottom: -116px;
  }
  .area1__page .selectRegion__section {
    min-height: 916px;
  }
  .area1__page.has__category-selector .selectRegion__section {
    min-height: 830px;
  }
  .area1__page.A1-3 .selectRegion__section {
    min-height: 832px;
  }
  .area1__page.A1-4 .selectRegion__section {
    min-height: 852px;
  }
  .area1__page.A1-4 .MapChartSettings__container,
  .area1__page.A1-4 .RegionMapSelect__container,
  .area1__page.A1-4 .MapInfoBtn__container {
    bottom: -52px;
  }

  .area1__page.area__A1-18 .selectRegion__section {
    min-height: 964px;
  }
  .recommendations__listItem span {
    font-size: 14px;
    line-height: 18px;
  }

  .recommendations__section .recs__actions {
    flex-direction: column;
  }

  .recs__actions .recs__tabBtn {
    border-right: 2px solid #b0cbce;
  }

  .recommendations__section .recs__left {
    max-width: none;
    max-width: initial;
    padding: 30px 30px 15px 30px;
  }

  .area1__page .areaContent__header {
    /*margin-bottom: 32px;*/
    margin-bottom: 0;
  }

  .area1__page.area__A1-18 .areaContent__header {
    margin-bottom: 4px;
  }

  .table__section .areaContent__header {
    padding-right: 16px;
  }

  .ach__iconContainer {
    margin: 0 0 0 0;
  }

  .table__section {
    padding-top: 0;
    padding-bottom: 1px;
  }

  .table__section .ach__titlesContainer {
    padding: 0 0 0 0;
  }

  .table__section .ach__titlesContainer .areaContent__title {
    margin-bottom: 12px;
  }

  .ach__titlesContainer .areaContent__title {
    font-size: 26px;
    margin-top: 0;
    text-align: center;
    width: 100%;
  }

  .reactSelect__link-dropdown .dropdown-item.rsi__small:not(:first-child) a {
    font-size: 12px;
    line-height: 14px;
  }

  .area__page .dd-wrapper {
    width: 90vw !important;
    left: 5vw;
  }

  .area5__page .mainMap__quickLinks-container button {
    font-size: 14px;
    margin-top: 0;
    flex-grow: 1;
  }

  .table__quickLinks {
    flex-direction: column;
  }

  .table__quickLinks .tql__item {
    padding: 20px;
    width: 100%;
    margin-bottom: 12px;
  }

  .table__quickLinks .tql__item span {
    margin-bottom: 0;
  }

  .areaContent__title,
  .areaContent__subtitle,
  .table__actions {
    width: calc(100% - 15px);
  }

  .quickSearch__container {
    margin-bottom: 40px;
  }

  .quickSearch__container.individualComm__title {
    margin-top: 90px;
  }

  .searchItem2__box {
    font-size: 18px;
    height: 50px;
    margin-bottom: 10px;
  }

  .methodologyModal .modal-header {
    min-height: 212px;
  }

  .methodologyModal .modal-body {
    padding: 30px 40px;
  }

  header a.logoContainer {
    padding-bottom: 0;
    display: inline-block;
    max-width: 240px;
    padding-left: 20px;
  }

  .quickSearch__container {
    flex-direction: column;
    width: 96%;
  }

  .quickSearch__left,
  .quickSearch__right {
    width: 100%;
  }

  .quickSearch__left {
    margin-bottom: 20px;
  }

  .searchItems__container .searchItem__box {
    width: 48%;
  }

  .area3__page .searchItems__container .searchItem__box {
    width: 100%;
  }

  .area3__page .quickSearch__right {
    width: 100%;
    margin-top: 40px;
  }

  .area3__page .searchItems__container .searchItem__box:nth-child(7),
  .area3__page .searchItems__container .searchItem__box:nth-child(9) {
    margin-bottom: 15px;
  }

  .searchItems__container .searchItem__box:last-child {
    margin-top: 15px;
  }

  .dropdownCombo__container svg {
    right: 8px;
    top: 8px;
  }

  .dropdownCombo__container .btn,
  .reactSelect > div {
    font-size: 14px;
    line-height: 16px;
    white-space: break-spaces;
  }

  .table__section .content__container {
    max-width: calc(90% + 14px) !important;
    margin-left: 5%;
  }

  .dd-wrapper__search {
    -webkit-transform: translate(-185px, 56px);
            transform: translate(-185px, 56px);
  }

  .tableArea1-1 th:first-child,
  .tableArea1-1 td:first-child {
    width: 62px !important;
    min-width: 62px !important;
  }

  .table__section .areaContent__subTitle,
  .content__container .areaContent__subTitle {
    font-size: 22px;
    text-align: center;
    line-height: 24px;
    margin-bottom: 22px;
  }
  .table__section .areaContent__subTitle {
    padding: 0 24px;
  }
  .areaContent__left,
  .areaContent__right {
    background-color: #f4f8f8;
    max-width: none;
    max-width: initial;
  }

  .areaContent__title,
  .areaContent__subTitle {
    font-size: 26px;
    line-height: 26px;
  }

  .areaContent__subTitle {
    margin-bottom: 32px;
  }

  .areaContent__section .content__container {
    max-width: 100% !important;
  }

  .reactSelect__link-dropdown .dropdown-menu .rsi__small a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
            hyphens: auto;
    white-space: normal;
    font-size: 12px;
    line-height: 14px;
  }

  .reactSelect__link-dropdown .reactSelect__item:first-child.rsi__small a,
  .reactSelect__link-dropdown .reactSelect__item-topLevel,
  .reactSelect__item-topLevel,
  .reactSelect__item-topLevel-dd .accordion-item .accordion-button {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
            hyphens: auto;
    white-space: normal;
    font-size: 12px;
    line-height: 14px;
  }

  .showMapSettings.MapChartSettings__menu {
    top: -522px;
    right: -11px;
    margin-bottom: 0;
  }

  .area__page.area1__page .selectRegion__section > .content__container {
    padding-top: 126px;
  }

  .area__page.area6__page .selectRegion__section > .content__container {
    padding-top: 46px;
  }

  .area__page.area2__page .selectRegion__section > .content__container {
    padding-top: 164px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .area__page.area3__page .selectRegion__section > .content__container {
    padding-top: 120px;
  }

  .area__page.area2__page .selectRegion__section {
    min-height: 800px;
  }

  .area__page.area2__page .MapChartSettings__container,
  .area2__page .RegionMapSelect__container,
  .area2__page .MapInfoBtn__container {
    bottom: -48px;
  }

  .area__page.area3__page .selectRegion__section {
    min-height: 830px;
  }

  .area__page.area3__page .selectRegion__section > .content__container {
    padding-top: 230px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .area4__page .selectTreaty__select_container {
    display: flex;
    width: 100%;
  }

  .area__page.area4__page .selectRegion__section > .content__container {
    padding-top: 142px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .area__page.area4__page .selectRegion__section {
    min-height: 754px;
  }

  .area__page.area4__page .showMapSettings.MapChartSettings__menu {
    top: -524px;
  }

  .area4__page .mainGraphic__legend {
    width: 100%;
  }

  .area4__page .mainGraphic__section img {
    width: 100%;
  }

  .area__page.area5__page .selectRegion__section > .content__container {
    padding-top: 146px;
  }

  .area__page.area5__page .selectRegion__inputs-container {
    top: 48px;
  }

  .area__page.area5__page .selectRegion__section {
    min-height: 716px;
  }

  header .content__container {
    flex-direction: column;
  }

  header .logoContainer img {
    max-width: 196px;
    margin-top: 0;
  }

  header .menuContainer {
    margin-left: auto;
    order: -1;
    margin-right: 20px;
  }

  div#chartdiv {
    height: 320px;
    min-height: 320px;
  }

  .area1__page div#chartdiv {
    height: 400px;
    min-height: 400px;
  }

  .menuLogo {
    max-width: 135px;
    margin-top: 10px;
  }

  .content__wide .boxLeft {
    margin-bottom: 20px;
  }

  .list-unstyled {
    margin-bottom: 10px;
  }

  .hero__section h1 {
    max-width: 90%;
  }

  .hero__section {
    padding-top: 60px;
  }

  .content__wide .boxLeft a span,
  .content__wide .boxRight a span {
    font-size: 38px;
    line-height: 40px;
  }

  .area__page.area5__page .selectRegion__section > .content__container {
    padding-top: 188px;
  }

  .area__page.area5__page .mainMap__quickLinks-container {
    margin-left: 0;
    margin-left: initial;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .mainCTA__section60 {
    width: 100%;
  }

  .mainCTA__section40 {
    width: 100%;
    max-width: none;
    max-width: initial;
  }

  .mapLegend__item_10 {
    flex-grow: 1;
  }

  .selectRegion__inputs-container .css-tlfecz-indicatorContainer {
    height: 38px;
  }

  .selectRegion__inputs-container .selectTreaty__select .css-tlfecz-indicatorContainer svg,
  .selectRegion__inputs-container .selectTreaty__select .css-1gtu0rj-indicatorContainer svg,
  .selectRegion__inputs-container .selectTreaty__select .css-tlfecz-indicatorContainer svg {
    top: 0;
  }

  .selectRegion__inputs-container .selectTreaty__select .css-g1d714-ValueContainer,
  .selectRegion__inputs-container .selectTreaty__select .css-1hwfws3,
  .selectRegion__inputs-container .selectTreaty__select .css-1hb7zxy-IndicatorsContainer,
  .selectRegion__inputs-container .selectTreaty__select .css-1gtu0rj-indicatorContainer,
  .selectRegion__inputs-container .selectTreaty__select .css-tlfecz-indicatorContainer {
    height: 38px;
  }

  .selectCategory__container label {
    font-size: 10px;
  }

  .areaContent__section .areaContent__left {
    margin-bottom: 28px;
  }

  .mainCTA__2charts {
    flex-direction: column;
  }
  .area1__page.has__category-selector .MapChartSettings__container,
  .area1__page.has__category-selector .RegionMapSelect__container,
  .area1__page.has__category-selector .MapInfoBtn__container {
    bottom: -12px;
  }

  .has__category-selector.area__page .mainCTA__2charts .mainCTA__section40 {
    margin-top: 28px;
  }

  .area4__page .MapInfoBtn__container {
    bottom: -62px;
  }
  div.recommendations__listItem {
    height: 60px;
  }
  .mapYearFilterSwitch {
    bottom: -16px;
  }
  div.area3__page .MapChartSettings__container,
  div.area5__page .MapChartSettings__container,
  div.area3__page .RegionMapSelect__container,
  div.area5__page .RegionMapSelect__container,
  div.area3__page .MapInfoBtn__container,
  div.area5__page .MapInfoBtn__container,
  div.area3__page .mapYearFilterSwitch,
  div.area5__page .mapYearFilterSwitch {
    bottom: -28px;
  }
  div.area5__page.map__alternate .MapChartSettings__container,
  div.area5__page.map__alternate .RegionMapSelect__container,
  div.area5__page.map__alternate .MapInfoBtn__container,
  div.area5__page.map__alternate .mapYearFilterSwitch {
    bottom: -28px;
  }

  .area6__page .table__section .ach__iconContainer svg {
    -webkit-transform: scale(1.275) translate3d(-4px, 3px, 1px);
            transform: scale(1.275) translate3d(-4px, 3px, 1px);
  }

  .area6__page .mapContent__container .ach__iconContainer svg {
    -webkit-transform: scale(0.6) !important;
            transform: scale(0.6) !important;
  }

  /* Area6 new responsive css */
  .area6__content,
  .area6__page > .content__container {
    flex-direction: column;
    padding: 0 28px;
  }
  .area6__content .areaContent__left,
  .area6__content .areaContent__right {
    width: 100%;
    margin: 0 0 0 0;
  }
  .area6__content .areaContent__right {
    margin: var(--verticalMargin);
  }
  .resolutions__content,
  .a6activeTab__area .accordion-body .infoBox,
  .areaContent__left,
  .areaContent__right,
  .infoBox.infoBox-white {
    padding: 18px 18px 12px 18px;
  }
  .resolutions__tabLinks-container,
  .a6activeTab__area .accordion-body,
  .infoBox__footer,
  .infoBox__action-bar {
    flex-direction: column;
  }
  .resolutions__tabLinks-container .resolutions__tabLink {
    width: 100%;
    padding: 14px 24px;
    margin-bottom: 8px;
  }
  .a6activeTab__area .accordion-body .infoBox,
  .areaContent__left.areaContent-transparent{
    width: 100%;
  }
  .resolutions__tabIndicator {
    margin-bottom: 0;
  }
  .a6activeTab__area {
    margin-top: 12px;
  }
  .infoBox__footer button {
    margin-top: 14px;
    width: 100%;
  }
  .a6activeTab__area .accordion-button::after {
    top: calc(50% - 8px);
  }
  .tableArea6-1 table.mainTable {
    width: 1600px;
  }
  .tableArea6-1 th:first-child, .tableArea6-1 td:first-child {
    width: 10% !important;
    min-width: 0 !important;
    min-width: initial !important;
  }
  .infoBox__action-bar .infoBox__action-button {
      margin-right: 0;
      margin-bottom: 12px;
      line-height: 16px;
  }
  .infoBox__action-bar .infoBox__action-button:last-child {
      margin-bottom: 0;
  }
  .infoBox__vote-list {
      grid-template-columns: repeat(1, 1fr);
  }
  .area__page .infoBox.infoBox-white {
      padding-bottom: 18px;
  }
  .infoBox__chart-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .areaContent__sponsors-container {
    width: 100%;
    margin: 0 0 28px 0;
    position: static;
    height: auto;
    height: initial;
  }
  .area__page .infoBox .infoBox__title {
    font-size: 18px;
    line-height: 22px;
  }
  .areaContent__sponsors {
    margin-bottom: 0;
  }
  .areaContent__sponsors ul {
    grid-template-columns: repeat(1, 1fr);
  }
  .infoBox__chart-containerActions button,
  .infoBox__chart-switchContainer {
    height: 40px;
  }
  .area__page .infoBox .infoBox__info-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  .infoBox__info-bar .ib__tag {
    margin-bottom: 8px;
  }
  .area6__content p {
    font-size: 14px;
    line-height: 22px;
  }
  .vr__barChart-container {
    padding: 12px 10px;
  }
  .resolutions__tabLink .rtl__mainHeading {
    font-size: 18px;
    line-height: 24px;
  }
  .resolutions__tabLink {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 1024px) {
  .jurHero__section .content__container > .content__container {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    max-width: none !important;
    max-width: initial !important;
  }
  .tableFooter {
    padding-right: 14px;
  }
  .tablePagination button:first-child {
    margin: 0 auto 0 0;
  }
  .tablePagination button:last-child {
    margin: 0 0 0 auto;
  }
  .recommendations__listItem {
    height: 50px;
  }
  .area__page.area1__page .selectRegion__section > .content__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .area1__page .mapLegend {
    top: -18px;
    position: relative;
  }
  .area1__page.has__category-selector.has__category-selector .selectRegion__section {
    min-height: 812px;
  }

  .area1__page.has__category-selector .mapLegend {
    top: -2px;
  }
  .area__page.area1__page .content__container.mapContent__container > div:first-child {
    top: 0;
  }
  .area__page.has__category-selector .content__container.mapContent__container > div:first-child {
    top: 20px;
  }
  .area__page .selectRegion__inputs-container {
    padding: 0 20px;
  }
  .recommendations__section {
    flex-direction: column;
  }

  .recommendations__section .recs__left,
  .recommendations__section .recs__right {
    width: 100%;
    max-width: none;
    max-width: initial;
  }

  .recommendations__section .recs__right {
    margin-top: 26px;
  }

  .menu__modal.modal {
    max-height: calc(100vh - 10px);
  }

  .menu__modal .modal-content {
    border-radius: 0 0 0 0;
  }

  .login__modal.menu__modal .modal-header img {
    margin-top: 54px;
    margin-bottom: 62px;
  }

  .hero__section img {
    width: 90%;
    margin-top: 50px;
    margin-bottom: 16px;
  }

  .selectTreaty__select {
    margin-left: 0;
    margin-top: 12px;
  }

  .selectRegion__select {
    margin-top: 12px;
  }

  .content__wide .boxLeft,
  .content__wide .boxRight,
  .content__section .content__left,
  .content__section .content__right {
    width: 100%;
  }

  .footer__content .footer__links {
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 32px;
  }

  .footer__content .footer__copy {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }

  header {
    padding: 15px 0;
  }

  .menuLeft {
    display: none;
  }

  header.section__homePage a.logoContainer img {
    height: 100px;
  }

  .area__page > .content__container,
  .page__home > .content__container {
    max-width: 100% !important;
    padding: 0 28px;
  }

  .content__wide,
  .content__section .content__container {
    flex-direction: column;
    width: 100% !important;
    max-width: 100% !important;
  }

  .selectRegion__section img {
    width: 100%;
  }

  .content__wide .boxLeft {
    margin-bottom: 25px;
  }

  .selectRegion__section .selectArea__select {
    max-width: none;
    max-width: initial;
    margin-right: 0;
  }

  .area__page.area2__page .isb-4 > div,
  .jurProfile__area2 .isb-4 > div {
    width: calc(50% - 2px);
  }

  .area__page.area2__page .infoBox__container,
  .jurProfile__area2 .infoBox__container {
    max-width: none;
    max-width: initial;
    width: 100%;
  }

  .area__page.area2__page .infoSection__box,
  .jurProfile__area2 .infoSection__box {
    margin-right: 32px;
  }

  .area__page.area2__page .infoSection__box > div,
  .jurProfile__area2 .infoSection__box > div {
    width: 100%;
    max-width: none;
    max-width: initial;
    margin: 0 0 4px 0;
  }

  .area__page .isb-1,
  .jurProfile__area2 .isb-1 {
    width: 100%;
  }

  .area__page.area2__page .info__section {
    padding: 28px;
  }

  .area2__page .table__section .areaContent__header {
    margin: 0 0 28px;
  }

  .area__page.area2__page .areaContent__section {
    padding-bottom: 0;
  }
  .area2__page .infoSection__box {
    margin-bottom: 28px;
  }
  .area2Table__section.table__section {
    margin-top: 0;
    padding: 0 28px;
  }

  .area2__page .MapInfoBtn__container,
  .area3__page .MapInfoBtn__container,
  .area4__page .MapInfoBtn__container,
  .area5__page .MapInfoBtn__container {
    right: 154px;
  }
  .area2__page .RegionMapSelect__container,
  .area3__page .RegionMapSelect__container,
  .area4__page .RegionMapSelect__container,
  .area5__page .RegionMapSelect__container {
    right: 88px;
  }
  .area2__page .MapChartSettings__container,
  .area3__page .MapChartSettings__container,
  .area4__page .MapChartSettings__container,
  .area5__page .MapChartSettings__container {
    right: 22px;
  }
  .area5__page .mapYearFilterSwitch {
    right: 204px;
  }

  .area__page.area5__page .selectRegion__section > .content__container {
    padding-top: 144px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .area__page.area5__page .mainMap__quickLinks-container {
    margin-left: 0;
    margin-left: initial;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .area5__page .selectRegion__inputs-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .area5__page .selectRegion__select {
    width: 50%;
  }

  .area5__page .mainMap__quickLinks-container {
    margin-left: 0;
    margin-left: initial;
    margin-top: 12px;
  }
  .area1__page .MapChartSettings__container {
    right: 20px;
  }
  .area1__page .RegionMapSelect__container {
    right: 86px;
  }
  .area1__page .MapInfoBtn__container {
    right: 152px;
  }

  .mainCTA__2charts .mainCTA__section50:last-child {
    margin: var(--verticalMargin);
  }

  .mapLegend__item_10 .stripe {
    margin-bottom: 6px;
  }

  .mapLoader__container #loading img {
    position: static !important;
  }
  .area5__page .MapChartSettings__container,
  .area5__page .RegionMapSelect__container,
  .area5__page .MapInfoBtn__container,
  .area5__page .mapYearFilterSwitch {
    bottom: 10px;
  }
  .area5__page.map__alternate .MapChartSettings__container,
  .area5__page.map__alternate .RegionMapSelect__container,
  .area5__page.map__alternate .MapInfoBtn__container,
  .area5__page.map__alternate .mapYearFilterSwitch {
    bottom: -4px;
  }
  .area3__page .MapChartSettings__container,
  .area3__page .RegionMapSelect__container,
  .area3__page .MapInfoBtn__container,
  .area3__page .mapYearFilterSwitch {
    bottom: 18px;
  }
}

@media (min-width: 1024px) {
  .selectTreaty__select {
    max-width: 382px;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .toTop__btn {
    bottom: 20px;
    left: 20px;
  }
  .JurProfileSummary__section {
    padding: 20px 20px 20px 20px;
  }
  .jurSearch__countryList button {
    width: calc(25% - 8px);
  }
  .section__jurs .content__container {
    padding: 0 20px;
  }
  .jurHero__section > .content__container {
    padding: 0 20px;
  }
  .mapYearFilterSwitch {
    right: 200px;
    bottom: -25px;
  }
  .area1__page.has__category-selector .MapChartSettings__container,
  .area1__page.has__category-selector .RegionMapSelect__container,
  .area1__page.has__category-selector .MapInfoBtn__container {
    bottom: -22px;
  }
  .hero__section {
    min-height: 640px;
    padding-bottom: 40px;
  }

  .section__homePage .page__home .hero__section {
    margin-top: 180px;
    min-height: 380px;
  }

  .area__page.area3__page .selectRegion__section #chartdiv.mapDiv {
    height: 400px;
    min-height: 400px;
  }

  .area5__page .selectRegion__inputs-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .area5__page .selectRegion__select {
    width: 50%;
  }

  .area5__page .mainMap__quickLinks-container {
    margin-left: 0;
    margin-left: initial;
    margin-top: 12px;
  }

  .area5__page .mainMap__quickLinks-container button {
    margin-top: 0;
  }

  .area__page.area5__page .mainMap__quickLinks-container {
    width: auto;
    width: initial;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .areaContent__left,
  .areaContent__right {
    padding: 40px 40px 20px 40px;
  }
  .areaContent__left {
    max-width: calc(60% - 10px);
  }
  .areaContent__right {
    max-width: calc(40% - 10px);
  }

  .area__page.area5__page .selectRegion__section > .content__container {
    padding-top: 140px;
  }

  .area__page.area1__page .selectRegion__section > .content__container {
    padding-top: 124px;
    padding-bottom: 0;
  }

  .area__page.area1__page .selectRegion__section {
    /*min-height: 864px !important;*/
  }

  .area__page.area1__page .selectRegion__section .mapContent__container > div:first-child,
  .area__page.area1__page div#chartdiv {
    height: 500px;
    max-height: 500px;
    top: 0;
  }

  .content__container .areaContent__section .content__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100% !important;
  }

  .selectRegion__section .selectArea__select {
    max-width: none;
    max-width: initial;
    margin: 0 0 0 0;
  }

  div#chartdiv {
    max-height: 560px;
    min-height: 560px;
  }

  .area1__page div#chartdiv {
    max-height: 400px;
    min-height: 400px;
  }

  .area1__page .mapLegend {
    position: relative;
    top: 18px;
  }

  .area1__page .MapChartSettings__container,
  .area1__page .RegionMapSelect__container,
  .area1__page .MapInfoBtn__container {
    bottom: -76px;
  }

  .MapChartSettings__container {
    bottom: -58px;
  }

  .MapChartSettings__container {
    bottom: 20px;
  }

  .area__page.area4__page .selectRegion__section {
    min-height: 844px;
  }

  .area__page.area4__page .showMapSettings.MapChartSettings__menu {
    top: -474px;
  }

  .area3__page .selectRegion__section {
    min-height: 774px;
  }

  .selectRegion__inputs-container {
    flex-direction: column;
  }

  .searchItems2__container {
    height: calc(100% - 72px);
  }

  .area__page.area3__page .selectRegion__section > .content__container {
    padding-top: 172px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .area__page.area4__page .MapChartSettings__container {
    bottom: -10px;
  }

  .area__page.area5__page .selectRegion__section {
    min-height: 914px;
  }

  footer .fc__jurs {
    padding-left: 60px;
  }
  .selectCategory__container {
    width: 346px;
  }
}

@media (min-width: 769px) and (max-width: 960px) {
  .area2__page .areaContent__left {
    width: 50%;
  }

  .area2__page .areaContent__right {
    width: calc(50% - 28px);
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 40px 20px 40px;
  }

  .area2__page .areaContent__right .circle {
    flex-grow: 0;
    height: 250px;
    width: 250px !important;
  }

  .mainCTA__section60 {
    width: calc(50% - 14px);
  }

  .mainCTA__section40 {
    width: calc(50% - 14px);
    max-width: 500px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pieOptions {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1140px) {
  .recommendations__listItem {
    height: 50px;
  }
  .menu__modal .menu__accordion {
    position: relative;
    width: auto;
    width: initial;
  }
  .menu__modal .accordion-button::after {
    top: 0 !important;
  }
  header .content__container {
    padding: 0 20px;
  }
  div.homepage__sidebar,
  div.homepage__content {
    width: calc(50% - 14px);
  }
  div.homepage__contentBox .hpcb__areasBox {
    width: calc(50% - 10px);
    margin-top: 20px;
  }
  div.homepage__contentBox .hpcb__areasBox {
    margin-top: 10px;
    margin-right: 20px;
    min-width: 160px;
    padding: 16px;
  }
  div.homepage__contentBox,
  div.homepage__deadlines,
  div.homepage__latestUpdates,
  div.homepage__jurisdictions {
    padding: 26px 22px 20px;
  }
  div.homepage__contentBox .hpcb__areaBox-hiddenContent {
    padding: 18px 16px 12px;
  }
  div.homepage__contentBox .hpcb__areasBox:hover .hpcb__areaBox-hiddenContent p {
    max-height: 168px;
  }
  div.homepage__contentBox .hpcb__areasContainer {
    overflow-x: auto;
  }
  div.hpcb__toolsContainer,
  div.homepage__contentBox .hpcb__areasContainer {
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  div.hpcb__toolsContainer {
    width: 100%;
  }
  div.hpcb__toolsContainer .hpcb__toolBox {
    min-width: 126px;
    margin-top: 10px;
  }
  div.hpcb__toolsContainer .hpcb__toolBox:last-child,
  div.homepage__contentBox .hpcb__areasBox:last-child {
    margin-right: 0;
  }
  .areaContent__sponsors ul {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 1024px) and (max-width: 1140px) {
  .jurSearch__countryList button {
    width: calc(20% - 8px);
  }
}

@media (max-width: 1140px) {
  .homepage__latestUpdates button,
  div.hpcb__tools .hpcb__tools-button,
  div.hcpb__monitor .hcpb__monitor-button,
  div.homepage__deadlines .hd__button,
  div.hcpb__monitor .monitor__box,
  div.hj__box,
  div.homepage__jurisdictions .hj__button,
  div.homepage__contentBox .hpcb__areas-button {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .page__home .hero__section p {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .menu__modal {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .menu__modal .modal-body {
    padding: 40px 60px;
    height: 70vh;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .menu__modal .subNav__links:last-child {
    margin-left: 0;
    margin-left: initial;
    margin-top: 20px;
  }

  html,
  body {
    overflow-x: hidden;
  }
}

@media (min-width: 1024px) and (max-width: 1084px) {
  .mapLegend__item {
    width: 126px;
    font-size: 12px;
    line-height: 14px;
  }
  .mapLegend__item span:last-child {
    padding: 0 8px;
  }
}

@media (min-width: 980px) and (max-width: 1092px) {
  .areaContent__section .areaContent__left,
  .areaContent__section .areaContent__right {
    max-width: none;
    max-width: initial;
    padding: 30px;
    width: calc(50% - 14px);
    display: flex;
    flex-direction: column;
  }
  .recommendations__section .recs__left,
  .recommendations__section .recs__right {
    padding: 30px 30px;
  }
  footer .footer__siteMap {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  footer .fc__moreLinks {
    width: 33%;
    justify-content: space-between;
  }
  .areaContent-transparent.areaContent__left {
    padding: 0 0 0 0 !important;
    width: calc(63.333% - 14px);
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  .tallyData__container .td__text {
    font-size: 16px;
    line-height: 18px;
  }
  .content__container,
  .content__section h2 {
    max-width: calc(100% - 56px) !important;
  }
  .jurHero__section .content__container > .content__container {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    max-width: none !important;
    max-width: initial !important;
  }

  .advancedSearch__page .content__container,
  .advancedSearch__page .content__section h2 {
    margin: 0 auto;
  }

  .content__section .content__left {
    width: 55%;
  }

  .content__section .content__right {
    width: 40%;
  }

  .content__container .areaContent__section .content__container {
    max-width: 100% !important;
  }

  .area__page .mainGraphic__section {
    margin-top: 25px;
  }
  .area__page .mainGraphic__section.recs__right {
    margin-top: 0;
  }
}

@media (min-width: 1240px) {
  .hero__section h1 {
    font-size: 37px;
    font-weight: 600;
    line-height: 57px;
  }

  .hero__section {
    padding: 120px 0 160px;
  }
}

/* JUR PAGES RESPONSIVE CSS*/
@media (max-width: 768px) {
  .jurPage__searchBar {
    z-index: 12;
    position: relative;
  }
  .jurHero__section .content__container {
    padding: 0 20px;
  }
  .toTop__btn {
    z-index: 2;
  }
  .jh__countryData .jh__countryNewsContainer,
  .jh__timelineContainer,
  .jurPage__searchBar,
  .jurSearch__countryList button,
  .jh__searchSubnational,
  .jur-home__page .selectGroup__select,
  .jur-home__page .selectJur__select,
  .jps__button,
  .jurMapContainer {
    width: 100%;
  }
  .jur-profile__page .jpjs__innerArea {
    padding: 0 20px;
  }
  .jurPage__searchBar {
    margin-bottom: 24px;
  }

  .jh__countryInfo-container {
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 2;
  }

  .jh__countryInfo {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    font-size: 28px;
    line-height: 30px;
    width: calc(100% - 54px);
    position: relative;
    padding-bottom: 4px;
  }

  .jh__searchSubnational {
    margin-left: 0;
    margin-left: initial;
    margin-top: 32px;
  }

  .jur-home__page .jurHero__section {
    min-height: 632px;
    max-height: 500px;
    padding-top: 128px;
  }

  .jurSearch__section {
    padding: 24px 0 42px 0;
  }

  .jurSearch__countryList {
    max-height: 70vh;
    overflow-y: auto;
  }

  .jurSearch__section .jurSearch__header {
    flex-direction: column;
  }

  .jur-home__page .selectJur__select {
    margin-bottom: 18px;
  }

  .jur-home__page .selectGroup__select,
  .jur-home__page .selectJur__select {
    margin-right: 0;
    height: 46px;
  }

  .jur-home__page .jurSearch__header {
    margin-bottom: 24px;
    padding: 18px 18px 18px 18px;
  }

  .jps__summaryBoxes,
  .spl__container,
  .jpjs__sogiesc-columns,
  .infoSection__box {
    max-width: 100% !important;
    overflow-x: auto;
  }

  .infoSection__box > div {
    min-width: 176px;
  }

  .jpjs__titleWithBtn,
  .jps__header {
    flex-direction: column;
    margin-bottom: 32px;
  }

  .jpjs__title {
    margin-bottom: 32px;
    text-align: left;
    width: 100%;
  }

  .jps__button {
    align-items: center;
    justify-content: flex-start;
    display: flex;
  }

  .section1-jur1 {
    padding: 40px 0 60px;
  }

  .JurProfileSummary__section {
    padding: 40px 20px 60px;
  }

  .jh__countryData {
    flex-direction: column;
  }

  .jh__countryStatus {
    bottom: -14px;
  }

  .jurMapContainer {
    height: 320px;
    padding: 20px 0;
    min-width: 0 !important;
    min-width: initial !important;
    margin: 0 auto 20px;
  }

  .jh__countryNewsContainer .nav-item button {
    font-size: 13px;
    line-height: 20px;
    max-height: none;
    max-height: initial;
    padding: 20px 16px;
  }

  .jh__tab {
    padding: 32px 22px;
  }

  .jpsd__box {
    min-width: 0;
    min-width: initial;
    width: 100%;
    overflow-x: auto;
    max-width: none;
    max-width: initial;
  }

  .jpjs__innerArea-header .content__container,
  .jpjs__innerArea-body .jpjs__summaryBox {
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0;
  }

  .jpjs__summaryBox .sbox__left,
  .jpjs__summaryBox .sbox__right {
    width: 100%;
  }

  .jpia__title {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .jpsd__boxContainer {
    justify-content: space-around;
  }

  .jpssr__sb {
    max-width: 246px;
    min-width: 172px;
    padding: 22px 20px;
  }

  .jpjs__countryBox-actions {
    flex-direction: column;
  }

  .jpjs__countryBox-actions .greenBtn {
    margin: 4px 0;
  }

  .jpjss__column {
    min-width: 282px;
  }

  .jpjss__columnContent p,
  .sbox__right p {
    margin-top: 22px;
    color: #707070;
    font-size: 16px;
    line-height: 20px;
  }

  .sbox__right p:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  .accordion-collapse.show + .accordion-footer {
    height: 138px;
  }

  .spl__container {
    padding: 20px 0;
    gap: 20px;
  }

  .samePageLinks__section {
    margin: 16px 0;
  }

  .spl__container .spl__link {
    padding: 20px;
    height: 120px;
    font-size: 16px;
    line-height: 18px;
    margin-right: 4px;
    width: 160px;
    min-width: 160px;
  }

  .spl__container .spl__link span {
    font-size: 12px;
  }

  .jh__countryData .jh__countryNewsContainer {
    margin-bottom: 20px;
  }

  .jh__timelineList {
    padding: 20px;
    margin-bottom: 0;
  }

  .jh__timelineContainer h5 {
    line-height: 32px;
    margin-bottom: 0;
    padding-top: 20px;
  }

  .jps__title {
    align-self: flex-start;
    margin-bottom: 20px;
  }

  .jps__body,
  .jps__discriminationSection {
    margin: 32px 0 0;
  }

  .jps__sb {
    padding: 20px;
    height: auto;
    height: initial;
    min-height: 0;
    min-height: initial;
    margin-right: 4px;
    min-width: 224px;
  }

  .jps__sb p {
    margin-bottom: 0;
  }

  .jps__summaryBoxes .jps__header,
  .jps__sb p span {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
    text-align: left;
    flex-direction: row;
    align-items: baseline;
  }

  .jpsdsc__status {
    padding: 0 14px;
  }

  .jurProfile__area3 {
    flex-direction: column;
  }

  .jurProfile__area3 > div {
    padding: 30px 20px;
  }

  .jurProfile__area3 .tb__recsContainer,
  .jurProfile__area3 .tb__pieChart-container,
  .jurProfile__area3 .a2ts__body .content__container,
  .jurProfile__area3 .table__section .content__container {
    width: 100%;
    max-width: none !important;
    max-width: initial !important;
  }

  .jurProfile__area3 .a2ts__body .content__container {
    margin: 0 0 0 0;
  }

  .jurProfile__area3 .tb__recBoxContainer {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .jurProfile__area3 .tb__recBoxContainer .recBox {
    min-width: 240px;
    min-height: 120px;
    margin-right: 4px;
  }

  .jurProfile__area3 > .content__container {
    padding: 30px 0px;
  }

  .jurProfile__area3 .jpjs__tableTabBtns {
    flex-direction: column;
  }

  .jurProfile__area3 .jpjs__tableTabBtns .jpjs__tableTabBtn {
    width: 100%;
    margin: 0 0 12px 0;
    max-width: none;
    max-width: initial;
    font-size: 22px;
    line-height: 22px;
  }

  .jurProfile__area3 .tb__recs-title {
    padding: 0 0 0 0;
  }

  .jurProfile__area3 .table__section .content__container {
    margin: 0 0 0 0;
  }

  .jur__page .table__section {
    padding-bottom: 0;
  }
  .jur__page .section1-jur1 .table__section {
    padding: 0 0 0 0;
  }
  .section1-jur1 .jpjs__titleWithBtn,
  .section1-jur1 .jps__header {
    margin-bottom: 28px;
  }

  .jpjs__tableTabBtns {
    flex-direction: column;
    align-items: center;
  }

  .jpjs__tableTabBtns .jpjs__tableTabBtn {
    padding: 20px 24px;
    font-size: 24px;
    line-height: 24px;
    margin: 10px 0;
    max-width: none;
    max-width: initial;
    width: 100%;
    flex-grow: 1;
  }
  .selectCategory__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
  .area3__page .selectCategory__container .selectCategory {
    flex-grow: 1;
    max-width: none;
    max-width: initial;
    min-width: 70px;
  }
}

@media (max-width: 1024px) {
  .jur__page table th,
  .jur__page table td {
    min-width: 140px;
  }
  .tb__pieChart-actualContainer {
    position: relative;
  }
  .tb__recsContainer.tb__recsContainer4 {
    margin: 28px 0 0 0;
  }
  .tb__pieChart-actualContainer .chartContainer {
    max-width: 100%;
    width: 100%;
  }
  .tb__pieChart-actualContainer .chartContainer #gaugeChartdiv {
    width: 100% !important;
  }
  .section1-jur1.jurProfile__area3 {
    padding-top: 28px;
  }
  .jurProfile__area3 {
    padding: 0 28px;
  }
  .samePageLinks__section .content__container {
    padding: 0 28px;
  }
  .jpjs__innerArea {
    padding: 4px 28px 0;
  }
  .section__jurs .content__container {
    padding: 0 28px;
  }
  .section1-jur1 > .content__container {
    padding: 0 0 0 0;
  }
  .section1-jur1 .table__section > .content__container {
    margin: 0 0 0 0;
    width: 100%;
    max-width: none !important;
    max-width: initial !important;
  }
  .jpjs__innerArea-header {
    padding: 0 0 0 0;
  }
  .jpjs__innerArea-header .content__container {
    align-items: flex-start;
  }
}

@media (min-width: 769px) and (max-width: 1140px) {
  .area3__page .selectRegion__inputs-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .area3__page .selectCategory__container_fullwidth {
    width: 100%;
    justify-content: flex-start;
    margin-top: 4px;
  }
  .jh__countryData {
    flex-wrap: wrap;
    justify-content: center;
  }

  .jh__countryInfo-container {
    font-size: 26px;
    line-height: 28px;
    padding-bottom: 12px;
  }

  .jh__searchSubnational {
    width: 280px;
    margin-left: auto;
  }

  .jurProfile__area3 .tb__recsContainer {
    width: 100%;
    max-width: none;
    max-width: initial;
  }

  .spl__container,
  .jps__summaryBoxes,
  .jpjs__sogiesc-columns {
    width: 100%;
    overflow-x: auto;
    padding: 20px 0;
  }

  .spl__container {
    padding: 20px 20px;
  }

  .spl__container .spl__link {
    width: 240px;
    min-width: 240px;
    margin-right: 4px;
  }

  .jps__sb,
  .jpssr__sb {
    width: 240px;
    min-width: 240px;
    margin-right: 4px;
  }

  .jps__discriminationSection {
    justify-content: space-between;
  }

  .jps__discriminationSection .jpsd__box {
    width: 49%;
  }

  .jpsd__stripeColumn {
    width: 25%;
  }

  .jpjss__column {
    min-width: 282px;
  }

  .jurProfile__area3 .tb__barChart-container {
    width: calc(60% - 18px);
  }

  .footer__siteMap {
    padding: 40px 30px;
  }

  .jurProfile__area3.jurProfile__area6 .tb__barChart-container,
  .jurProfile__area3.jurProfile__area6 .tb__pieChart-container {
    width: 100%;
    max-width: none;
    max-width: initial;
  }

  .jurProfile__area3 .area2Table__section .content__container {
    width: 100%;
    max-width: 100%;
  }
  .menu__modal .modal-dialog {
    max-width: 75%;
  }
}

@media (min-width: 1025px) and (max-width: 1441px) {
  .content__container.jurProfile__area2 {
    padding: 0 0 0 0;
  }
  .samePageLinks__section > .content__container {
    padding: 0 0 0 0;
  }
  .area__page .selectRegion__section,
  .area3__page .selectRegion__section,
  .area4__page .selectRegion__section {
    min-height: 794px;
  }

  .jur-home__page .jurHero__section {
    min-height: 720px;
  }

  #chartdiv {
    width: 100%;
    height: 480px;
    min-height: 480px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .area5__page .mapContent__container {
    padding-bottom: 18px !important;
  }
  .area5__page.map__alternate .mapContent__container {
    padding-bottom: 22px;
  }
  .area__page #loading {
    position: relative;
    top: 16px;
    width: 100%;

  }
}

@media (min-width: 1025px) {
  .section1-jur1 .table__section > .content__container {
    padding: 0 0 28px 0;
  }
}

@media (min-width: 768px) {
  .page__home .hero__section {
    margin-top: 200px;
    height: 466px;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .page__home .hero__section {
    margin-top: 180px;
  }
}