.mapControls__container {
  position: absolute;
  top: 4px;
  left: auto;
  left: initial;
  right: 4px;
  height: 110px;
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.mapControls__container button {
  background-color: #f4f8f8;
  border: medium none currentColor;
  border: initial;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.053) !important;
  padding: 0 0 0 0;
  min-width: 32px;
  width: 32px;
  height: 32px;
  color: #A4CAD1;
  font-weight: 600;
  text-transform: uppercase;
  transition: color 225ms, background-color 225ms;
  margin-top: 4px;
}
.section__jurs .mapControls__container button {
  color: #649364;
}
.mapControls__container button:first-child {
  margin-top: 0;
}
.mapControls__container button:hover {
  color: #6EA2B5;
  background-color: #fff;
}
.section__jurs .mapControls__container button:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}
