.asai__modal .modal-dialog {
  max-width: 1170px;
}
.asai__modal .modal-content {
  border-radius: 0;
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
  background-color: #eeecf5;
  padding: 20px;
}
.asai__modal .modal-header {
  border-bottom: 0;
  border-bottom: initial;
  padding: 0px 10px;
}
.asai__modal .modal-body {
  padding: 16px 10px 18px;
  display: flex;
  justify-content: space-between;
}
.asai__modal-body h6 {
  color: #8a79ba;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}
.asai__headerBtn {
  background-color: #d8d5e3;
  color: #707070;
  margin-bottom: 0;
  border: medium none currentColor;
  border: initial;
  height: 25px;
  line-height: 25px;
  margin-left: 6px;
  min-width: 95px;
  transition: background-color 324ms;
}
.asai__headerBtn:hover {
  background-color: #e8e7ea;
}
.asaimb__box-right,
.asaimb__box-left {
  background: #ffffff;
  padding: 28px 4px 28px 30px;
  flex-grow: 1;
  height: 420px;
}
.asaimb__box-left {
  max-width: 420px;
  min-width: 420px;
  margin-right: 14px;
  min-height: 280px;
}
.asaimbr__header {
  display: flex;
  justify-content: space-between;
}
.asaimbr__body {
  margin-bottom: 0;
  max-height: 310px;
  overflow-y: auto;
}
/* width */

.asaimbr__body::-webkit-scrollbar {
  width: 11px;
}
/* Track */

.asaimbr__body::-webkit-scrollbar-track {
  background: #ffffff;
}
/* Handle */

.asaimbr__body::-webkit-scrollbar-thumb {
  background: #eeecf5;
  border-radius: 8px;
  width: 11px;
}
/* Handle on hover */

.asaimbr__body::-webkit-scrollbar-thumb:hover {
  background: #e3e3e3;
}
.asaimbr__body p {
  font-size: 16px;
  line-height: 16px;
}
.asai__areaItems li {
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  color: #000;
}
.asai__areaItems li:last-child {
  margin-bottom: 0;
}
.asai__areaItems li.active {
  font-weight: 700;
}
.asai__areaItems svg {
  position: absolute;
  right: -50;
  bottom: 10px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.asai__modal .modal-footer {
  border-top: 0;
  border-top: initial;
  padding: 0 10px 6px 60px;
}
.advancedSearch__page .asai__modal-footer.acp__actionBar button {
  margin: 0 0 0 0;
  min-width: 126px;
  margin-left: 20px;
}

.advancedSearch__page .asai__modal-footer.acp__actionBar button.clear {
  background-color: #ada1ce;
  border: #ada1ce 1px solid;
}

@media (max-width: 768px) {
  .asai__modal {
    padding-top: 0;
  }
  .asai__modal .modal-body {
    padding: 18px 22px 18px;
    flex-direction: column;
  }
  .asai__modal .asaimb__box-right,
  .asai__modal .asaimb__box-left {
    margin: 0 0 24px 0;
    max-width: none;
    max-width: initial;
    width: 100%;
  }
  .asaimbr__header {
    flex-direction: column;
    margin-bottom: 24px;
  }
  .asaimbr__header h6 {
    margin-bottom: 16px;
  }
  .advancedSearch__page .dd-filter input {
    margin-right: 0;
  }
  .filterModal__header .mcs__toggle {
    padding: 12px 0 0;
  }
}
