.switch__container {
    position: relative;
}
/* The switch - the box around the slider */
.switch__container .switch {
    position: relative;
    width: 36px;
    height: 19px;
    margin: 0 auto 4px;
    display: block;
}

/* Hide default HTML checkbox */
.switch__container .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.switch__container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #A4CAD1;
    transition: .4s;
}

.switch__container .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
}

.switch__container input:checked + .slider {
    background-color: #6EA2B5;
}

.switch__container input:focus + .slider {
    box-shadow: 0 0 1px #6EA2B5;
}

.switch__container input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.switch__container .slider.round {
    border-radius: 34px;
}

.switch__container .slider.round:before {
    border-radius: 50%;
}

