.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']:before {
  content: 'Title 1';
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']:before {
  content: 'Subtitle 1';
  font-size: 18px;
  padding-top: 15px;
  color: #528598;
  font-style: italic;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']:before {
  content: 'Subtitle 2 (indent)';
  font-size: 16px;
  padding-top: 15px;
  color: #528598;
  font-style: italic;
  margin-left: 20px;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']:before,
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']:before {
  content: 'Smallprint';
}
.quill{
  margin-bottom: 20px;
  height: 450px;
}
.ql-snow p {
  margin-bottom: 12px;
}

