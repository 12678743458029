.moreBtn {
    width: 100%;
    text-align: center;
    height: 40px;
    background: #ffffff;
    color: #6EA2B5;
    font-size: 16px;
    border-radius: 0;
    align-self: flex-end;
    justify-self: flex-end !important;
    margin-top: auto;
}