
/* Add Jurisdiction Button css */
.jurSelect__modal .modal-content {
    background-color: #FFFFFF;
    border-radius: 0 0 0 0;
}
.jurSelect__modal .modal-body {
    padding: 0 0 0 0;
}
.jurSelect__modal .jurSelect__modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.jurSelect__modal-header .dd-searchInput {
    font-size: 12px;
    min-width: 240px;
    border: 0;
    width: 100%;
    height: 34px;
    background-color: #8A79BA;
    padding: 3px 10px;
    letter-spacing: 0.33px;
    color: #ffffff;
}
.dd-searchInput:focus {
    box-shadow: none;
    box-shadow: initial;
    border-color: transparent;
    outline: none;
}
.jurSelect__modal-header .dd-searchInput::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
    opacity: 1; /* Firefox */
}
.jurSelect__modal-header .dd-searchInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
    opacity: 1; /* Firefox */
}

.jurSelect__modal-header .dd-searchInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ffffff;
}

.jurSelect__modal-header .dd-searchInput::-ms-input-placeholder { /* Microsoft Edge */
    color: #ffffff;
}
/* end of Add Jurisdiction Button css */
.jurSelect__modal-header {
    font-weight: bold;
}
.dd-wrapper .dd-list {
    padding: 0;
    margin: 0;
    width: 100%;
    left: 0px;
    max-height: 400px;
    overflow: auto;
}
.dd-wrapper .dd-list li {
    list-style-type: none;
}
.dd-wrapper .dd-list li:first-of-type > button {
    border-top: 1px solid #ccc;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.dd-wrapper .dd-list li:last-of-type > button {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.dd-wrapper .dd-list li button {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    font-size: small;
    padding: 0px 10px 0px 10px;
    border: 0;

    width: 100%;
    text-align: left;
}

.dd-wrapper .dd-list .dd-filter {
    display: flex;
    justify-content: space-between;
    background-color: white;
    font-size: small;
    padding: 5px 5px 5px 5px;
    border: 0;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #ccc;
}

.dd-wrapper .dd-list .dd-list-item input[type="checkbox"] {
    vertical-align: bottom;
}
.dd-wrapper .dd-list li button:hover {
    cursor: pointer;
    background-color: #cbe2e6;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
}
.dropdown:hover .dropdown-content {
    display: block;
}
.dd-filter input {
    padding: 3px;
    width: 100%;
    height: 34px;
    background-color: #cbe2e6;
    border-top: 0 !important;
    border-top: initial !important;
}
.dd-clear-filter {
    border: 0px;
    padding-bottom: 8px;
    width: 100%;
    height: 34px;
    background-color: transparent;
    font-size: 12px;
    color: #676e6f;
}

.jurSelect__modal .accordionsContainer {
    margin-top: 0;
}
.jurSelect__modal .accordion-body {
    max-height: 370px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    padding: 12px;
}
.jurSelect__modal .accordion-body::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border: 1px solid black;
}
.jurSelect__modal .accordion-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.jurSelect__modal .accordion-body::-webkit-scrollbar-thumb {
    background: #b9c1c3;
    border-radius: 5px;
}
.jurSelect__modal .accordion-body::-webkit-scrollbar-thumb:hover {
    background: #93999a;
    border-radius: 5px;
}
.jurSelect__modal .accordion-item {
    border: none;
}
.jurSelect__modal .dd-list-item input[type="checkbox"] {
    color: #dbedef;
    border-color: #dbedef;
    width: 14px;
    height: 14px;
    margin-right: 13px;
}
.jurSelect__modal .dd-list-item button {
    border: medium none currentColor;
    border: initial;
    background: #F9F7FF;
    padding: 0;
    width: 100%;
}
.jurSelect__modal .dd-list-item button > span {
    display: flex;
    justify-content: flex-start;
    min-width: 240px;
    font-size: 15px;
    line-height: 22px;
    align-items: center;
}
.jurSelect__modal .dd-list-item button img {
    margin-right: 5px;
}
.jurSelect__modal .accordion-item:first-child {
    border-bottom: 0;
    border-bottom: initial;
}
.jurSelect__modal .accordion-button {
    background-color: #FFFFFF;
    box-shadow: none;
    border-bottom: 1px solid #EEECF5 !important;
}
.jurSelect__modal .accordion-button h5 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: #8A79BA;
    text-transform: uppercase;
    margin-bottom: 0;
}
.advancedSearch__page.jurSelect__modal .accordion-button:not(.collapsed)::after,
.advancedSearch__page.jurSelect__modal .accordion-button::after {
    right: 16px;
    top: 13px;
}
.jurSelect__modal .dd-list-item.WhatItem button {
    background: #F9F7FF !important;
    border-top: 0 !important;
    border-top: initial !important;
}
.advancedSearch__page.jurSelect__modal  .accordion {
    margin-top: 0;
}
/* CUSTOM CHECKBOX STYLES */
.jurSelect__modal .check-container {
    background-color: #fff;
}
.jurSelect__modal .check-container label {
    width: 100%;
    color: #000;
    background-color: #FFFFFF;
}
.jurSelect__modal .check-container label:hover {
    background-color: #F9F7FF;
}
.jurSelect__modal .check-container label:before {
    background-color: #F9F7FF;
}
.jurSelect__modal .check-container label:after {
    border: 2px solid #8A79BA;
    background-color: #F9F7FF;
}
.jurSelect__modal .check-container input:checked ~ label:after {
    background-color: #F9F7FF;
    border-color: #8A79BA;
}
.jurSelect__modal .accordion-body::-webkit-scrollbar {
    width: 6px;
}
.jurSelect__modal .accordion-body::-webkit-scrollbar-track {
    background: #F9F7FF;
}
.jurSelect__modal .accordion-body::-webkit-scrollbar-thumb {
    background: #dfd6f9;
    border-radius: 8px;
    width: 11px;
}
.jurSelect__modal .accordion-body::-webkit-scrollbar-thumb:hover {
    background: #8A79BA;
}

@media (max-width: 768px) {
    .accordion-button:not(.collapsed)::after,
    .accordion-button::after {
        top: 0;
    }
}
