.methodBtn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  height: 10px 20px 10px 20px;
  position: relative;
  margin-top: 38px;
  padding: 7px 20px;
}
.methodBtn__text,
.methodBtn svg {
  color: #5c8f95;
  font-size: 16px;
}
.methodBtn svg {
  font-size: 35px !important;
  width: 24px !important;
  height: 24px !important;
  position: absolute;
  right: 10px;
  bottom: 5px;
}
.methodologyModal .modal-content {
  border: medium none currentColor;
  border: initial;
  border-radius: 0;
}
.methodologyModal .btn-close {
  position: absolute;
  top: 28px;
  right: 28px;
}
.methodologyModal .modal-header {
  min-height: 120px;
  justify-content: flex-start;
  align-items: flex-end;
  background: #e9eff0;
  width: 100%;
  padding: 20px 60px;
  border-bottom: 0;
  border-bottom: initial;
}
.methodologyModal .modal-body {
  padding: 40px 60px;
}
.methodologyActions__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9eff0;
  border-top: 1px solid #e9eff0;
  padding: 8px 0;
}
.methodologyActions__container h4 {
  margin-bottom: 0;
  font-size: 24px;
  color: #6ea2b5 !important;
}
.methodologyActions__container .btn-link {
  text-decoration: none;
  text-decoration: initial;
  color: #6ea2b5;
}
.methodologyActions__container .btn-link:hover {
  text-decoration: underline;
}
.methodologyActions__container .btn-link svg path {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}
.methodologyModal__content {
  padding: 12px 0;
}
.methodologyModal__content h5 {
  font-size: 20px;
  line-height: 22px;
  color: #6ea2b5;
  margin-bottom: 18px;
  margin-top: 22px;
}
.methodologyModal__content p {
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: 1240px) {
  .methodologyModal .modal-lg {
    max-width: 1040px;
  }
}

.methodologyModal__content li::marker {
  font-size: 14px;
}

.methodologyModal__content li p {
  margin-left: 10px;
}

.methodologyModal__content h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 12px;
  color: #528598;
}

.methodologyModal__content h5 {
  font: normal normal bold 25px/28px Lato;
  font-size: 18px;
  line-height: 20px;
  color: #528598;
  margin-bottom: 18px;
}

.methodologyModal__content ul {
  list-style: circle outside;
  width: 100px;
}

.methodologyModal__content li {
  padding-left: 20px;
}

.methodologyModal__content p {
  font-size: 16px;
  line-height: 18px;
  color: #575757;
  margin-bottom: 18px;
  margin-top: 10px;
}

.methodologyModal__content li {
  font-size: 16px;
  line-height: 18px;
  color: #575757;
  margin-bottom: 18px;
  margin-top: 10px;
}

.methodologyModal__content .subtitle {
  font-size: 18px;
  padding-top: 15px;
  color: #528598;
  font-style: italic;
}
.methodologyModal__content h2 {
  font-size: 18px;
  padding-top: 15px;
  color: #528598;
  font-style: italic;
}
.methodologyModal__content span.subsubtitle {
  font-size: 16px;
  padding-top: 15px;
  color: #528598;
  font-style: italic;
  margin-left: 20px;
}

.methodologyModal__content h3 {
  font-size: 16px;
  padding-top: 15px;
  color: #528598;
  font-style: italic;
  margin-left: 20px;
}
.methodologyModal__content p.indent20px {
  margin-left: 20px;
}

.methodologyModal__content p.ql-indent-1 {
  margin-left: 20px;
}

.methodologyModal__content p.ql-indent-2 {
  margin-left: 40px;
}

.methodologyModal__content p.smallPrint {
  font-size: 11px;
  line-height: 12px;
}
