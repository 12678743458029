.mgMentions__container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    width: 340px;
    margin-left: 42px;
    max-height: 600px;
    overflow-y: auto;
}
.mgMention__item-container {
    width: 94px;
    display: flex;
    margin-left: 18px;
    justify-content: space-between;
}
.mgMention__item-container span {
    font-size: 10px;
    line-height: 22px;
    color: #575757;
    display: block;
    height: 100%;
}
.mgMention__item-container span:last-child {
    background: #F2F2F2;
    text-align: center;
    margin-left: 5px;
    width: 36px;
}
.mgMention__item-container span.isZero:last-child {
    color: #BFBFBF;
}
.mgMention__item-container:nth-child(3n+1) {
    margin-left: 0;
}


.mgMentions__container::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border: 1px solid black;
}
.mgMentions__container::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.mgMentions__container::-webkit-scrollbar-thumb {
    background: #b9c1c3;
    border-radius: 5px;
}
.mgMentions__container::-webkit-scrollbar-thumb:hover {
    background: #93999a;
    border-radius: 5px;
}