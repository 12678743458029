.mgContent__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mgMap__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  margin-top: 40px;
}
.mapGenerator__page .areaContent__header {
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.mapGenerator__page .areaContent__header .acp__title {
  font-size: 18px;
  font-weight: 900;
  line-height: 34px;
}
.mapGenerator__page .content__container > p {
  width: 100%;
  max-width: 625px;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  margin-bottom: 24px;
}
.mapGenerator__page .mgMap__header .comboLogoContainer {
  display: flex;
  align-items: center;
  position: absolute;
  right: -11px;
  top: 34px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
.mgMap__title-container {
  display: flex;
}
.mgMap__text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.grayBar {
  width: 16px;
  height: 100%;
  background-color: #f2f2f2;
  margin-right: 8px;
}
.mgMap__title {
  font-size: 20px;
  line-height: 31px;
  margin-bottom: 0;
  font-weight: 600;
}
.mgMap__subtitle {
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 0;
}
.mgMapSection__container {
  width: calc(100% - 218px);
  max-height: 760px;
  background-color: #ffffff;
  padding: 14px 42px 0;
  border: 5px dashed #eeecf5;
  position: relative;
  margin-bottom: 14px;
}
.mgMap__container {
  position: relative;
}
.mgMapSection__container p {
  position: absolute;

  bottom: 0px;
  font-size: 8px;
  line-height: 8px;
}
.mgMap__infoBubble {
  position: absolute;
  width: 314px;
  height: 92px;
  background-color: #eeecf5;
  top: -114px;
  left: calc(50% - 100px);
  padding: 20px;
  font-size: 14px;
  line-height: 16px;
}

.mgMap__infoBubble .arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #eeecf5;
  position: absolute;
  bottom: -9px;
  left: calc(50% - 5px);
}

.section__tools.mapGenerator__page .comboLogoContainer .ilgaDatabase path {
  fill: #a5a4a4;
}

.mapPlaceholder {
  height: 600px;
  border: 1px solid #d1d1d1;
}

.mapPlaceholder .content__container.mapContent__container {
  height: 100%;
  background-color: #d8e3e5;
}

.mapPlaceholder .content__container.mapContent__container > div:first-child {
  top: 0px;
  height: 520px;
}

.mapPlaceholder .content__container.mapContent__container #chartdiv {
  height: 520px;
  min-height: 520px;
}

.mapPlaceholder .mapLegend {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}

.mgmsf__sidebar {
  width: 208px;
  display: flex;
  justify-content: stretch;
  padding: 24px 30px;
  background-color: #eeecf5;
  margin-bottom: 120px;
  align-items: center;
}
.mgmsf__exportBtn {
  flex-grow: 1;
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
  background-color: #a8a0c1;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  transition: background-color 324ms;
  width: 160px;
  height: 63px;
}
.mgmsf__exportBtn:hover,
.mgmsf__exportBtn:focus,
.mgmsf__exportBtn:active {
  background-color: #8a79ba;
}
.mapGenerator__page.mapGenerator__page .acp__formContainer .acp__formControl {
  margin-bottom: 0;
  width: 390px;
}

.mapGenerator__page.mapGenerator__page
  .acp__formContainer
  .mg__formControl
  .acp__formControl {
  margin-bottom: 0;
  width: 450px;
}

.advancedSearch__page
  .acp__formContainer
  .mg__formControl
  .acp__formControl
  label {
  min-width: 60px;
  width: 60px;
}

.mapGenerator__page.mapGenerator__page .acp__formContainer {
  padding: 40px 50px;
}

.mapGenerator__page .switch__container .slider {
  background-color: #c4bfd5;
}
.mapGenerator__page .switch__container input:checked + .slider {
  background-color: #a8a0c1;
}

.mapGenerator__page .switch__container input:focus + .slider {
  box-shadow: 0 0 1px #a8a0c1;
}
.section__tools .mgContent__container .comboLogoContainer .ilgaDatabase path {
  fill: #a5a4a4;
}

.mapGenerator__page .switch__container.disabled .slider {
  background-color: #ccc;
}

.mapGenerator__page .switch__container.disabled {
  color: #ccc;
}

/* sidebar */
.mgMapSection__sidebar {
  max-width: 220px;
  background-color: #eeecf5;
  padding: 6px 22px 0px 22px;
  margin-bottom: 14px;
}
.mgMapSection__sidebar .mcs__item {
  flex-direction: column;
  background-color: transparent;
  height: auto;
  height: initial;
  margin-top: 6px;
}
.mgMapSection__sidebar .mcs__item.exportFormatPicker {
  margin-top: 15px;
}
.mgMapSection__sidebar .mcs__item.mcs__hasBoxes {
  margin-bottom: 12px;
}
.mgMapSection__sidebar .mcs__itemTitle {
  align-self: baseline;
  color: #707070;
  font-size: 14px;
  line-height: 22px;
  padding: 0 0 0 0;
  margin: 0 0 10px 0;
}
.mgMapSection__sidebar .mcs__itemTitle.mcit__projection {
  margin-bottom: 2px;
}
.mgMapSection__sidebar .mcs__toggle {
  padding: 0 0 0 0;
}
.mgMapSection__sidebar .buttonPurpleToggle {
  height: 34px;
  border-color: transparent;
  margin: 0 0 0 0;
}
.mgMapSection__sidebar .buttonPurpleToggle .knobs:before {
  background-color: #a8a0c1;
  border-radius: 0;
  border: 2px solid #a8a0c1;
}
.mgMapSection__sidebar .buttonPurpleToggle .knobs:before,
.mgMapSection__sidebar .buttonPurpleToggle .knobs span {
  left: 0;
  top: 0;
  height: 30px;
  width: 80px;
  border: 2px solid #a8a0c1;
  border-radius: 0;
  padding: 0px 6px;
  line-height: 28px;
}
.mgMapSection__sidebar .buttonPurpleToggle .knobs:after {
  right: 0;
  top: 0;
  height: 30px;
  border: 2px solid #a8a0c1;
  border-radius: 0;
  width: 80px;
  padding: 0px 6px;
  line-height: 28px;
}
.mgMapSection__sidebar .buttonMicrostates .checkbox:checked + .knobs:before,
.mgMapSection__sidebar .buttonPurpleToggle .checkbox:checked + .knobs:before {
  left: 76px;
  border-radius: 0;
}

/* GENERATE TOGGLES OFF STATE*/
.mgMapSection__sidebar .buttonCenterMap.buttonCN .knobs:after {
  content: 'Hide';
}
.mgMapSection__sidebar .buttonCenterMap.buttonCN .knobs-es:after {
  content: 'Ocultar';
}
.mgMapSection__sidebar .buttonCenterMap.buttonQ .knobs:after {
  content: 'SQ';
}
.mgMapSection__sidebar .buttonCenterMap.buttonMS .knobs:after {
  content: 'Actual Size';
}
.mgMapSection__sidebar .buttonCenterMap.buttonMS .knobs-es:after {
  content: 'Tamaño real';
}
.mgMapSection__sidebar .smallGrayBoxes {
  display: flex;
  justify-content: flex-start;
  padding-left: 2px;
  margin-bottom: 8px;
}
.mgMapSection__sidebar .smallGrayBoxes span {
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: #707070;
  background-color: #d4d0e3;
  width: 40px;
  height: 26px;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* dropdown */
.purpleDropdown__container {
  position: relative;
}
.purpleDropdown__trigger {
  background-color: #a8a0c1;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
  width: 100%;
  padding: 6px;
  height: 28px;
}
.purpleDropdown__trigger:after {
  position: absolute;
  content: '';
  border-left: 3px solid #ffffff;
  border-top: 3px solid #ffffff;
  padding: 5px;
  right: 6px;
  top: 6px;
  -webkit-transform: rotate(-135deg) scale(0.475);
          transform: rotate(-135deg) scale(0.475);
}
.purpleDropdown__items {
  background-color: #e6e2f0;
  height: 0;
  overflow: hidden;
  padding: 0 0 0 0;
  position: absolute;
  z-index: 12;
  left: 0;
  right: 0;
  transition: height 324ms, padding 324ms;
}
.purpleDropdown__items.open {
  height: 98px;
  padding: 6px 20px 2px;
  overflow-y: auto;
}

.acp__formContainer .purpleDropdown__items.open {
  height: 132px;
  padding: 6px 0px 2px 20px;
  overflow-y: auto;
  background-color: #fff;
}

.acp__formContainer .purpleDropdown__trigger {
  background-color: #fff;
  color: #575757;
}

.purpleDropdown__item {
  font-size: 12px;
  line-height: 12px;
  color: #575757;
  cursor: pointer;
  padding: 3px 12px;
  margin-bottom: 4px;
}
.purpleDropdown__item:hover {
  background-color: #d4d0e3;
}

/* FOOTER STYLES */

.mgMapSection__footer {
  width: calc(100% - 218px);
  margin-bottom: 120px;
}
.mapGenerator__page
  .mgMapSection__footer
  .acp__formContainer
  .acp__formControl
  label {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}

.mapGenerator__switch {
  width: 132px;
  margin-left: 48px;
  text-align: center;
}
.mgMapSection__footer .mgFooter__selectCategory {
  display: flex;
  align-items: center;
  margin-top: 24px;
  width: calc(100% - 175px);
}
.mgMapSection__footer .mgFooter__row2 .mgFooter__selectCategory {
  margin-top: 0;
}
.mgMapSection__footer .mgFooter__row2 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  width: 100%;
  margin-top: 20px;
}
.mgMapSection__footer .mgFooter__row2 .mapGenerator__switch {
  top: -10px;
}
.mgMapSection__footer .mgFooter__label {
  width: 132px;
  text-align: right;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  font-weight: 600;
  position: relative;
  left: -12px;
  margin-bottom: 0;
}
.mgMapSection__footer .selectCategory__container {
  width: 83%;
  flex-grow: 0;
}
.mgMapSection__footer .selectCategory__container label {
  font-size: 11px;
  line-height: 13px;
}
.mgMapSection__footer .selectCategory__container input + label {
  background: #eeecf5;
  color: #a5a4a4;
  padding: 0 24px;
  text-align: center;
  line-height: 32px;
}
.mgMapSection__footer.mgArea-6 .selectCategory__container input + label {
  padding: 0 6px;
}

.mgMapSection__footer .selectCategory__container input:checked + label {
  background: #d8d5e3;
  color: #000;
}

.mgMapSplit__container {
  display: flex;
}
.mgMapSplit__container .mapPlaceholder {
  flex-grow: 1;
}
.mgMapSection__footer .selectCategory__container-small {
  width: 376px;
  left: -2px;
  position: relative;
}
.mgMapSection__footer .infoIcon {
  position: absolute;
  right: -18px;
  bottom: 6px;
  cursor: pointer;
}
.mgMapSection__footer .infoIcon g {
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0.16);
          transform: scale(0.16);
  fill: #a8a0c1;
}

.mapGenerator__page .mgMapSection__footer .reactSelect > div {
  font-size: 13px;
  line-height: 13px;
}

.noDissagregatedData .disaggregatedSwitch {
  visibility: hidden;
}

.mgMapSplitVertical__container .mapPlaceholder {
  height: 476px;
}
.mgMapSplitVertical__container .cbMap__container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 150px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 12px;
  padding-bottom: 4px;
}

/* CB MAP content styles */
.cbMap__container .cbMap__item {
  height: 20px;
  font-size: 9px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 118px;
  margin-right: 18px;
}
.cbMap__item-name {
  border-bottom: 1px solid #f2f2f2;
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
}
.cbMap__item:nth-child(n + 5) .cbMap__item-name,
.cbMap__item:first-child .cbMap__item-name {
  border-top: 1px solid #f2f2f2;
}
.cbMap__item .cbMap__item-badge {
  background-color: #f2f2f2;
  flex-grow: 1;
  margin-left: 3px;
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 6px 0 4px;
  width: 50px;
}
.cbMap__item .cbMap__item-badge.greyedOut {
  color: #bfbfbf;
}
.cbMap__item .cbMap__item-badge .catCircle {
  margin-right: 4px;
  width: 12px;
  height: 12px;
}
.cbMap__item .cbMap__item-badge .catCircle.cat__blue + span {
  font-weight: 600;
}
.cbMap__item .cbMap__item-badge .catCircle.cat__yellow {
  color: #ffffff;
}

/* CB MAP custom scroll */
.cbMap__container::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  border: 1px solid black;
}
.cbMap__container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.cbMap__container::-webkit-scrollbar-thumb {
  background: #b9c1c3;
  border-radius: 5px;
}
.cbMap__container::-webkit-scrollbar-thumb:hover {
  background: #93999a;
  border-radius: 5px;
}

.mapGenerator__page .acp__formControl .check-container label {
  background-color: #eeecf5;
  width: 100%;
  padding: 8px 12px 5px 28px;
}
.mapGenerator__page .acp__formControl .check-container label:hover,
.mapGenerator__page .acp__formControl .check-container label:hover:before {
  background-color: #f9f7ff;
}
.mapGenerator__page .acp__formControl .check-container label:before {
  background-color: #eeecf5;
}
.mapGenerator__page .check-container label:after {
  content: '';
  border-color: #8a79ba !important;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%238A79BA' fill-rule='nonzero'/%3E%3C/svg%3E") !important;
}

.mapGenerator__page
  .acp__formControl
  .check-container
  input:checked
  ~ label:after {
  background-color: #eeecf5;
  border: 2px solid #8a79ba !important;
}
.dropdown-check-list {
  display: inline-block;
  position: relative;
  z-index: 22;
  flex-grow: 1;
  background: #eeecf5;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: block;
  padding: 10px 50px 5px 10px;
  border: none;
  min-height: 38px;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
}

.dropdown-check-list .anchor.noCuTypes {
  background-color: #eaeaea;
}

.dropdown-check-list .anchor:after,
.mapGenerator__page .acp__formContainer .advancedSearch__areasBtn:after {
  position: absolute;
  content: '';
  border-left: 3px solid #707070;
  border-top: 3px solid #707070;
  padding: 5px;
  right: 13px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg) scale(0.475);
}
.mapGenerator__page .acp__formContainer .advancedSearch__areasBtn {
  position: relative;
  flex-grow: 1;
  border: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 38px;
  align-items: center;
  padding-left: 12px;
  padding-right: 28px;
}
.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  display: none;
  margin: 6px 0 0 0;
  border: medium none currentColor;
  border: initial;
  background-color: #eeecf5;
  position: absolute;
  width: 100%;
  padding: 20px 25px;
  max-height: 400px;
  overflow-y: scroll;
}

.dropdown-check-list ul.items li {
  list-style: none;
}
.dropdown-check-list.visible .items {
  display: block;
}

.mapGenerator__page .dd-wrapper.dd-wrapperjur {
  background: #eeecf5;
}

.mapGenerator__page .dd-wrapper .accordion-button {
  background: #eeecf5;
}

.mapGenerator__page .dd-wrapper.dd-wrapperjur {
  background: #eeecf5;
}

.mapGenerator__page .dd-wrapper .accordion-body {
  max-height: 370px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: #eeecf5;
  padding-left: 12px;
}
.mapGenerator__page .check-container {
  background-color: #eeecf5;
}
