.exportFormatPicker {
    max-width: 166px;
}
.exportFormatPicker .mcs__itemTitle {
    margin-bottom: 4px;
}
.exportFormat__item {
    background-color: transparent;
    border: 2px solid #A8A0C1;
    color: #A8A0C1;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;
    box-shadow: initial;
    margin-bottom: 9px;
    font-size: 12px;
    width: 100% ;
    text-align: left;
    transition: color 225ms, background-color 225ms, border-color 225ms;
}
.exportFormat__item.active {
    color: #ffffff;
    background-color: #A8A0C1;
}

.exportFormat__item:hover,
.exportFormat__item:focus {
    background-color: #8A79BA;
    color: #ffffff;
    border-color: #8A79BA;
}

.exportFormat__item span {
    text-align: left;
    flex-grow: 1;
    padding-left: 4px;
}
.exportFormat__pdf {
    -webkit-transform: scale(2);
            transform: scale(2);
    position: relative;
    left: 16px;
    top: 14px;
    margin-right: 8px;
}
.exportFormat__item.active .exportFormat__pdf path:nth-child(2),
.exportFormat__item:hover .exportFormat__pdf path:nth-child(2),
.exportFormat__item:focus .exportFormat__pdf path:nth-child(2) {
    fill: #ffffff;
}