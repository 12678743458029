.mgDisaggregated__container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 333px;
    padding-bottom: 48px;
    margin-left: 28px;

}
.mgd__overflowContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    height: 552px;
    overflow: auto;
    padding-right: 4px;
}
.mgd__item,.mgd__item-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 78px;
}
.mgd__item {
    width: 150px;
    border-bottom: 1px solid #F0F0F0;
    height: 22px;
    align-items: center;
}
.mgd__item > span {
    font-size: 9px;
    line-height: 22px;
    color: #575757;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.mgDisaggregated__container-footer {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #D1D1D1;
    padding: 10px 0;
}
.mgdcf__item {
    display: flex;
    justify-content: flex-start;
    font-size: 10px;
    align-items: center;
}
.mgdcf__item .catLabel {
    margin-left: 4px;
}
.mgd__overflowContainer::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border: 1px solid black;
}
.mgd__overflowContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.mgd__overflowContainer::-webkit-scrollbar-thumb {
    background: #b9c1c3;
    border-radius: 5px;
}
.mgd__overflowContainer::-webkit-scrollbar-thumb:hover {
    background: #93999a;
    border-radius: 5px;
}
/* circle labels */
.catCircle {
    width: 16px;
    height: 16px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 8px;
    line-height: 10px;
    color: #707070;
    font-weight: 600;
    border: 1px solid transparent;
    margin-left: 4px;
}
.cat__blue.catCircle {
    background-color: #91B6C4;
    border-color: #91B6C4;
    color: #FFFFFF;
}
.cat__lightBlue.catCircle {
    background-color: #D8E3E5;
    border-color: #91B6C4;
}
.catCircle.cat__yellow {
    background-color: #EFD79C;
    border-color: #EFD79C;
}
.catCircle.cat__orangeWhite {
    background-color: #FFFFFF;
    border-color: #D8A585;
}
.catCircle.cat__orange {
    background-color: #D8A585;
    border-color: #D8A585;
    color: #ffffff;
}
.catCircle.cat__grayWhiteBar {
    background-color: #CBCBCB;
    border-color: transparent;
    overflow: visible;
    position: relative;
}
.catCircle .cat__whiteBar {
    width: 16px;
    height: 8px;
    background: #ffffff;
    position: absolute;
    top: 3px;
    color: black;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* section footer */