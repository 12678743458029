.mgMapPlaceholder__container img {
    width: 100%;
    height: auto;
}
.mgMapPlaceholder__container {
    margin: 0 auto;
}
.mgMapPlaceholder__container.map-generator-6-1 {
    width: 418px;
}
.mgMapLegend {
    width: 256px;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    flex-wrap: wrap;
}
.mgMapLegend p {
    width: 100%;
    margin-bottom: 6px;
    text-align: center;
}
.mgMapLegend .mgMapLegend__item-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mgMapLegend__item-value {
    font-size: 8px;
    line-height: 8px;
    color: #575757;
    margin-top: 4px;
}