.rf__header {
  margin-bottom: 10px;
  border-bottom: 1px solid #d3d3d3;
}

.reportForm__container {
  width: 100%;
  margin: 0 auto;
  max-width: 575px;
}

.lfInput__container textarea {
  background: #ffffff !important;
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
  color: #000;
  font-size: 16px;
  line-height: 18px;
  height: 120px;
  padding: 14px 14px;
}

.lfInput__container input[type='file'] {
  background: #f2f2f2 !important;
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
  color: #000;
  font-size: 16px;
  line-height: 18px;
}

.report__modal .modal-body {
  padding-top: 0px;
  padding-bottom: 10px;
}

.report__modal .modal-body .sourceInput label {
  font-size: 14px;
}

.report__modal .modal-body .sourceInput input {
  font-size: 14px;
}

.selectReport {
}
.selectReport .content__container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  justify-content: space-between;
}
.selectReport .content__container .areaLinkBox {
  width: calc(50% - 20px);
  padding: 26px 28px 26px;
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  min-height: 220px;
  cursor: pointer;
}

.selectReport .content__container .areaLinkBox:hover {
  background: #d8d8d8;
}

.fullSize.report__modal .modal-dialog {
  max-width: 650px !important;
}

.fullSize.report__modal .modal-content {
  background-color: #f2f2f2 !important;
}

.report__modal h6 {
  font-size: 22px !important;
}

.report__modal .areaLink__description {
  font-size: 16px !important;
}

.report__modal .dd-wrapper .check-container label {
  background-color: #f2f2f2 !important;
}

.report__modal .btn-primary {
  background-color: #8f8f8f !important;
}

.report__modal .lf__title {
  font-size: 24px !important;
  padding-top: 20px;
  text-align: center;
}



.selectReport h5.thankyou {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
  margin: auto;
  margin-bottom: 50px;
}
