/* Very Fragile stuff */
.reactSelect__item-topLevel-dd .accordion-item {
  border: medium none currentColor;
  border: initial;
  margin: 0 3%;
  width: 94%;
}
.reactSelect__item-topLevel-dd .accordion-item .accordion-button {
  padding: 13px 16px;
}
.reactSelect__item.dropdown-item:hover {
  background-color: #f2f2f2;
}

.selectTreaty__select {
  margin-left: 10px;
  flex-grow: 1;
  z-index: 100;
}

.selectTreaty__select_container {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-end;
}

.reactSelect > * {
  cursor: pointer !important;
}
.reactSelect > div {
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  outline: none;
  box-shadow: 0 0 0 1px #d8e3e5;
  border-radius: 0;
  font-size: 16px;
  line-height: 18px;
}
.reactSelect > .css-yk16xz-control,
.reactSelect > .css-1pahdxg-control,
.reactSelect .css-x1ghzc-control,
.reactSelect .css-16fimad-control {
  height: 100%;
}
.reactSelect__link-dropdown {
  background-color: #ffffff;
  margin-right: 12px;
}
/* deletes separators from react-select */
.reactSelect .css-1okebmr-indicatorSeparator {
  display: none;
}

.reactSelect__item_number {
  margin-right: 8px;
}

.reactSelect .css-11unzgr {
  max-height: none;
  max-height: initial;
  overflow: visible;
  overflow: initial;
  padding: 0 10px 0 10px;
}

div[id^="-option"]:hover,
div[class*="-option"]:hover,
div[id^="-option"]:focus,
div[class*="-option"]:active {
  background: #f4f4f4 !important;
  color: #4e4e4e !important;
  cursor: pointer !important;
}
div.css-9gakcf-option {
  background: #fff;
  color: #000;
}
div.css-26l3qy-menu {
  margin-top: 4px;
  border-radius: 3px;
  box-shadow: 0 3px 6px #00000029;
}
div.css-4ljt47-MenuList {
  padding: 0 10px 0 10px;
  max-height: none;
  max-height: initial;
  border-color: #fff;
  border-radius: 3px;
}
div[id^="react-select-3-option"] {
  border-top: 1px solid #dddae2;
  padding: 12px 24px;
}
div[id^="react-select-2-option"] {
  border-top: 1px solid #dddae2;
  padding: 12px 24px;
}
div[id^="react-select-3-option-0"] {
  border-top: 0;
  border-top: initial;
}
div[id^="react-select-2-option-0"] {
  border-top: 0;
  border-top: initial;
}
.reactSelect {
  font-size: 14px;
}
.reactSelect input:focus {
  outline: none;
  border-color: #4e4e4e !important;
}
.dropdownCombo__container {
  background: #ffffff;
  position: relative;
}
.dropdownCombo__container svg {
  position: absolute;
  right: 10px;
  top: 12px;
}
.dropdownCombo__container .btn {
  width: 100%;
  max-width: none;
  max-width: initial;
  text-align: left;
  outline: none;
  border-color: #ffffff;
  box-shadow: none;
  box-shadow: initial;
  padding: 10px 10px;
}
.dropdownCombo__container .btn:after {
  display: none;
}
.reactSelect__link-dropdown {
  width: 100%;
}
.reactSelect__link-dropdown .dropdown-menu {
  width: 100%;
  border: medium none currentColor;
  border: initial;
  border-radius: 0;
  top: calc(100% + 5px);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-shadow: 0 3px 6px #00000029;
  opacity: 0;
  transition: opacity 175ms, height 225ms;
  height: 0;
}
.reactSelect__link-dropdown .dropdown-menu.show {
  opacity: 1;
  height: auto;
  max-height: 78vh;
  border-radius: 3px;
  top: 2px;
}
.reactSelect__link-dropdown .dropdown-menu a {
  text-decoration: none;
  display: block;
  padding: 8px 16px;
}
/* TODO: Check for other browsers/devices */
.reactSelect__link-dropdown .dropdown-item.rsi__small a {
  font-size: 15px;
  padding: 4px;
}
.reactSelect__link-dropdown .dropdown-menu a:hover,
.reactSelect__link-dropdown .dropdown-menu a:focus,
.reactSelect__link-dropdown .dropdown-menu a:active {
  color: #000;
}
.reactSelect__link-dropdown .reactSelect__item {
  font-size: 14px;
  margin-bottom: 0px;
  padding: 0px 10px;
}
.reactSelect__link-dropdown .reactSelect__item-topLevel {
  margin: 0 3%;
  width: 94%;
  padding: 0 0 0 0;
}
.reactSelect__item-topLevel,
.reactSelect__item-topLevel-dd .accordion-item .accordion-button {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  border-bottom: 1px solid #dddae2;
}
.reactSelect__link-dropdown .reactSelect__item-topLevel:last-child {
  border-bottom: 0;
  border-bottom: initial;
}
.reactSelect__link-dropdown .reactSelect__item-topLevel {
  padding: 5px 0 5px 0;
}
.reactSelect__link-dropdown .reactSelect__item-topLevel:hover,
.reactSelect__link-dropdown .reactSelect__item-topLevel:active,
.reactSelect__item-topLevel-dd .accordion-item button:hover,
.reactSelect__item-topLevel-dd .accordion-item button:active {
  background-color: #f2f2f2 !important;
  outline: none;
  box-shadow: none;
  box-shadow: initial;
}
.reactSelect__link-dropdown .reactSelect__item-topLevel:focus,
.reactSelect__item-topLevel-dd .accordion-item button:focus {
  outline: none;
  box-shadow: none;
  box-shadow: initial;
}
.reactSelect__item-topLevel-dd .accordion-button:not(.collapsed)::after,
.reactSelect__item-topLevel-dd .accordion-button::after {
  background-image: none;
  background-image: initial;
  content: normal;
  content: initial;
}
.reactSelect__item-topLevel-dd .accordion-button:not(.collapsed) {
  background-color: transparent;
  background-color: initial;
}
.reactSelect__item-topLevel-dd .accordion-body {
  padding: 10px;

  overflow: auto;
}

.reactSelect__link-dropdown .dropdown-menu.show::-webkit-scrollbar,
.reactSelect__item-topLevel-dd .accordion-body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border: 1px solid black;
}
.reactSelect__link-dropdown .dropdown-menu.show::-webkit-scrollbar-track,
.reactSelect__item-topLevel-dd .accordion-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.reactSelect__link-dropdown .dropdown-menu.show::-webkit-scrollbar-thumb,
.reactSelect__item-topLevel-dd .accordion-body::-webkit-scrollbar-thumb {
  background: #b9c1c3;
  border-radius: 5px;
}
.reactSelect__link-dropdown .dropdown-menu.show::-webkit-scrollbar-thumb:hover,
.reactSelect__item-topLevel-dd .accordion-body::-webkit-scrollbar-thumb:hover {
  background: #93999a;
  border-radius: 5px;
}
.isClosed .dropdown-menu {
  display: none !important;
}
