body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url('/public/images/hero_images/1.webp')
    url('/public/images/hero_images/2.webp')
    url('/public/images/hero_images/3.webp')
    url('/public/images/hero_images/5.webp')
    url('/public/images/hero_images/6.webp')
    url('/public/images/hero_images/7.webp')
    url('/public/images/hero_images/8.webp')
    url('/public/images/hero_images/9.webp')
    url('/public/images/hero_images/10.webp')
    url('/public/images/hero_images/11.webp');
}
.homepage__updates-container h2 {
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: 900;
}
.homepage__updates-container p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 22px;
}
.section__areas.section__homePage {
  background-color: #f0f0f0;
}

/* HOMEPAGE RAINBOW BAR */
.rainbow__bar {
  display: flex;
  width: 100%;
  margin-top: 5px;
  justify-content: stretch;
}

.rainbow__bar span {
  background: blue;
  height: 15px;
  flex-grow: 1;
  margin-right: 5px;
}

.rainbow__bar span:last-child {
  margin-right: 0;
}

.rainbow__bar span:nth-child(1),
.rainbow__bar span:nth-child(10) {
  background-color: #ab97ce;
}

.rainbow__bar span:nth-child(2),
.rainbow__bar span:nth-child(11) {
  background-color: #d3d3d3;
}

.rainbow__bar span:nth-child(3),
.rainbow__bar span:nth-child(12) {
  background-color: #96c9db;
}

.rainbow__bar span:nth-child(4),
.rainbow__bar span:nth-child(13) {
  background-color: #92c598;
}

.rainbow__bar span:nth-child(5),
.rainbow__bar span:nth-child(14) {
  background-color: #b1c691;
}

.rainbow__bar span:nth-child(6),
.rainbow__bar span:nth-child(15) {
  background-color: #f0c85c;
}

.rainbow__bar span:nth-child(7),
.rainbow__bar span:nth-child(16) {
  background-color: #f5934b;
}

.rainbow__bar span:nth-child(8),
.rainbow__bar span:nth-child(17) {
  background-color: #e08aa2;
}

.rainbow__bar span:nth-child(9),
.rainbow__bar span:nth-child(18) {
  background-color: #e5a9de;
}

/* HOME PAGE NEW CONTENT */
.page__home .content__section {
  display: flex;
  justify-content: space-between;
  padding: 28px 0;
}

.page__home .content__container {
  padding-bottom: 50px;
}

.homepage__contentBox {
  padding: 56px;
}

.homepage__contentBox.hcpb__monitor {
  padding: 50px 45px 28px;
}

.hd__container {
  max-height: 492px;
  overflow: auto;
}

/* custom scrollbar */
/* width */
.hd__container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border: 1px solid black;
}

/* Track */
.hd__container::-webkit-scrollbar-track {
  background: #f4efef;
  opacity: 0.75;
}

/* Handle */
.hd__container::-webkit-scrollbar-thumb {
  background: #e5b4b4;
  border-radius: 5px;
}

/* Handle on hover */
.hd__container::-webkit-scrollbar-thumb:hover {
  background: #e08aa2;
  border-radius: 5px;
}

/* custom scrollbar */
/* width */
.hlu__container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border: 1px solid black;
}

/* Track */
.hlu__container::-webkit-scrollbar-track {
  background: #f6efdd;
  opacity: 0.75;
}

/* Handle */
.hlu__container::-webkit-scrollbar-thumb {
  background: #ecd28e;
  border-radius: 5px;
}

/* Handle on hover */
.hlu__container::-webkit-scrollbar-thumb:hover {
  background: #f0c85c;
  border-radius: 5px;
}

.homepage__contentBox.hpcb__areas {
  background: #d8e3e5;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.homepage__contentBox .homepage__areasSidebar {
  width: 240px;
  padding: 10px 0;
}

.homepage__contentBox .homepage__areasSidebar button,
.homepage__Jurisdictions-sidebar button {
  color: #fff;
  font-size: 14px;
  text-align: center;
  border: medium none currentColor;
  border: initial;
  width: 150px;
  padding: 6px;
  margin-top: 20px;
  height: 30px;
  font-weight: 700;
  transition: background-color 225ms;
}

.homepage__contentBox .homepage__areasSidebar button {
  background: #96c9db;
}

.homepage__contentBox .homepage__areasSidebar button:hover {
  background: #a2d7ea;
}

.homepage__Jurisdictions-sidebar button {
  background: #96a195;
  margin-left: auto;
  display: block;
  margin-top: 36px;
}

.homepage__Jurisdictions-sidebar button:hover {
  background: #a9b6a8;
}

.homepage__contentBox.hpcb__areas h2 {
  width: 100%;
  font-size: 32px;
  line-height: 50px;
  font-weight: 900;
}

.homepage__contentBox.hpcb__areas p,
.homepage__Jurisdictions p {
  font-size: 14px;
  line-height: 22px;
  color: #575757;
}

.homepage__Jurisdictions p {
  text-align: right;
}
.homepage__Jurisdictions .mapLoader__container p {
  text-align: center;
}
.homepage__Jurisdictions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #dee0d5;
  padding: 72px 72px 72px 22px;
}

.homepage__Jurisdictions h2 {
  width: 100%;
  font-size: 32px;
  line-height: 32px;
  font-weight: 900;
  text-align: right;
  margin-bottom: 36px;
}

.homepage__Jurisdictions img {
  width: calc(100% - 236px);
}

.homepage__Jurisdictions-sidebar {
  width: 236px;
}
.homepage__Jurisdictions #chartdiv {
  margin-bottom: 0;
  margin-top: 16px;
  height: 500px;
  min-height: 500px;
}
.homepage__Areas.homepage__Tools .homepage__contentBox.hpcb__areas {
  margin-top: 50px;
  background-color: #d8d5e3;
}

.homepage__Areas.homepage__Tools .homepage__contentBox.hpcb__areas button {
  background-color: #afa6c9;
}

.homepage__Areas.homepage__Tools
  .homepage__contentBox.hpcb__areas
  button:hover {
  background-color: #bdb3d9;
}

.homepage__contentBox .hpcb__areasContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% - 290px);
  padding: 16px;
}

.homepage__contentBox .hpcb__areasBox {
  cursor: pointer;
  background-color: #f4f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(33.333% - 9px);
  margin-bottom: 16px;
  height: 206px;
  position: relative;
  transition: background-color 225ms;
}

.homepage__contentBox .hpcb__areasBox:nth-child(n + 3) {
  margin-bottom: 0;
}

.homepage__contentBox .hpcb__areasBox:hover {
  background-color: #b0cbce;
}

.homepage__contentBox .hpcb__areasBox path,
.homepage__contentBox .hpcb__areasBox rect,
.homepage__contentBox .hpcb__areasBox circle {
  transition: fill 225ms;
}

.homepage__contentBox .hpcb__areasBox:hover path,
.homepage__contentBox .hpcb__areasBox:hover rect,
.homepage__contentBox .hpcb__areasBox:hover circle {
  fill: #89a7b1;
}

.homepage__contentBox .hpcb__areaSubTitle {
  font-size: 14px;
  line-height: 15px;
  color: #617a7c;
  text-transform: uppercase;
  margin: 0 0 0 0;
}

.homepage__contentBox.hpcb__areas .hpcb__areaTitle {
  font-size: 18px;
  line-height: 20px;
  color: #528598;
  font-weight: 900;
  margin: 6px 0 16px;
  max-width: 172px;
  text-align: center;
}

.homepage__contentBox .hpcb__areas-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background-color: #b0cbce;
  border: medium none currentColor;
  border: initial;
  width: 100%;
  margin-top: 32px;
  height: 35px;
}

.homepage__latestUpdates {
  padding: 46px 40px 28px;
  background: #f5f5ed;
  margin: var(--verticalMargin);
}

/* HOMEPAGE TOOLS SECTION */
.hpcb__tools {
  background-color: #d8d5e3;
}

.hpcb__toolsContainer {
  display: flex;
  flex-direction: row;
  width: calc(100% - 370px);
}

.hpcb__tools .hpcb__toolBox {
  background-color: #e8e5f0;
  cursor: pointer;
  width: 33%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  position: relative;
  padding: 52px 30px;
  transition: background-color 225ms;
}

.hpcb__toolBox svg path,
.hpcb__toolBox svg circle,
.hpcb__toolBox svg rect {
  transition: fill 225ms;
}

.hpcb__tools .hpcb__toolBox:last-child {
  margin-right: 0;
}

.hpcb__tools .hpcb__toolBox:last-child svg {
  position: relative;
  top: 2px;
}

.homepage__contentBox.hpcb__tools .hpcb__toolBox p {
  margin-top: 34px;
  color: #868686;
  font-size: 13px;
  line-height: 18px;
}

.homepage__contentBox.hpcb__tools p.hpcb__toolTitle {
  max-width: 130px;
  font-size: 22px;
  line-height: 26px;
  color: #8a79ba;
  font-weight: 900;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 0;
}

.homepage__contentBox.hpcb__tools p.hpcb__toolTitle.forthcoming {
  margin-bottom: 8px;
}

.homepage__contentBox.hpcb__tools .hpcb__toolBox p.forthcoming {
  margin-top: 9px;
}

.homepage__deadlinesUpdates {
  background-color: #d3d3d3;
  padding: 102px 116px;
  margin-top: 50px;
}

.homepage__deadlinesUpdates .content__container {
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
}

.homepage__deadlines-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.homepage__latestUpdates {
  background-color: #fff;
  padding: 38px;
  margin-top: 14px;
}

.homepage__deadlines {
  background-color: #fff;
  padding: 38px;
}

.hd__container {
  max-height: 434px;
  overflow: auto;
}

.hlu__container {
  max-height: 298px;
  overflow: auto;
}

.hlu__topBar {
  font-size: 14px;
  line-height: 18px;
  font-weight: 900;
  color: #4e4e4e;
}

.hlu__topBar img {
  width: 20px;
  margin-right: 6px;
}

.hlu__topBar a {
  color: #528598;
}

.hlu__item {
  padding: 12px 0;
  width: 98%;
  border-bottom: 1px dashed #cbcbcb;
}

.hlu__item:first-child {
  padding-top: 0;
}

.hlu__item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
  border-bottom: initial;
}

.hlu__item .itemContent {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 6px;
}

.hlu__item .itemDate {
  color: #528598;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0;
}
.hlu__item .itemDate:first-letter {
  text-transform: capitalize;
}
.homepage__updates-container .moreBtn,
.hd__button {
  margin-top: 24px;
  border: medium none currentColor;
  border: initial;
  margin-left: auto;
  width: 150px;
  display: block;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  height: 30px;
  transition: background-color 225ms;
}

.hd__button {
  background-color: #e08aa2;
}

.hd__button:hover {
  background-color: #e1a0b2;
}

.homepage__updates-container .moreBtn {
  background-color: #f0c85c;
}

.homepage__updates-container .moreBtn:hover {
  background-color: #eccf7e;
}

.homepage__deadlinesUpdates .hd__item {
  padding: 12px 0;
  width: 98%;
  border-bottom: 1px dashed #bdc9bc;
}

.homepage__deadlinesUpdates .hd__item:first-child {
  padding-top: 0;
}

.homepage__deadlinesUpdates .hd__item:last-child {
  border-bottom: 0;
  border-bottom: initial;
  padding-bottom: 0;
}

.homepage__deadlinesUpdates .hd__item .hdi__topBar {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
}

.homepage__deadlinesUpdates .hd__item .hdi__topBar span {
  font-size: 15px;
  font-weight: bold;
}

.hd__item{
  margin-bottom: 20px;
}

.hd__item .hdi__title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 900;
  color: #4e4e4e;
  margin-bottom: 4px;
}

.hd__container .hd__item p {
  font-size: 12px;
  line-height: 16px;
  color: #707070;
  margin-bottom: 4px;
}

.hdi__footer a {
  font-size: 12px;
  text-decoration: underline;
  color: #e7a4a4;
}

.hdi__footer a:hover {
  color: #96c9db;
}

.homepage__deadlinesUpdates h3 {
  color: #4e4e4e;
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 20px;
}

.homepage__deadlinesUpdates p {
  font-size: 14px;
  line-height: 20px;
  color: #575757;
}

.hpcb__tools .hpcb__toolBox:hover {
  background-color: #afa6c9;
  color: #ffffff;
}

.hpcb__tools .hpcb__toolBox:hover p,
.hpcb__tools .hpcb__toolBox:hover h6 {
  color: #ffffff;
}

.hpcb__tools .hpcb__toolBox:hover svg path,
.hpcb__tools .hpcb__toolBox:hover svg circle,
.hpcb__tools .hpcb__toolBox:hover svg rect {
  fill: #a397c4 !important;
}

.hpcb__tools .hpcb__tools-button {
  display: block;
  width: 100%;
  margin: var(--verticalMargin);
  text-align: center;
  color: #ffffff;
  background-color: #afa6c9;
  font-size: 14px;
  font-weight: 700;
  height: 35px;
  line-height: 35px;
}

/* JUR BOX ACOORDIONS */
.jurSelect__modal-header .js-searchInput {
  font-size: 12px;
  min-width: 240px;
  border: 0;
  width: 100%;
  height: 34px;
  background-color: #a0aa9f;
  padding: 3px 10px;
  letter-spacing: 0.33px;
  color: #ffffff;
  margin-bottom: 12px;
}

.jurSelect__modal-header button {
  visibility: hidden;
}

.js-searchInput:focus {
  box-shadow: none;
  box-shadow: initial;
  border-color: transparent;
  outline: none;
}

.jurSelect__modal-header .js-searchInput::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}

.jurSelect__modal-header .js-searchInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}

.jurSelect__modal-header .js-searchInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.jurSelect__modal-header .js-searchInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}

.dd-wrapper .dd-list {
  padding: 0;
  margin: 0;
  width: 100%;
  left: 0px;
  max-height: 400px;
  overflow: auto;
}

.dd-wrapper .dd-list li {
  list-style-type: none;
}

.dd-wrapper .dd-list li:first-of-type > button {
  border-top: 1px solid #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dd-wrapper .dd-list li:last-of-type > button {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dd-wrapper .dd-list li button {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  font-size: small;
  padding: 0px 10px 0px 10px;
  border: 0;

  width: 100%;
  text-align: left;
}

.dd-wrapper .dd-list .dd-filter {
  display: flex;
  justify-content: space-between;
  background-color: white;
  font-size: small;
  padding: 5px 5px 5px 5px;
  border: 0;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.dd-wrapper .dd-list .dd-list-item input[type='checkbox'] {
  vertical-align: bottom;
}

.dd-wrapper .dd-list li button:hover {
  cursor: pointer;
  background-color: #cbe2e6;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dd-filter input {
  padding: 3px;
  width: 100%;
  height: 34px;
  background-color: #cbe2e6;
  border-top: 0 !important;
  border-top: initial !important;
}

.js-clear-filter {
  border: 0px;
  padding-bottom: 8px;
  width: 100%;
  height: 34px;
  background-color: transparent;
  font-size: 12px;
  color: #676e6f;
  margin-top: 16px;
}


.ilgaMonitor__logo-container {
  width: 100%;
  margin-bottom: 30px;
}
.ilgaMonitor__logo {
  height: 52px;
  width: auto;
}

.monitorCarrousel {
  width: calc(100% - 332px);
  height: 554px;
  overflow: hidden;
  font-size: 12px;
  position: relative;
  justify-content: center;
  transition: height 200ms;
  margin-top: -60px;
}

.monitorCarrousel .update__title {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0;
  width: 100%;
  cursor: pointer;
  padding-left: 38px;
}

.monitorCarrousel .update__country {
  margin-bottom: 4px;
}

.monitorCarrousel .update__country img {
  margin-right: 10px;
}

.monitorCarrousel .update {
  background-color: rgba(248, 248, 248, 0.94);
  max-width: 600px;
  min-height: 74px;
  margin: 0 auto 16px;
  padding: 16px 16px;
  transition: all 0.8s ease;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.monitorCarrousel .update.update--high {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  margin: 28px auto;
}

.monitorCarrousel span.sourceName {
  color: #528598;
  font-size: 13px;
  font-style: italic;
}
.update--low {
  opacity: 0;
}
.update--high,
.update--mid,
.update--mid-low {
  opacity: 1;
}

.fade-in-section {
  opacity: 0;
  -webkit-transform: translateY(0vh);
          transform: translateY(0vh);
  visibility: hidden;
  transition: opacity 400ms ease-out, visibility 400ms ease-out, -webkit-transform 200ms ease-out;
  transition: opacity 400ms ease-out, transform 200ms ease-out,
    visibility 400ms ease-out;
  transition: opacity 400ms ease-out, transform 200ms ease-out,
    visibility 400ms ease-out, -webkit-transform 200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  visibility: visible;
}

.homepage__Jurisdictions .homeMap {
  height: 100%;
  width: calc(100% - 248px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homepage__Jurisdictions .homeMap > div {
  width: 100%;
}

@media (max-width: 768px) {
  .leadSection__container {
    flex-direction: column;
  }
  .leadSection__container .homepage__updates-container {
    background: #d3d3d3;
    padding: 26px 20px !important;
  }

  .homepage__updates-container .homepage__latestUpdates {
    margin-top: 26px;
  }
  div.leadSection__container section,
  div.leadSection__container .homepage__updates-container {
    width: 100%;
  }
  div.leadSection__container .homepage__updates-container {
    margin-bottom: 28px;
  }
  .homepage__contentBox.hpcb__areas {
    flex-direction: column;
  }
  .page__home .hero__section .container .hero__whiteBox {
    background: rgba(255, 255, 255, 0.74);
    padding: 20px 16px;
  }
  .homepage__contentBox.hpcb__areas h2,
  .homepage__Jurisdictions h2 {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 8px;
  }
  .homepage__contentBox .homepage__areasSidebar button {
    margin-left: auto;
    display: block;
  }
  .homepage__contentBox .homepage__areasSidebar,
  .homepage__contentBox .hpcb__areasContainer {
    width: 100%;
  }
  .homepage__contentBox .hpcb__areasContainer {
    padding: 0 0 0 0;
  }
  .homepage__contentBox.hpcb__areas .hpcb__areaTitle {
    font-size: 14px;
    line-height: 16px;
  }
  .homepage__Jurisdictions {
    margin-top: 28px;
    padding: 26px 20px;
    flex-direction: column;
  }
  .homepage__Jurisdictions-sidebar,
  .hpcb__toolsContainer,
  .homepage__Jurisdictions img {
    width: 100%;
  }
  .homepage__Jurisdictions-sidebar button {
    margin-top: 20px;
  }
  .homepage__Jurisdictions img {
    order: 2;
    margin-top: 20px;
  }
  .homepage__contentBox .homepage__areasSidebar {
    padding: 0 0 0 0;
  }
  .page__home .hpcb__toolsContainer .hpcb__toolBox {
    width: 100%;
    height: auto;
    height: initial;
    margin-right: 0;
    padding: 26px 20px;
  }
  .homepage__contentBox.hpcb__tools .hpcb__toolBox p {
    margin-top: 20px;
  }
  .page__home .content__container {
    padding-bottom: 0;
  }
  .homepage__deadlinesUpdates {
    margin-top: 28px;
    padding: 26px 28px;
  }
  .homepage__deadlinesUpdates .content__container {
    padding: 0 0 0 0;
    flex-direction: column;
  }
  .homepage__deadlines-container,
  .homepage__updates-container {
    width: 100%;
  }
  .homepage__updates-container {
    margin-top: 28px;
  }
  .homepage__deadlinesUpdates h3,
  .homepage__Areas.homepage__Monitor h2 {
    font-size: 28px;
    line-height: 32px;
  }
  .monitorCarrousel {
    width: 100%;
    margin-top: 32px;
  }
  .homepage__Areas.homepage__Tools .homepage__contentBox.hpcb__areas {
    margin-top: 28px;
  }
  .homepage__deadlinesUpdates + .content__container {
    padding: 0 0 0 0;
  }
  .homepage__Areas.homepage__Monitor {
    margin-top: 0;
  }
  .monitorCarrousel .update {
    max-width: 80%;
    height: auto;
  }
  .homepage__Jurisdictions .homeMap {
    width: 100%;
  }
  .homepage__Jurisdictions-sidebar {
    padding-top: 0;
  }
  .homepage__Jurisdictions #chartdiv {
    height: 180px;
    min-height: 180px;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .homepage__deadlines-container {
    flex-direction: column;
    
  }
  .hpcb__deadlines {
    
    margin-bottom: 28px;
  }
}

@media (min-width: 768px) and (max-width: 1050px) {
  header.section__homePage .logoContainer {
    margin-left: -18px !important;
  }
  header.section__homePage .logoContainer img {
    height: 140px !important;
  }
  .menuHomepage__actions {
    flex-direction: column;
  }
  .menuHomepage__actions a {
    margin-right: 0 !important;
    margin-bottom: 10px;
    right: 6px;
    position: absolute;
  }
  .menuHomepage__actions a:last-child {
    top: 52px;
  }
  .page__home .content__container {
    padding-bottom: 0;
  }
  .homepage__deadlinesUpdates {
    padding: 50px 0;
  }
  .homepage__deadlines-container {
    width: 100%;
  }
  .homepage__deadlines-container h3,
  .homepage__updates-container h3 {
    padding-right: 80px;
  }
  .homepage__latestUpdates {
    margin-top: 54px;
  }
  .page__home .content__container:last-child {
    padding-bottom: 50px;
  }
  .monitorCarrousel,
  .homepage__contentBox .hpcb__areasContainer {
    width: 100%;
    margin-top: 28px;
  }
  .homepage__Jurisdictions .homeMap #chartdiv {
    width: 100%;
    height: 320px;
    min-height: 320px;
  }
}

.homepage__contentBox .homepage__areasSidebar .goToMonitorBtn {
  margin-bottom: 0px;

  text-align: center;
  height: 40px;
  width: 100%;
  font-size: 16px;
  border-radius: 0;
  align-self: flex-end;
  justify-self: flex-end !important;
}

.homepage__areasSidebar.homepage__areasSidebar__monitor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0px;
}

.photoCreditsActions__container h4 {
  font-size: 24px;
  color: #6ea2b5 !important;
  text-align: center;
}

.photoCredits__content {
  margin-top: 10px;
  line-height: 18px;
  font-size: 14px;
  text-align: justify;
}

.photoCredits__content a {
  color: #6ea2b5;
}
.photoCredits__content a:hover {
  text-decoration: underline;
}

.leadSection__container {
  display: flex;
}
.leadSection__container section {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leadSection__container .homepage__updates-container {
  width: 40%;
  padding: 48px 0;
}

@media (min-width: 920px) {
  .leadSection__container .homepage__updates-container {
    background: #d3d3d3;
    padding: 26px 26px !important;
    margin: 28px 0;
  }
}

.homepage__contentBox.hpcb__deadlines {
  margin-top: 50px;
  background-color: #d3d3d3 !important;
}
