.tippy-box {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 8, 16, 0.15);
  color: #333;
  box-shadow: 0 4px 14px -2px rgba(0, 8, 16, 0.08);
}
.tippy-box > .tippy-backdrop {
  background-color: #fff;
}
.tippy-box > .tippy-arrow:after,
.tippy-box > .tippy-svg-arrow:after {
  content: "";
  position: absolute;
  z-index: 2;
}
.tippy-box > .tippy-arrow:after {
  border-color: transparent;
  border-style: solid;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-top-color: #fff;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:after {
  border-top-color: rgba(255, 255, 255, 1);
  border-width: 7px 7px 0;
  position: absolute;
  bottom: -33px;
  left: calc(50% - 7px);
}
.tippy-box[data-placement^="top"] > .tippy-svg-arrow > svg {
  top: 16px;
}
.tippy-box[data-placement^="top"] > .tippy-svg-arrow:after {
  top: 17px;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-bottom-color: #fff;
  bottom: 16px;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:after {
  border-bottom-color: rgba(0, 8, 16, 0.2);
  border-width: 0 7px 7px;
  bottom: 17px;
  left: 1px;
}
.tippy-box[data-placement^="bottom"] > .tippy-svg-arrow > svg {
  bottom: 16px;
}
.tippy-box[data-placement^="bottom"] > .tippy-svg-arrow:after {
  bottom: 17px;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-left-color: #fff;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:after {
  border-left-color: rgba(2, 2, 3, 0.2);
  border-width: 7px 0 7px 7px;
  left: 17px;
  top: 1px;
}
.tippy-box[data-placement^="left"] > .tippy-svg-arrow > svg {
  left: 11px;
}
.tippy-box[data-placement^="left"] > .tippy-svg-arrow:after {
  left: 12px;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-right-color: #fff;
  right: 16px;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:after {
  border-width: 7px 7px 7px 0;
  right: 17px;
  top: 1px;
  border-right-color: rgba(0, 8, 16, 0.2);
}
.tippy-box[data-placement^="right"] > .tippy-svg-arrow > svg {
  right: 11px;
}
.tippy-box[data-placement^="right"] > .tippy-svg-arrow:after {
  right: 12px;
}
.tippy-box > .tippy-svg-arrow {
  fill: #fff;
}
.tippy-box > .tippy-svg-arrow:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA2czEuNzk2LS4wMTMgNC42Ny0zLjYxNUM1Ljg1MS45IDYuOTMuMDA2IDggMGMxLjA3LS4wMDYgMi4xNDguODg3IDMuMzQzIDIuMzg1QzE0LjIzMyA2LjAwNSAxNiA2IDE2IDZIMHoiIGZpbGw9InJnYmEoMCwgOCwgMTYsIDAuMikiLz48L3N2Zz4=);
  background-size: 16px 6px;
  width: 16px;
  height: 6px;
}

.tippy-box[data-theme~="ilga-source"] {
  background-color: rgb(255, 223, 78) !important;
  color: black;
  max-width: 200px;
  border: 1px solid black;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.tippy-box[data-theme~="ilga-a5"] {
  background-color: rgb(255, 223, 78) !important;
  color: black;
  max-width: 450px;
  border: 1px solid black;
  box-shadow: 0px 3px 6px #00000029 !important;
  text-align: left;
}

.tippy-box[data-theme~="ilga-source"][data-placement^="top"] .tippy-arrow::before {
  border-top-color: rgb(255, 223, 78) !important;
}

.tippy-box[data-theme~="ilga-a5"][data-placement^="top"] .tippy-arrow::before {
  border-top-color: rgb(255, 223, 78) !important;
}

.tippy-box[data-theme~="ilga-help"] {
  background-color: rgb(196, 196, 196) !important;
  color: black;
  max-width: 200px;
  border: 1px solid black;
  box-shadow: 0px 3px 6px #00000029 !important;
  padding: 20px 20px 20px 20px !important;
}

.tippy-box[data-theme~="ilga-help"][data-placement^="top"] .tippy-arrow::before {
  border-top-color: rgb(196, 196, 196) !important;
  bottom: -33px;
}

/* Bozidar Overrides */
.tippy-box[data-theme~="ilga-help"] {
  background-color: #fff !important;
  border-color: #fff !important;
  border-radius: 3px !important;
  padding: 20px !important;
  font-size: 12px;
  line-height: 16px;
}
.tippy-box[data-theme~="ilga-help"][data-placement^="top"] .tippy-arrow::before {
  border-top-color: #ffffff !important;
  position: relative;
}

.tippy-box[data-theme~="ilga-source"] {
  background: #ffffff !important;
  border-radius: 3px !important;
  padding: 20px !important;
  border-color: #ffffff !important;
  width: 246px;
}

.tippy-box[data-theme~="ilga-a5"] {
  background: #ffffff !important;
  border-radius: 3px !important;
  padding: 20px !important;
  border-color: #ffffff !important;
  width: 466px;
}
.tippy-box[data-theme~="ilga-help"] .tippy-arrow {
  bottom: 26px;
}
.tippy-box[data-theme~="ilga-source"] .tippy-arrow {
  bottom: 26px;
}
.tippy-box[data-theme~="ilga-source"] .tippy-arrow:after {
  border-top-color: #ffffff;
  -webkit-transform: translateX(-8px);
          transform: translateX(-8px);
}

.tippy-box[data-theme~="ilga-a5"] .tippy-arrow {
  left: 8px !important;
  bottom: 26px;
}
.tippy-box[data-theme~="ilga-a5"] .tippy-arrow:after {
  border-top-color: #ffffff;
  -webkit-transform: translateX(-8px);
          transform: translateX(-8px);
}

.sourceTooltipTitle {
  font-size: 17px;
  text-decoration: none;
  text-align: center;
}
.sourceTooltipBody a {
  color: #575757;
  text-decoration: underline;
  font-size: 14px;
  line-height: 16px;
}
.sourceTooltipBody svg {
  margin-right: 10px;
}
.sourceTooltipBody svg path {
  -webkit-transform: scale(1.75);
          transform: scale(1.75);
}

.sourceTooltipLink {
  display: flex;
}

.tippy-box[data-theme~="ilga-enforce"] {
  background-color: rgb(255, 223, 78) !important;
  color: black;
  max-width: 200px;
  border: 1px solid black;
  box-shadow: 0px 3px 6px #00000029 !important;
}
.tippy-box[data-theme~="ilga-enforce"][data-placement^="top"] .tippy-arrow::before {
  border-top-color: rgb(255, 223, 78) !important;
}
.tippy-box[data-theme~="ilga-enforce"] {
  background: #ffffff !important;
  border-radius: 3px !important;
  padding: 10px !important;
  border-color: #ffffff !important;
  width: 316px;
}
.tippy-box[data-theme~="ilga-enforce"] .tippy-arrow {
  bottom: 26px;
}
.tippy-box[data-theme~="ilga-enforce"] .tippy-arrow:after {
  border-top-color: #ffffff;
  -webkit-transform: translateX(-8px);
          transform: translateX(-8px);
}