.had__container {
    position: relative;
    z-index: 12;
}
.headerAreaDropdown-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    top: 100%;
    width: 820px;
    height: 670px;
    z-index: 5;
    left: -120px;
}
.headerDropdown__item-number {
    margin-right: 8px;
}
.headerAreaDropdown {
    padding: 12px 17px;
    position: relative;
    top: 40px;
    display: flex;
    flex-direction: column;
    width: 356px;
    background: #ffffff;
}
.had__container .headerAreaDropdown > * {
    display: block;
    text-align: left;
    padding: 12px 10px !important;
    border-bottom: 1px solid #DEDEDE;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 0 0 !important;
    cursor: pointer;
    font-weight: 400;
    position: relative;
}
.had__container .headerAreaDropdown > *.activeArea {
    font-weight: 600;
}
.headerAreaDropdown > a:last-child,
.had__container .headerAreaDropdown + .headerSubAreaDropdown > a:last-child {
    border-bottom: none;
}

.had__container .headerAreaDropdown svg {
    position: absolute;
    right: 0;
    bottom: 10px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.headerSubAreaDropdown {
    position: relative;
    top: 35px;
    padding: 12px 17px;
    width: 456px;
    background: #ffffff;
}
.headerSubAreaDropdown.area3SubDropdown {
    top: 132px;
}
.had__container .headerAreaDropdown + .headerSubAreaDropdown > a {
    display: block;
    text-align: left;
    padding: 8px 10px 8px 10px;
    border-bottom: 1px solid #DEDEDE;
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 0 0 !important;
    cursor: pointer;
    font-weight: 400;
    position: relative;
    display: flex;
    justify-content: flex-start;
}
.had__container .headerSubAreaDropdown > a.disabled {
    color: #cccccc;
    cursor: not-allowed;
    pointer-events: none;
}
.had__container .headerSubAreaDropdown > a.activeItem {
    font-weight: 600;
}

.mapLoader__container {
    position: relative;
}