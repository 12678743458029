.jurProfile__area2 .info__section {
  padding: 100px 0;
  background-color: #d8e3e5;
}
.jurProfile__area2 .info__section .content__container,
.jurProfile__area2 .infoSection__box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.jurProfile__area2 .infoSection__box {
  margin-bottom: 54px;
}
.jurProfile__area2 .info__section h2,
.jurProfile__area2 .info__section h4,
.jurProfile__area2 .info__section h5 {
  width: 100%;
  text-align: left;
}
.jurProfile__area2 .infoBox__container {
  display: flex;
  flex-wrap: wrap;
}
.jurProfile__area2 .infoSection__box > div {
  margin-right: 2px;
  box-sizing: border-box;
}
.jurProfile__area2 .infoSection__box > div:last-child {
  margin-right: 0;
}
.jurProfile__area2 .isb-1 {
  width: 253px;
}
.jurProfile__area2 .isb-2 {
  width: 532px;
}
.jurProfile__area2 .isb-3 {
  width: 768px;
}
.jurProfile__area2 .isb-4 {
  width: 1046px;
}

.jurProfile__area2 .isb-2 > div {
  width: calc(50% - 2px);
}
.jurProfile__area2 .isb-3 > div {
  width: calc(33.33% - 2px);
}
.jurProfile__area2 .isb-1 > div {
  width: 100%;
}
.jurProfile__area2 .isb-4 > div {
  width: calc(25% - 2px);
}
.jurProfile__area2 .infoSection__box:nth-child(2n-1),
.jurProfile__area2 .infoSection__box:nth-last-child(2) {
  margin-right: 26px;
}
.jurProfile__area2 .isb-title {
  font-weight: 900;
  padding-left: 21px;
  margin-bottom: 6px;
  color: #707979;
  width: 100%;
}
.jurProfile__area2 .isb__white,
.jurProfile__area2 .isb__black {
  padding: 18px 21px;
  height: 164px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.jurProfile__area2 .isb__white {
  background: #ffffff;
}
.jurProfile__area2 .isb__black {
  background: #9bb6b9;
}
.jurProfile__area2 .isb-boxTitle {
  font-size: 13px;
  line-height: 14px;
}
.jurProfile__area2 .isb-act {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  margin-top: 4px;
}
.jurProfile__area2 .isb__white .isb-boxTitle {
  color: #707070;
}
.jurProfile__area2 .isb__white .isb-act {
  color: #707070;
}
.jurProfile__area2 .isb__black .isb-boxTitle {
  color: #ffffff;
}
.jurProfile__area2 .isb__black .isb-act {
  color: #ffffff;
}
.jurProfile__area2 .text-green {
  color: #5eaa79;
}
.jurProfile__area2 .text-yellow {
  color: #ffe095;
}
.jurProfile__area2 .text-no {
  color: #fbfbfb5e;
}
.jurProfile__area2 .isb-implementation {
  position: absolute;
  bottom: 14px;
  left: 22px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 900;
}

.jurProfile__area2 .isb-1 {
  max-width: 240px;
}
.jurProfile__area2 .isb-1.contentOnRight {
  margin-right: 26px;
}
.jurProfile__area2 .isb-2 {
  max-width: 466px;
  margin-right: 26px;
}

.jurProfile__area2 .isb-3 {
  max-width: 722px;
  margin-right: 26px;
}
.jurProfile__area2 .isb-4 {
  max-width: 948px;
}

.jurProfile__area2 .isb__black {
  background-color: #9fadac !important;
}
.section1-area2 .jpjs__titleWithBtn {
  margin-bottom: 72px;
}
.jur__page .table__section {
  background-color: #ecefec;
}

/* Jur Profile Area 3 */

.jurProfile__area3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.jurProfile__area3 > div {
  background: #ecefec;
  padding: 32px 48px 16px 48px;
  margin: 0 0 0 0;
}
.jurProfile__area3 .tb__recsContainer {
  width: 59%;
}
.jurProfile__area3 .tb__recsContainer4 {
  width: 49%;
  max-width: 800px;
}
.jurProfile__area3 .tb__pieChart-container {
  width: 39%;
  position: relative;
}
.section1-jur1.jurProfile__area3 {
  justify-content: center;
}
.jurProfile__area3 .tb__pieChart-container4 {
  width: 49%;
  max-width: 800px;
}
.jurProfile__area3 .tb__barChart-container {
  width: 100%;
  margin: var(--verticalMargins);
  padding: var(--boxPaddingEven);
}

.jurProfile__area4 .tb__recsContainer4 {
  width: calc(50% - 18px);
  max-width: 800px;
}

.tb__pieChart-title {
  padding: 0 24px;
}
.tb__recs-title,
.tb__pieChart-title {
  min-height: 36px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 22px;
}

.tb__pieChart-title2 {
  margin-bottom: 0px;
}
.tb__recs-title {
  padding: 0 60px;
}
.tb__recBoxContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tb__recBoxContainer .recBox {
  width: calc(33.33% - 12px);
  background: #dde0d4;
  color: #818881;
  padding: 22px;
  margin-bottom: 24px;
  min-height: 133px;
  position: relative;
}
.tb__recBoxContainer .recBox__title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}
.tb__recBoxContainer .recBox__count {
  position: absolute;
  bottom: 16px;
  right: 22px;
  color: #ffffff;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
}
.tb__pieChart-actualContainer {
  height: calc(100% - 120px);
  
  justify-content: center;
  align-items: center;
  position: relative;
}
.tb__pieChart-actualContainer-noflex {
  display: inline;
  display: initial;
}
.tb__pieChart-actualContainer img,
.tb__barChart-actualContainer img {
  width: 100%;
}
.tb__barChart-title {
  text-align: left;
  font-size: 20px;
  line-height: 16px;
  font-weight: 900;
  color: #000;
  margin-bottom: 3px;
}
.tb__barChart-subtitle {
  font-size: 13px;
  line-height: 14px;
  color: #707070;
}

.tb__pieChart-subtitle {
  width: 100%;
  display: block;
  text-align: center;
  font-size: 13px;
  line-height: 14px;
  color: #707070;
  margin-top: 10px;
}
.jpjs__tableTabBtns {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 28px;
}
.jpjs__tableTabBtns .jpjs__tableTabBtn {
  padding: 18px 28px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 900;
  background: #ffffff;
  color: #707070;
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
  max-width: 365px;
  margin-right: 25px;
  transition: color 324ms, background-color 324ms;
}
.jpjs__tableTabBtns .jpjs__tableTabBtn:hover,
.jpjs__tableTabBtns .jpjs__tableTabBtn.active {
  background: #a0aa9f;
  color: #f4f4f4;
}

.jpjs__tableTabBtn.disabled {
  background-color: #e3e3e3;
  color: #a5a4a4;
}
.jpjs__tableTabBtn.disabled:hover {
  background-color: #e3e3e3;
  color: #a5a4a4;
  cursor: default;
}
.a3ts__tableTitle {
  font-size: 20px;
  line-height: 20px;
  color: #000;
  margin-bottom: 16px;
  font-weight: 900;
}
div.a3ts__tableTitle {
  align-items: center;
  gap: 10px;
  display: flex;
}
/* Jur Profile Area 4*/
.tb__recTabsContainer {
  width: 100%;
  max-width: 464px;
  margin: 0 auto;
}
.tb__recTabsContainer .nav-tabs {
  border-bottom: 0;
  border-bottom: initial;
  width: 100%;
  display: flex;
}
.tb__recTabsContainer .nav-item {
  flex-grow: 1;
}
.tb__recTabsContainer .nav-item .nav-link {
  font-size: 16px;
  line-height: 18px;
  width: 100%;
  border: 2px solid #818881;
  color: #818881;
  background-color: #ecefec;
  border-radius: 0;
  transition: color 324ms, background-color 324ms;
}
.tb__recTabsContainer .nav-item .nav-link.active {
  background-color: #818881;
  color: #f7f5e4;
}
.tb__recTabsContainer .tab-pane {
  margin: 26px 0 16px;
}

.tb__recTabsContainer .tab-content .tab-pane {
  max-height: 400px;
  overflow: auto;
}

.tb__recTabsContainer .tab-content .tab-pane::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.tb__recTabsContainer .tab-content .tab-pane::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  min-height: 75px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.tb__recTabsContainer .tab-content .tab-pane::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.tb__recTabsContainer .tab-content .tab-pane::-webkit-scrollbar-corner {
  background-color: transparent;
}

.tb__recTabsContainer .recRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  font-size: 16px;
  color: #707070;
  line-height: 18px;
  margin-bottom: 6px;
}
.tb__recTabsContainer .recRow p,
.tb__recTabsContainer .recRow span {
  padding: 12px 24px 12px 24px;
  background-color: #d3dbca;
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #707070;
}
.tb__recTabsContainer .recRow p {
  flex-grow: 1;
  margin-right: 6px;
  margin-bottom: 0;
}
.tb__recTabsContainer .recRow span {
  width: 60px;
  min-width: 60px;
  min-height: 42px;
  text-align: center;
  justify-content: center;
}

.noRecs {
  font-size: 12px;
  color: #707070;
  line-height: 16px;
}

.tb__pieChart-actualContainer .noRecs,
.tb__recTabsContainer .noRecs {
  text-align: center;
}

.tb__recTabsContainer .recRow.noRecs {
  font-size: 12px;
}

/* Jur Profile Area 6 */
.jurProfile__area6 .jpjs__titleWithBtn {
  margin-bottom: 120px;
}

.jurMainContent div.expandCollapse {
  color: #707070;
  cursor: pointer;
}

.jurMainContent div.expandCollapse:hover {
  text-decoration: underline;
}
