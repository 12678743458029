@media (max-width: 768px) {
    div.areaHome__page .content__container .areaLinkBox {
        width: 100%;
    }
    div.areaHome__page .content__container {
        padding-top: 132px;
    }
    div.areaHome__page .content__container .areaLinkBox {
        padding: 38px 32px 18px;
        margin-bottom: 20px;
    }
    div.areaHome__page .iconContainer {
        margin-bottom: 12px;
    }
    .areaLinkBox:nth-child(1) .iconContainer {
        margin-bottom: 6px;
    }
    .areaLinkBox:nth-child(2) .iconContainer {
        margin-top: 2px;
    }
    .areaLinkBox .iconContainer {
        margin-top: 8px;
    }
    div.areaLink__header h6 {
        font-size: 24px;
        line-height: 28px;
    }
}
@media (min-width: 568px) and (max-width: 1200px) {
    div.areaHome__page .content__container .areaLinkBox {
        padding: 30px 26px 12px;
        margin-bottom: 20px;
        width: calc(50% - 10px);
        height: auto;
    }
}
/* Areas Page */
.areaHome__page {
    background-color: #D8E3E5;
    min-height: 1200px;
}
.areaHome__page .content__container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 200px;
    justify-content: space-between;
}
.areaHome__page .content__container .areaLinkBox {
    width: calc(33.33% - 20px);
    padding: 60px 38px 26px;
    background: #F4F8F8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
    height: 370px;
    cursor: pointer;
}
.areaLink__header p {
    font-size: 16px;
    line-height: 16px;
    color: #617a7c;
    margin-bottom: 10px;
}
.areaLink__header h6 {
    font-size: 28px;
    line-height: 32px;
    color: #528598;
    margin-bottom: 16px;
    font-weight: 900;
}
.areaLink__body {
    flex-grow: 1;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.areaLink__body p {
    font-size: 16px;
    line-height: 20px;
    color: #868697;
}
.areaHome__page .iconContainer {
    margin-bottom: 26px;
}
/* AREAS PAGE HOVER STYLES*/
.areaHome__page .content__container .areaLinkBox {
    transition: background-color 325ms;
}
.areaHome__page .content__container .areaLinkBox path,
.areaHome__page .content__container .areaLinkBox rect,
.areaHome__page .content__container .areaLinkBox circle {
    transition: fill 325ms;
}

.areaHome__page .content__container .areaLinkBox:hover {
    background-color: #B0CBCE;
}
.areaHome__page .content__container .areaLinkBox:hover path,
.areaHome__page .content__container .areaLinkBox:hover rect,
.areaHome__page .content__container .areaLinkBox:hover circle {
    fill: #89A7B1;
}
/* END OF AREAS PAGE*/