body {
  margin: 20px 0;
  padding: 0;
  line-height: 1;
  font-size: 12px;
  font-family: Lato, sans-serif;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Tables Headers Generic Styles */

.wrapCellHeader {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-content: flex-end;
  padding: 10px 5px 0 5px;
  max-height: 86px;
  height: 82px;
  color: #4e4e4e;
}

.wrapCellHeader--lg {
  max-height: 100px;
  height: 100px;
}

.wrapCellHeader--xl {
  max-height: 110px;
  height: 110px;
}

.titleCellHeader {
  flex-basis: 100%;
  text-transform: uppercase;
  margin: 0 0 0 0;
  box-sizing: border-box;
  align-self: stretch;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding: 0px 10px;
}
.wrapCellHeader p {
  color: #4e4e4e;
}

.wrapCellHeader--lg .titleCellHeader {
  vertical-align: middle;
  min-height: 30px;
}
.sortCellHeader,
.selectCellHeader {
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}
.sortCellHeader {
  flex-basis: 30%;
  height: 40px;
  text-align: left;
  box-sizing: border-box;
  align-self: flex-end;
  padding-left: 10px;
}
.sortCellHeader p {
  margin-bottom: 0;
}

.selectCellHeader {
  font-size: small;
  height: 40px;
  text-align: right;
  box-sizing: border-box;
  align-self: flex-end;
  font-weight: 400;
  font-size: 8px;
}
.selectCellHeader img {
  vertical-align: baseline;
  vertical-align: initial;
}

.area2HeaderAdjust {
  height: 32px;
}
.dd-header,
.dd-header p {
  font-size: 12px;
  line-height: 16px;
}
.searchCellHeader {
  flex-basis: 70%;
  font-size: small;
  height: 40px;
  text-align: left;
  box-sizing: border-box;
  align-self: flex-end;
}

.tableContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tableContainer.fullWidth {
  width: 98vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -49vw;
  margin-right: -50vw;
}

.fullWidthSwitches {
  width: 98vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -49vw;
  margin-right: -50vw;
}

.tableArea1-1 {
  flex-basis: 85%;
  overflow: auto;
  transition: flex-basis 500ms ease-in-out;
  scrollbar-color: #e9eff0 #d8e3e5;
}

.jurTableContainer .tableArea1-1 {

  scrollbar-color: #d5d7d5 #ecefec;
}



table.mainTable {
  border-spacing: 0px;
  font-size: small;
  border-collapse: separate;
  width: 100%;
}

table.mainTable th,
table.mainTable td {
  border: 1px solid #d8e3e5;
  padding: 20px 10px;
  line-height: 16px;
  position: relative;
  color: #1c1c1c;
}

table.mainTable th {
  position: relative;
  background-color: #b0cbce;
  padding: 0px;
  margin: 0px;
  padding: 10px 0px 6px;
  text-align: center;
}
table.mainTable th.jurProfile {
  background-color: #b0ceb0;
}

table.mainTable tr {
  /*NICO*/
  background-color: #fff;
}

.trHeader {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  overflow-y: hidden;
}

.header-0 th {
  padding-bottom: 2px !important;
  padding-top: 20px !important;
}

.header-0 th.undefined {
  background-color: #bfd6d8 !important;
}

.header-0 .wrapCellHeader {
  padding: 0;
  height: 0;
  text-align: center;
  margin-top: 0px;
  font-size: 2px;
}
.header-0 .titleCellHeader {
  font-size: 12px;
}

th.empty-header {
  background-color: #d8e3e5;
}

th.header-0-0 {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0px;
  z-index: 100;
}

.firstTh {
  min-width: 200px;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0;
  z-index: 1000;
  box-shadow: 0 0 4px 1px rgba(128, 128, 128, 0.25) !important;
}
.firstThScrolled {
  min-width: 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1000;
  -webkit-clip-path: inset(0px -15px 0px 0px);
          clip-path: inset(0px -15px 0px 0px);
}

.first {
  min-width: 10px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 0;
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(128, 128, 128, 0.75);
  -webkit-clip-path: inset(0px -15px 0px 0px);
          clip-path: inset(0px -15px 0px 0px);
}

.compareTool__page .firstCompareTool {
  min-width: 10px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 0;
  background-color: #d8d5e3;
  font-weight: 700;
}

.compareTool__page .JurBtnContainer {
  padding-top: 24px;
  min-width: 176px;
  color: #8a79ba;
  font-size: 16px;
  line-height: 16px;
  background-color: #f6f5fa;
  text-align: center;
}

.firstHover {
  background-color: #f9fbfc;
}

.firstScrolled {
  min-width: 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 0;
  -webkit-clip-path: inset(0px -15px 0px 0px);
          clip-path: inset(0px -15px 0px 0px);
  background-color: #fff;
}

.first-1 {
  background-color: #fff;
}
.hover {
  background-color: #f9fbfc;
}
button.deleteRow,
button.deleteCol {
  position: absolute;
  right: 3px;
  top: 0px;
  cursor: pointer;
  display: none;
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
}

button.deleteRow {
  background: transparent;
  color: #868686;
  font-size: 18px;
}
button.deleteCol {
  background: #b0cbce;
  color: #868686;
  font-size: 18px;
}

div.rowActions {
  position: absolute;
  right: 6px;
  bottom: 6px;
  margin: auto;
  cursor: pointer;

  border: medium none currentColor;

  border: initial;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
  box-shadow: none;
  box-shadow: initial;
}

div.showSubJurs {
  position: absolute;
  left: 20px;
  bottom: 0px;
  cursor: pointer;
  border: medium none currentColor;
  border: initial;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
  box-shadow: none;
  box-shadow: initial;
  font-size: 10px;
  font-style: italic;
  vertical-align: bottom;
}

div.showSubJurs svg {
  vertical-align: bottom;
  padding-top: 2px;
  margin-left: 40px;
}

button.addColumn {
  display: block;
  background-color: blanchedalmond;
  margin: 10px;
  cursor: pointer;
}

.tableAddColumns {
  margin-top: 24px;
  margin-bottom: 14px;
  flex-basis: 15%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #e9eff0;
  opacity: 1;
  display: block;
  flex-wrap: wrap;
  position: relative;
}

.tableAddColumnsHidden {
  display: none;
  opacity: 0;
}

.tableArea1-1-big {
  flex-basis: 100%;
  transition: flex-basis 500ms ease-in-out;
}

.sourceTooltip {
  color: #12a0b7;

  cursor: help;
}

.sourceTooltip:hover {
  text-decoration: underline;
}

.sourceTooltipBody {
  width: 100%;
  text-align: left;
  padding: 5px;
  font-size: small;
  background: #ffffff;
}

.sourceTooltipBody a.pdfLink {
  color: #12a0b7;
  text-decoration: none;
  text-decoration: initial;
  cursor: pointer;
  margin-bottom: auto;
  margin-top: auto;
}
.sourceTooltipBody a.pdfLink:hover {
  text-decoration: underline;
}

.sourceTooltipBody a.pdfLinkImage {
  color: #6ea2b5;
}
.sourceTooltipTitle {
  font-size: 17px;
  color: #000;
  margin-bottom: 15px;
  font-weight: 900;
  line-height: 16px;
}

.sourceTooltipBody {
  font-size: 12px;
  color: #000;
  font-weight: 400;
  line-height: 14px;
}

.enforcmentModal .sourceTooltipBody a {
  font-size: 12px;
  color: #12a0b7;
  text-decoration: none;
  text-decoration: initial;
  font-weight: 400;
  line-height: 14px;
}

.moreLink {
  color: rgb(141, 141, 141);
  white-space: nowrap;
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-size: 20px;
}
.helpIcon {
  vertical-align: text-bottom;
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin-bottom: 5px;
  order: -1;
  position: absolute;
  bottom: 2px;
  left: calc(50% - 13px);
}
.red {
  background-color: red;
}

.textCenter {
  text-align: center;
}

.tableSources div.textCenterW100 {
  text-align: center;
  width: 100%;
}

.col80 {
  max-width: 80px;
  min-width: 80px;
}

.col90 {
  max-width: 90px;
  min-width: 90px;
}

.col90 .titleCellHeader {
  padding: 0px 0px;
}

.col100 {
  max-width: 100px;
  min-width: 100px;
}

.col110 {
  max-width: 110px;
  min-width: 110px;
}

.col120 {
  max-width: 120px;
  min-width: 120px;
}

.col125 {
  max-width: 125px;
  min-width: 125px;
}
.col140 {
  max-width: 140px;
  min-width: 140px;
}
.col170 {
  max-width: 170px;
  min-width: 170px;
}
.col190 {
  max-width: 190px;
  min-width: 190px;
}

.col270 {
  max-width: 270px;
  min-width: 270px;
}

.col300 {
  max-width: 300px;
  min-width: 300px;
}

.col550 {
  max-width: 550px;
  min-width: 550px;
}

.col600 {
  max-width: 600px;
  min-width: 600px;
}

.col190Protection {
  width: 190px;
  max-width: 190px;
  min-width: 190px;
}

.tableArea1-1::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.tableArea1-1::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  min-height: 75px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.tableArea1-1::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.tableArea1-1::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Bozidar Added CSS */
.dd-wrapper__search {
  padding-right: 16px;
  padding-bottom: 12px;
  padding-left: 16px;
  max-width: 340px;
}

.dd-header p {
  margin-bottom: 0;
}
.sourceTooltip {
  cursor: pointer;
}

.sourceTooltip:focus,
.sourceTooltip:active,
.sourceTooltip:hover {
  outline-color: #d8e3e5;
  border-color: #d8e3e5;
}

table.mainTable tr {
  background-color: #fff;
  z-index: 1001;
}
.tableJurCell td.first {
  min-width: 240px !important;
  max-width: 240px !important;
  width: 240px !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0 !important;
  z-index: 900 !important;
  box-shadow: 0 0 12px 1px rgba(128, 128, 128, 0.35) !important;
  -webkit-clip-path: inset(0px -15px 0px 0px) !important;
          clip-path: inset(0px -15px 0px 0px) !important;
}

tr.tableJurCell {
  height: 120px;
}

tr.a13Tr {
  height: 20px;
}

.tableAddColumns .ac_text {
  font-size: 24px;
  line-height: 26px;
  font-weight: 900;
  padding: 23px 32px;
}
.tableAddColumns .ac_text span:first-child {
  color: #6ea2b5;
}
.tableAddColumns .ac_text span:last-child {
  color: #000;
  font-weight: 400;
}
.tableAddColumns .addColumn {
  background-color: #b0cbce;
  border: none;
  box-shadow: none !important;
  box-shadow: initial !important;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 18px;
  display: inline;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  margin-left: 30px;
  margin-bottom: 22px;
  position: relative;
  text-align: left;
}
.tableAddColumns .addColumn::before {
  content: '+';
  position: absolute;
  font-size: 22px;
  font-weight: 400;
  left: -18px;
  top: 4px;
  font-family: Lato, sans-serif;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.closeIcon {
  top: 2px;
  position: relative;
}
.closeIcon path {
  -webkit-transform: translate(-4px, -4px);
          transform: translate(-4px, -4px);
  fill: #868686;
}

.filterIcon path {
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
  fill: #4e4e4e;
}

.filterCalendarIcon path {
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
  fill: #4e4e4e;
}

.filterSearchIcon path {
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
  fill: #4e4e4e;
}
.filterDates__container {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
}

.filterDates__container label {
  padding-top: 4px;
}
.filterDates__select {
  width: 100px;
}

.filterDates__input-input {
  font-size: 16px;
  width: 60px;
}

.tableReset__btn {
  background: #ffffff;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  bottom: 25px;
  width: 85%;
  left: 7.5%;
  color: #6ea2b5;
}
.header-1 {
  top: 24px;
}
.header-1 th {
  text-align: center;
}

span.legal {
  color: #70a599;
  font-weight: 700;
  text-transform: uppercase;
}

span.ilegal {
  font-weight: 700;
  color: #ce7b68;
  text-transform: uppercase;
}

.jurCell {
  display: flex;
}

.jurCell img {
  vertical-align: top;
  margin-right: 8px;
  margin-left: 12px;
  min-width: 22px;
}

.jurCell div {
  font-weight: 600;
  max-width: 140px;
}

.jurCellIssuing {
  text-align: center;
}
.jurCellIssuing .tableFlag {
  margin-bottom: 10px;
}

.dependentTerritory div {
  font-weight: 400;
  font-style: italic;
}

.subjurisdiction {
  margin-left: 20px;
}

.expandableCell {
  min-height: 48px;
}

.expandableCellExpanded {
  /*
  transition: all 1s;
  */
  cursor: zoom-out;
}

.textClamped3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: zoom-in;
}

.textUnclamped {
  display: block;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  overflow: visible;
  overflow: initial;
}

/*Topic Filter Modal*/
.topicFilter__container {
  color: #000;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  cursor: pointer;
}
.filter__modal .modal-body,
.filterModal__body .check-container,
.filter__modal .filterModal__footer {
  background: #f4fdff;
}
.filter__modal .filterModal__header {
  display: flex;
  align-items: center;
  background: #f4fdff !important;
  min-height: 80px;
}
.filterModal__input {
  max-width: 356px;
  margin-right: 20px;
}
.buttonCountryNames.buttonAndOr .knobs-en:after {
  content: 'Or';
  right: 4px;
}
.buttonCountryNames.buttonAndOr .knobs-es:after {
  content: 'O';
  right: 4px;
}
.filterModal__header .mcs__toggle {
  padding: 12px 22px 0 0;
}
.filterModal__body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 1020px;
  overflow-x: auto;
  overflow-y: hidden;
}

.topicFilterModal__body {
  -webkit-column-count: 5;
          column-count: 5;
}

.topicFilterModal__body .check-container {
  width: 100%;
}
.topicFilterModal__body .check-container label {
  font-size: 12px;
  padding: 0px 0px 0px 26px;
}

.filter__modal .modal-body {
  padding-top: 0px;
  padding-bottom: 0px;
}

.topicFilterModal__body .check-container label:after {
  top: 10px;
}

.filterModal__body .check-container {
  width: 25%;
}
.filterModal__body .check-container label {
  font-size: 12px;
  padding: 2px 0px 2px 26px;
}
.filterModal__clearFilter {
  color: #575757;
  font-size: 14px;
  line-height: 16px;
}
.filterModal__clearFilter:hover,
.filterModal__clearFilter:focus {
  color: #96c1d1;
}
.filterModal__footer {
  border-top: 0;
  border-top: initial;
  padding-bottom: 40px;
}
.filterModal__footer .btn.btn-primary {
  background-color: #96c1d1 !important;
  border-color: #96c1d1 !important;
  border-radius: 0;
  margin: 0 auto;
  font-size: 16px;
  padding: 10px 50px;
}

@media (min-width: 1350px) {
  .modal-xl {
    max-width: 1240px;
  }
}
@media (min-width: 1599px) {
  .modal-xl {
    max-width: 1550px;
  }
}
@media (min-width: 1800px) {
  .filterModal__body .check-container {
    width: 19.55%;
  }
  .modal-xl {
    max-width: 1740px;
  }
  .filterModal__body .check-container {
    width: 12%;
  }
  .filterModal__body .check-container label {
    padding: 2px 0px 2px 26px;
    font-size: 12px;
  }
  .filterModal__body {
    max-height: 68vh;
  }
}

.tableTallies {
  margin-top: 10px;
  float: left;
  color: #222;
}

.tableTallies__pageNum {
  font-weight: 700;
}

.customColumnHideSwitch {
  float: right;
  margin-top: 10px;
  margin-right: 14px;
}
.customColumnHideSwitch label {
  height: 22px;
  display: flex;
  align-items: center;
}

.customColumnHideSwitch label > * {
  margin-right: 4px;
  color: #222;
}
.bgGreen {
  background-color: #42a58e66;
  color: #557c73;
  font-weight: 700;
  text-transform: uppercase;
}

.bgRed {
  background-color: #dd8b6c33;
  color: #dd6c43;
  font-weight: 700;
  text-transform: uppercase;
}

.bgGray {
  background-color: #afafaf;
  color: #787878;
  font-weight: 700;
  text-transform: uppercase;
}

.bgYellow {
  background-color: #f6fe8f33;
  color: #e0cf50;
  font-weight: 700;
  text-transform: uppercase;
}
.uprGreen {
  color: #70a599;
  font-weight: 600;
  text-transform: uppercase;
}

.uprRed {
  color: #cf816e;
  font-weight: 600;
  text-transform: uppercase;
}

.playButtonUPR {
  color: #becebd;
}

.uprGreen div,
.uprRed div {
  margin-top: 6px;
}

.inactiveFilter {
  cursor: pointer;
  padding: 5px 8px 8px 5px;
}

.activeFilter {
  background-color: #c1dee2;
  cursor: pointer;
  font-weight: 600;
  padding: 5px 8px 8px 5px;
}

.tableTopic {
  background-color: #e9eff0;
  padding: 3px 6px;
  font-weight: 600;
  color: #5f5f5f;
  font-size: 11px;
  display: inline-block;
}

.topicSeparator {
  height: 10px;
}

.tableTopic:last-of-type {
  margin-bottom: 0;
  margin-bottom: initial;
}

.tableSources div {
  text-align: left;
  margin-bottom: 12px;
}

.tableSources div a {
  color: #12a0b7;
  margin-bottom: 5px;
  word-break: break-word;
}

.tableSources div a:hover {
  text-decoration: underline;
}

.tableSources div:last-of-type {
  margin-bottom: 0;
  margin-bottom: initial;
}

.table__modal .modal-content {
  background-color: #d8e3e5;
}

.table__modal .areaContent__subTitle {
  margin: 0 28px;
  text-align: center;
}

.table__modal .menuLogo {
  height: 48px;
  padding-top: 6px;
}

.tableAreaFullVh {
  max-height: 86vh;
}

@media (min-height: 900px) {
  .tableAreaFullVh {
    max-height: 85vh;
  }
  .tableAreaPx {
    max-height: 750px;
  }
}
/* HAD TO CHANGE THIS */
.area__page .tableAreaPx {
  max-height: 700px;
  min-height: 700px;
}
.tableAreaPx {
  min-height: 120px;
}
@media (min-height: 1200px) {
  .tableAreaFullVh {
    max-height: 88vh;
  }
  .tableAreaPx {
    max-height: 1050px;
  }
}

.tableContainerSubtitle {
  display: flex;
  justify-content: space-between;
}

.tableAreaSimpleTable {
  max-height: none;
  max-height: initial;
  position: relative;
}

.advancedSearch__page .tableAreaSimpleTable {
  max-height: 750px;
  position: relative;
}

.fullscreenButton {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px 5px 8px;
  background-color: #b0cbce;
}

.fullscreenButton:hover {
  background-color: #dde9eb;
}

.dropdown-button__list {
  z-index: 10000;
}

.brSourceText {
  display: block;
  margin: 6px 0;
  line-height: 18px;
  content: ' ';
}
.tablePagination {
  margin: 5px 0px;
}
.tablePagination button {
  border: medium none currentColor;
  border: initial;
  background-color: transparent;
  background-color: initial;
  color: #707070;
  margin: 0 12px;
  font-family: Lato, sans-serif;
  padding: 2px 5px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

.tablePagination button:hover {
  text-decoration: underline;
}

.tablePagination button.active {
  color: black;
  font-weight: 600;
  background-color: #ffffff;
  border: medium none currentColor;
  border: initial;
  box-shadow: none;
  box-shadow: initial;
}

.readMoreTable {
  height: 20px;
}
/* Table Pagination styles */
.tablePagination {
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  position: relative;
  top: 8px;
}

.tableFooter {
  bottom: 0px;
  align-items: center;
  color: #868686;
  left: 0;
  right: 0;
  margin-bottom: 46px;
  min-height: 32px;
}
.tableFooter:last-child {
  margin-bottom: 0;
}
.tablePagination button:first-child,
.tablePagination button:last-child {
  width: 20px;
  height: 22px;
  background: #c0d1d5;
  color: #707070;
  padding: 0;
}
.tablePagination button:first-child svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.tablePagination button:last-child svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

@media (max-width: 1040px) {
  .tablePagination {
    position: relative;
    bottom: auto;
    bottom: initial;
    left: auto;
    left: initial;
    right: auto;
    right: initial;
    top: 10px;
    width: 100%;
  }
}
/* END OF Table Pagination styles */

.preDetails {
  display: block;
  margin-bottom: 10px;
}
.preDetails:not(:first-child) {
  margin-top: 20px;
}

.rowLegal {
  color: #fff !important;
  font-weight: bold !important;
  background-color: #91b6c4 !important;
}

.rowIlegal {
  color: #fff !important;
  font-weight: bold !important;
  background-color: #d8a585 !important;
}

.rowLegalCompare {
  font-weight: bold !important;
  color: #91b6c4 !important;
}

.rowIlegalCompare {
  font-weight: bold !important;
  color: #d8a585 !important;
}

.rowProtectionYes {
  color: #fff !important;
  font-weight: bold !important;
  background-color: #91b6c4 !important;
}

.rowProtectionNo {
  color: #fff !important;
  font-weight: bold !important;
  background-color: #d8a585 !important;
}

.rowProtectionVaries {
  color: #707070 !important;
  font-weight: bold !important;
  background-color: #efd79c !important;
}

.rowProtectionUnclear {
  color: #707070 !important;
  font-weight: bold !important;
  background-color: #cbcbcb !important;
}

.rowProtectionRegressiveInForce {
  color: #707070 !important;
  font-weight: bold !important;
  background-color: #f5934b !important;
}

.rowProtectionRegressiveDev {
  color: #707070 !important;
  font-weight: bold !important;
  background-color: #edb795 !important;
}

.rowProtectionYesCompare {
  font-weight: bold !important;
  color: #91b6c4 !important;
}

.rowProtectionNoCompare {
  font-weight: bold !important;
  color: #d8a585 !important;
}

.rowProtectionVariesCompare {
  font-weight: bold !important;
  color: #efd79c !important;
}

.rowProtectionUnclearCompare {
  font-weight: bold !important;
  color: #cbcbcb !important;
}

.rowProtectionRegressiveInForceCompare {
  font-weight: bold !important;
  color: #f5934b !important;
}

.rowProtectionRegressiveDevCompare {
  font-weight: bold !important;
  color: #edb795 !important;
}

span.formError {
  margin-bottom: 5px;
  color: red;
}

/*.missingSource {
  background-color: red !important;
  color: white !important;
}*/

div.helpCell--a118gm {
  min-height: 60px;
  vertical-align: middle;
  margin: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.table__a116 .wrapCellHeader {
  height: 100px;
  min-height: 100px;
}

span.translateShowMore {
  color: #12a0b7 !important;
  cursor: pointer;
}
span.translateShowMore:hover {
  text-decoration: underline;
}

p.translatePlaceholder {
  font-style: italic;
}

.reportIcon {
  color: #e7edef;
}

.reportIcon:hover {
  color: #a7abad;
}

.citeIcon {
  color: #e7edef;
}

.citeIcon:hover {
  color: #a7abad;
}
