.VideoSlider__container {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  height: auto;
  height: initial;
  margin: 20px 0 0 0;
  padding: 50px 0;
  flex-grow: 1;
  overflow: visible;
}
.VideoSlider__container button {
  background: transparent;
  border-color: transparent;
  position: absolute;
  top: calc(50% - 14px);
}

.VideoSlider__container button.VS__btnPrev {
  right: auto;
  right: initial;
  left: -42px !important;
}
.VideoSlider__container button.VS__btnNext {
  left: auto;
  left: initial;
  right: 12px !important;
}
.other, .otherNext, .otherPrev {
  display: none;
}
.VideoSlider__container .VideoSlider__slide {
  margin: 0 14px;
  transition: opacity 0ms;
  position: relative;
}
.VideSlide__overlay {
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: #dae3e5;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.86;
}
.VideSlide__overlay svg {
  fill: #fff;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 225ms;
  transition: transform 225ms;
  transition: transform 225ms, -webkit-transform 225ms;
}
h2.videoSlider__title {
  font-size: 20px;
  font-weight: 600;
  margin-top: -30px;
  text-align: center;
  margin-bottom: 10px;
}

.VideoSlider__container .VideoSlider__slide iframe {
  width: 294px;
  transition: width 225ms, height 125ms;
}
.VideoSlider__container .VideoSlider__slide.active iframe {
  width: 750px;
  max-width: 750px;
}
.VideoSlider__container .VideoSlider__slide.active {
  order: 2;
  margin:  0 0 0 0;
}
.VideoSlider__container .VideoSlider__slide.active svg {
  -webkit-transform: scale(2);
          transform: scale(2);
}
.VideoSlider__container .VideoSlider__slide.otherNext {
  order: 4;
  -webkit-transform: translate(50px, 0);
          transform: translate(50px, 0);
  transition: none 0s ease 0s !important;
  transition: initial !important;
  opacity: 0;
}
.VideoSlider__container .VideoSlider__slide.otherPrev {
  order: 0;
  transition: none 0s ease 0s !important;
  transition: initial !important;
  -webkit-transform: translate(-800px, 0);
          transform: translate(-800px, 0);
}
.VideoSlider__container .VideoSlider__slide.other {
  display: none;
  transition: none 0s ease 0s !important;
  transition: initial !important;
  opacity: 0;
}

.VideoSlider__container .VideoSlider__slide.next {
  display: none;
}
.VideoSlider__container .VideoSlider__slide.prev {
  display: none;
}

@media (max-width: 768px) {

  .VideoSlider__container {
    height: auto;
    padding: 0 0 0 0;
    overflow: visible;
    min-height: 155px;
  }
  .VideoSlider__container button.VS__btnPrev {
    left: -35px !important;
  }
  .VideoSlider__container button.VS__btnNext {
    right: -35px !important;
  }
  .VideoSlider__container .VideoSlider__slide.active svg {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .VideoSlider__slide {
    display: none;
    transition: none 0s ease 0s !important;
    transition: initial !important;
  }

  .VideoSlider__container .VideoSlider__slide iframe {
    width: 294px;
    transition: none 0s ease 0s !important;
    transition: initial !important;
  }
  .VideoSlider__slide.active {
    display: block;
    width: 100%;
    -webkit-transform: none !important;
            transform: none !important;
    -webkit-transform: initial !important;
            transform: initial !important;
    transition: none 0s ease 0s !important;
    transition: initial !important;
    margin-bottom: 30px;
  }
  .VideoSlider__slide.active iframe {
    width: 100% !important;
  }

  .videoSlider__title {
    margin-top: 5px !important;
    margin-bottom: 22px !important;
  }

}

@media (min-width: 768px) and (max-width: 1400px) {
  .page__home .content__section {
    overflow-x: hidden;
  }
  .VideoSlider__container {
    overflow: visible;
    width: 100%;
    height: 360px;
  }
  .VideoSlider__container .VideoSlider__slide {
    position: absolute;
    -webkit-transform: none !important;
            transform: none !important;
    -webkit-transform: initial !important;
            transform: initial !important;
    transition: left 225ms;
  }
  .VideoSlider__container .VideoSlider__slide.active iframe {
    width: 56vw;
  }
  .VideoSlider__container .VideoSlider__slide.active {
    left: calc(50% - 28vw);
  }
  .VideoSlider__container .VideoSlider__slide.next {
    display: none;
  }
  .VideoSlider__container .VideoSlider__slide.prev {
    display: none;
  }
  .VideoSlider__container .VideoSlider__slide.otherPrev {
    left: -50% !important;
  }
  .VideoSlider__container .VideoSlider__slide.otherNext {
    left: 150% !important;
  }
  .VideoSlider__container button.VS__btnPrev,
  .VideoSlider__container button.VS__btnNext {
    left: calc(100vw - 100px);
    z-index: 10;
    right: auto !important;
    right: initial !important;
  }
  .VideoSlider__container button.VS__btnPrev {
    left: -4px !important;
  }
  .VideoSlider__container button.VS__btnNext {
    left: auto;
    left: initial;
    right: -4px !important;
    z-index: 10;
  }
  .VideoSlider__container .VideoSlider__slide.active {
    -webkit-transform: scale(.87) !important;
            transform: scale(.87) !important;
  }
}

@media (min-width: 1030px) and (max-width: 1400px) {
  .VideoSlider__container {
    margin: 30px 0;
  }
  .VideoSlider__container .VideoSlider__slide.active {
    -webkit-transform: scale(.92) !important;
            transform: scale(.92) !important;
  }
}
