.mapContainer[data-v-027e65f0] {
  width: 100%;
  padding: 10px 0px;
  min-height: 630px;
}
#chartdiv[data-v-027e65f0] {
  width: 100%;
  height: 500px;
  min-height: 500px;
}
.jurMapContainer[data-v-027e65f0] {
  align-self: center;
  margin-right: 36px;
  height: 490px;
  min-width: 450px;
  flex-grow: 1;
  position: relative;
}
.jurMapContainer #chartdiv[data-v-027e65f0] {
  width: 100%;
  height: 490px;
  min-height: 490px;
  margin-top: -8px;
}
@media (min-width: 769px) and (max-width: 1140px) {
.jurMapContainer[data-v-027e65f0] {
    width: 100%;
    height: auto;
    margin-right: 0;
}
}
