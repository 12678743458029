.compareTool__page th {
  height: 84px;
  background-color: #f6f5fa;
  border: 5px solid #eeecf5;
  border-top: 0px;
  text-align: left;
  position: relative;
  padding: 16px 28px 16px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: #000;
  min-width: 272px;
}
.compareTool__page th:first-child {
  min-width: 218px;
  text-align: center;
}
.compareTool__page th:last-child {
  min-width: 0;
  min-width: initial;
}
.compareTool__page th:nth-child(n + 2) {
  padding-right: 120px;
  line-height: 18px;
}
.compareTool__page .emptyTable th:first-child {
  min-width: 426px;
  text-align: center;
}
.compareTool__page th:first-child,
.compareTool__page td:first-child {
  max-width: 218px;
  border-left: 0;
  border-left: initial;
}
.compareTool__page th:last-child {
  min-width: 128px;
  color: #8a79ba;
  font-size: 16px;
  line-height: 16px;
  font-weight: normal;
  position: relative;
  background-color: #f6f5fa;
  padding: 0 16px;
  text-align: center;
}
.compareTool__page td {
  height: 72px;
  background-color: white;
  border: 5px solid #eeecf5;
  text-align: left;
  min-width: 370px;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  padding: 13px 25px;
  vertical-align: top;
}

.compareTool__page td .td-rowContainer {
  display: flex;
  justify-content: flex-start;
}
.compareTool__page td img {
  margin-right: 12px;
}

.compareTool__page .emptyTable th,
.compareTool__page .emptyTable td {
  padding: 0 0 0 0;
}
.compareTool__page .emptyTable td {
  text-align: center;
}

.compareTool__page tr.jurTr:last-child td {
  min-width: 176px;
  color: #8a79ba;
  font-size: 16px;
  line-height: 16px;
  background-color: #f6f5fa;
  text-align: center;
}

.compareTool__page .emptyTable .infoBubble {
  position: absolute;
  color: #000;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.compareTool__page .emptyTable .infoBubble:after {
  content: ' ';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #e3e3e3;
  position: absolute;
}
.compareTool__page .emptyTable th .infoBubble {
  right: -240px;
  top: -32px;
  width: 214px;
  height: 150px;
}
.compareTool__page .emptyTable th .infoBubble:after {
  left: -10px;
}
.compareTool__page .emptyTable td .infoBubble {
  left: -24px;
  top: 100px;
  width: 261px;
  height: 133px;
}

.compareTool__page .emptyTable td .infoBubble:after {
  content: ' ';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e3e3e3;
  position: absolute;
  top: -20px;
  right: 26px;
}
.compareTool__page.section__page .areaContent__header {
  padding-bottom: 14px;
}
.compareTool__tableContainer {
  overflow-x: auto;
  max-height: 700px;
  min-height: 700px;
}

.compareCellDiv {
  height: 220px;
  overflow: auto;
  color: #575757;
}

.compareTool__tableContainer .sourceTooltip {
  color: #5a0468;
}

.compareTool__tableContainer.emptyTable {
  overflow: visible;
}
.compareTool__tableContainer.emptyTable .firstTh,
.compareTool__tableContainer.emptyTable .first {
  position: fixed;
  box-shadow: none;
  box-shadow: initial;
  -webkit-clip-path: unset;
  clip-path: initial;
  text-align: center;
}
.acp__table-deleteCol {
  position: absolute;
  background-color: transparent;
  border: medium none currentColor;
  border: initial;
  font-size: 12px;
  top: 14px;
  right: 0;
}

.compareTool__page .firstTh {
  background-color: #eeecf5;
}

.compareTool__page tr:last-child td .ast__addArea-btn,
.compareTool__page tr:first-child th .ast__addArea-btn {
  border: medium none currentColor;
  border: initial;
  color: inherit;
  background: transparent;
  line-height: inherit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* customScrollbar */
.compareTool__tableContainer::-webkit-scrollbar,
.compareCellDiv::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
.compareTool__tableContainer::-webkit-scrollbar-thumb,
.compareCellDiv::-webkit-scrollbar-thumb {
  height: 5px;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  min-height: 75px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05),
    inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}
.compareTool__tableContainer::-webkit-scrollbar-button,
.compareTool__tableContainer::-webkit-scrollbar-button,
.compareCellDiv::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.compareTool__tableContainer::-webkit-scrollbar-corner,
.compareCellDiv::-webkit-scrollbar-corner {
  background-color: transparent;
}

.compareTool__tableContainer div::-webkit-scrollbar,
.compareCellDiv::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
.compareTool__tableContainer div::-webkit-scrollbar-thumb,
.compareCellDiv::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  min-height: 75px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.compareTool__tableContainer div::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.compareTool__tableContainer div::-webkit-scrollbar-corner {
  background-color: transparent;
}

.compareTool__page .acp__formControl .check-container label {
  background-color: #eeecf5;
  width: 100%;
  text-align: left;
}

.compareTool__page .dropdown-check-list ul.items {
  background-color: #eeecf5;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  overflow: hidden;
}

.compareTool__page .dd-wrapper.dd-wrapperjur {
  background: #eeecf5;
}

.compareTool__page .dd-wrapper .accordion-button {
  background: #eeecf5;
}

.compareTool__page .dd-wrapper .accordion-button h5 {
  color: #575757;
}

.compareTool__page .dd-wrapper.dd-wrapperjur {
  background: #eeecf5;
}

.compareTool__page .dd-list-item .check-container label {
  background: #eeecf5;
}

.compareTool__page .dd-wrapper .accordion-body {
  max-height: 370px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: #eeecf5;
  padding-left: 12px;
}
.compareTool__page .check-container {
  background-color: #eeecf5;
}

.compareTool__page .check-container label:before {
  background-color: #ffffff;
}

.compareTool__page .check-container label:after {
  content: '';
  border-color: #8a79ba !important;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%238A79BA' fill-rule='nonzero'/%3E%3C/svg%3E") !important;
}
